import { useEffect, useState } from 'react';
import { GraphData, GraphinData } from '@antv/graphin';
import { useFlowContext } from './context/FlowContext';
import { SLOT_OPTION } from '../constants';

const graphinChartConverter = (graph: {
  edges: any[];
  nodes: any[];
}): GraphData => {
  const edges = graph.edges.map((edge) => {
    let label = '';
    const lastIdentifier = edge.to.split('.').pop();

    if (lastIdentifier === '1F' || lastIdentifier === '1T') {
      label = lastIdentifier === '1F' ? 'False' : 'True';
    }

    return {
      source: edge.from,
      target: edge.to,
      type: 'line',
      label,
    };
  });

  const nodes = graph.nodes.map((node) => {
    const { label, prompt_message, node_path } = node;
    let resultNode = {
      type: node.type || SLOT_OPTION.PROMPT,
      id: node_path,
      size: [90, 0], // Values used to define the spacing of the slots
      name: '',
      text: '',
    };

    switch (node.type) {
      case SLOT_OPTION.PROMPT:
        resultNode.name = label || 'New Prompt';
        resultNode.text = prompt_message || 'Pending Configuration';
        break;
      case SLOT_OPTION.DECISION_POINT:
        resultNode.name = label || 'New Decision Point';
        break;
      default:
        resultNode.name = label || 'New Prompt';
        resultNode.text = prompt_message || 'Pending Configuration';
        break;
    }
    return resultNode;
  });
  return { nodes, edges };
};

export function useFlowGraphData(): GraphinData {
  const { graph } = useFlowContext();

  const [flowGraphData, setFlowGraphData] = useState({
    nodes: [],
    edges: [],
  }) as [GraphinData, (GraphinData) => void];

  useEffect(() => {
    if (graph) {
      const graphToChartData = graphinChartConverter(graph);
      setFlowGraphData(graphToChartData);
    }
  }, [graph]);

  return flowGraphData;
}
