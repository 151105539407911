import { useMemo } from 'react';

import { CONFIGURATIONS_URL, CONFIGURATION_TYPE } from 'src/constants';

import { useData } from './utils';

export const useConfigurationData = (
  deploymentId: string,
  type: CONFIGURATION_TYPE
) => {
  const key = useMemo(() => `${CONFIGURATIONS_URL}/${deploymentId}/${type}`, [
    deploymentId,
    type,
  ]);

  const { data: { data: configuration = null } = {}, ...data } = useData<
    { data: ConfigurationType },
    ConfigurationType
  >(key);

  return {
    ...data,
    configuration,
  };
};
