import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { modalSetAppElement } from '@rollioforce/rollio-ui';
import Rollio from '@rollioforce/types'; // eslint-disable-line

// Global styles
import 'jsoneditor-react/es/editor.min.css';
import 'react-toastify/dist/ReactToastify.css';
import '@rollioforce/rollio-ui/dist/index.css';
import './index.css';

import { App } from './App';
import { SENTRY_DNS, RELEASE_VERSION, SENTRY_ENV } from './constants';

if (SENTRY_ENV !== 'development') {
  Sentry.init({
    dsn: SENTRY_DNS,
    release: RELEASE_VERSION,
    environment: SENTRY_ENV,
    integrations: [new Integrations.BrowserTracing()],
  });
}

const root = document.getElementById('root');

if (root) {
  ReactDOM.render(<App />, root);

  modalSetAppElement(root);
}
