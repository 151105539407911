import React, { useMemo } from 'react';
import { setActiveInstance } from '../store/actions';
import {
  Sidebar,
  SidebarProps,
  SidebarSelect,
} from '@rollioforce/rollio-admin-ui';
import { Link, Location, WindowLocation } from '@reach/router';
import { ToolTip } from '@rollioforce/rollio-ui';
import { useOrganizationsWithInstances } from '../hooks/api/useAllOrganizationInstancesData';
import { parseOrganizationToSelect } from '../services/utils';
import { getRouteById, getSidebarItems, ROUTES } from 'src/routes';
import { usePermission, useDispatch, useStore } from 'src/hooks';
import rollioLogo from 'src/assets/rollio-logo.png';

type Props = Pick<
  SidebarProps,
  'usernameLabel' | 'isVisible' | 'onLogoutClick'
>;

interface PropsWithLocation extends Props {
  location: WindowLocation;
}

const routes = [
  ROUTES.home,
  ROUTES.organizations,
  ROUTES.organization,
  ROUTES.data,
  ROUTES.configurations,
  ROUTES.flows,
];

const AppSidebarWithLocation: React.FC<PropsWithLocation> = ({
  location,
  ...props
}) => {
  const { checkRouteAccess, isInternal } = usePermission();

  const { organizations } = useOrganizationsWithInstances();

  const dispatch = useDispatch();
  const { activeInstance } = useStore();

  const organizationsForSelect = React.useMemo(
    () => parseOrganizationToSelect(organizations, isInternal),
    [organizations, isInternal]
  );

  const select: SidebarSelect = {
    label: 'Active Instance',
    onChange: (value) => {
      dispatch(setActiveInstance(value));
    },
    options: organizationsForSelect,
    tabIndex: 2,
    value: activeInstance,
    disabled: false,
  };

  const linkItems = useMemo(
    () =>
      getSidebarItems(location.pathname, routes)
        .filter(checkRouteAccess)
        .map(({ id, label, match, icon }) => ({
          id,
          label,
          active: match,
          icon,
        })),
    [location.pathname, checkRouteAccess]
  );

  return (
    <Sidebar
      {...props}
      logoImage={rollioLogo}
      select={select}
      titleLabel="Rollio CMS"
      greetingLabel="Hi, "
      linkItems={linkItems}
      renderLink={(id, linkInner) => {
        const { path } = getRouteById(id);
        const pathsWithInstanceRequired = ['data', 'configurations', 'flows'];

        let linkToRender = (
          <Link to={path} style={{ textDecoration: 'none' }}>
            {linkInner}
          </Link>
        );

        if (!activeInstance && pathsWithInstanceRequired.includes(id)) {
          linkToRender = (
            <ToolTip
              position={'right'}
              background={'offWhite2'}
              textColor={'greyDark'}
              message={'Please select an active instance'}
            >
              {linkInner}
            </ToolTip>
          );
        }

        return linkToRender;
      }}
    />
  );
};

export const AppSidebar: React.FC<Props> = (props) => (
  <Location>
    {({ location }) => (
      <AppSidebarWithLocation {...props} location={location} />
    )}
  </Location>
);
