import { useCallback, useMemo } from 'react';

import { DEPLOYMENTS_URL } from 'src/constants';

import { useData, useFetcher } from './utils';

export const useSfAvailableUsersData = (
  deploymentId: number,
  search?: SearchParams
) => {
  const { send, isLoading } = useFetcher();

  const key = useMemo(
    () => `${DEPLOYMENTS_URL}/${deploymentId}/sf-available-users`,
    [deploymentId]
  );

  const { data: { data: availableUsers = [] } = {}, ...data } = useData<
    { data: UserType[] },
    UserType
  >(key, search);

  const syncUsers = useCallback(
    async (username, sfToken) => {
      return send(
        key,
        'POST',
        { username },
        {
          headers: {
            'sf-authorization': sfToken,
          },
        }
      );
    },
    [send, key]
  );

  return {
    ...data,
    availableUsers,
    isLoadingFetcher: isLoading,
    syncUsers,
  };
};
