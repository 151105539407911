import React, { useMemo } from 'react';
import { Form, FormFields, FormProps } from '@rollioforce/rollio-admin-ui';

import { DATE_FORMATS } from 'src/constants';
import { formatDate } from 'src/services/utils';

export interface GenerateReportFormProps extends Partial<FormProps> {
  initialData?: OrganizationType;
  onSubmit: (formDetails: OrganizationType) => void;
}

export const DEFAULT_GENERATE_REPORT_FORM: GenerateReportType = {
  date_from: formatDate(
    new Date().setDate(new Date().getDate() - 7),
    DATE_FORMATS.FORM
  ),
  date_to: formatDate(new Date(), DATE_FORMATS.FORM),
  include_successful_failed: false,
};

export const GenerateReportForm: React.FC<GenerateReportFormProps> = ({
  initialData,
  ...props
}) => {
  const formData = useMemo(
    () => ({
      ...DEFAULT_GENERATE_REPORT_FORM,
      ...initialData,
    }),
    [initialData]
  );

  const FORM_FIELDS: FormFields = useMemo(
    () => [
      {
        key: 'topRow',
        type: 'row',
        fields: [
          {
            key: 'date_from',
            label: 'Date From',
            type: 'input',
            validate: 'onChange',
            fieldProps: {
              type: 'date',
            },
          },
          {
            key: 'date_to',
            label: 'Date To',
            type: 'input',
            validate: 'onChange',
            fieldProps: {
              type: 'date',
            },
          },
        ],
        style: { alignItems: 'center' },
      },
      {
        key: 'include_successful_failed',
        label: 'Show successful and failed columns',
        type: 'checkbox',
        validate: 'onChange',
      },
    ],
    []
  );

  return (
    <Form
      {...props}
      fields={FORM_FIELDS}
      enableReinitialize
      initialData={formData}
      saveButtonLabel="Generate"
    />
  );
};
