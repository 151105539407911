import { State } from './state';
import { ActionType } from './actionTypes';

export const reducer = (state: State, action: ActionType): State => {
  switch (action.type) {
    case 'SET_ACTIVE_DEPLOYMENT_ID':
      return {
        ...state,
        activeDeploymentId: action.payload,
      };
    case 'SET_AUTH_TOKEN':
      return {
        ...state,
        authToken: action.payload,
      };
    case 'SET_DELETE_MODAL':
      return {
        ...state,
        deleteModal: action.payload,
      };
    case 'SET_DELETE_MODAL_IS_LOADING':
      return {
        ...state,
        deleteModal: {
          ...state.deleteModal,
          isLoading: action.payload,
        },
      };
    case 'SET_DELETE_MODAL_IS_VISIBLE':
      return {
        ...state,
        deleteModal: {
          ...state.deleteModal,
          isVisible: action.payload,
        },
      };
    case 'SET_IS_SIDEBAR_EXPANDED':
      return {
        ...state,
        isSidebarExpanded: action.payload,
      };
    case 'SET_SF_TOKEN':
      return {
        ...state,
        sfToken: action.payload,
      };
    case 'SET_ACTIVE_INSTANCE':
      return {
        ...state,
        activeInstance: action.payload,
        sfToken: undefined,
      };
    case 'SET_LAST_SAVED_CHANGES':
      return {
        ...state,
        lastSavedChanges: action.payload,
      };
    default:
      return state;
  }
};
