import React from 'react';
import { RouteComponentProps } from '@reach/router';

import { OrganizationDetail } from 'src/layouts/Organization';
import { PageWrap } from 'src/components/PageWrap';

interface Props extends RouteComponentProps {
  organizationId?: string;
}

export const Organization: React.FC<Props> = ({ organizationId }) => (
  <PageWrap>
    <OrganizationDetail organizationId={organizationId} />
  </PageWrap>
);
