import { useMemo } from 'react';

import { DEPLOYMENTS_URL, ORGANIZATIONS_URL } from 'src/constants';

import { usePermission } from '../usePermission';
import { useUserProfile } from '../useUserProfile';
import { useData } from './utils';

export const useDeploymentsData = () => {
  const { isInternal } = usePermission();
  const userProfile = useUserProfile();

  const key = useMemo(
    () =>
      isInternal
        ? `${DEPLOYMENTS_URL}`
        : `${ORGANIZATIONS_URL}/${userProfile?.organization_id}/deployments`,
    [isInternal, userProfile]
  );

  const { data: { data: deployments = [] } = {}, ...data } = useData<
    { data: DeploymentType[] },
    DeploymentType
  >(key);

  return {
    ...data,
    deployments,
  };
};
