import { useMemo } from 'react';

import { DEPLOYMENTS_URL } from 'src/constants';

import { useData } from './utils';

export const useSfUsersData = (deploymentId: string, search?: SearchParams) => {
  const key = useMemo(
    () => `${DEPLOYMENTS_URL}/${deploymentId}/sf-selected-users`,
    [deploymentId]
  );

  const { data: { data: users = [] } = {}, ...data } = useData<
    { data: any[] },
    any
  >(key, search);

  return {
    ...data,
    users,
  };
};
