import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Icon, Table, TableLabel, TableProps } from '@rollioforce/rollio-ui';
import { Columns } from '@rollioforce/rollio-ui/dist/types/components/Table';

import { ACTION, RESOURCE } from 'src/constants';
import { usePermission } from 'src/hooks';

import { TableBitCell, TableDateCell } from './TableCells';

interface Props extends Omit<TableProps, 'columns'> {
  onConversationButtonClick?: (user: any) => void;
  onDeleteClick?: (user: any) => void;
}

const ActionContainer = styled.div`
  display: flex;
`;

const IconWithMargin = styled(Icon)`
  margin-right: 10px;
`;

export const SfUsersTable: React.FC<Props> = ({
  onConversationButtonClick,
  onDeleteClick,
  data,
  ...props
}) => {
  const { checkPermission, isInternal } = usePermission();

  const columns: Columns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
        width: 80,
        show: isInternal,
      },
      {
        Header: 'Name',
        accessor: 'name',
        Cell: ({ original }) => (
          <TableLabel variant="header">
            {original.first_name} {original.last_name}
          </TableLabel>
        ),
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'Created at',
        accessor: 'created_at',
        Cell: TableDateCell,
      },
      {
        Header: 'Last login',
        accessor: 'deployment_user.last_login',
        Cell: TableDateCell,
      },
      {
        Header: 'Active',
        accessor: 'is_active',
        Cell: TableBitCell,
        show: checkPermission(RESOURCE.SF_USER, ACTION.ACTIVATE),
      },
      {
        Header: '',
        accessor: 'actions',
        show: checkPermission(RESOURCE.SF_USER, ACTION.DELETE),
        Cell: ({ original }) => (
          <ActionContainer>
            {onDeleteClick && (
              <TableLabel isLink onClick={() => onDeleteClick(original)}>
                <IconWithMargin name="delete" />
              </TableLabel>
            )}
            {onConversationButtonClick && (
              <TableLabel
                isLink
                onClick={() => onConversationButtonClick(original)}
                title="Show conversation"
              >
                <Icon name="conversation" />
              </TableLabel>
            )}
          </ActionContainer>
        ),
      },
    ],
    [onDeleteClick, onConversationButtonClick, checkPermission, isInternal]
  );

  return (
    <Table
      {...props}
      columns={columns}
      data={data}
      showPagination={data.length >= 20}
    />
  );
};
