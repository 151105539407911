import React, { useState, useCallback } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { Button } from '@rollioforce/rollio-ui';
import { LoadingIndicator } from '@rollioforce/rollio-admin-ui';

import { SearchForm } from 'src/components/Forms';
import {
  ConfirmModal,
  ConversationListModal,
  SfSyncModal,
} from 'src/components/Modals';
import { Permission } from 'src/components/Permission';
import { SfUsersTable } from 'src/components/Tables';
import { useSfUsersData } from 'src/hooks';
import { ACTION, RESOURCE } from 'src/constants';

const TopTable = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  margin-bottom: 10px;
`;

const AddUsersButton = styled(Button)`
  margin-left: 10px;
`;

export const SfUsers = ({ deploymentId, synchedSfUsersAt }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
  const [isConfirmModalLoading, setIsConfirmModalLoading] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [searchParams, setSearchParams] = useState<SearchParams>();
  const [isConversationModalOpen, setIsConversationModalOpen] = useState(false);
  const [conversationUser, setConversationUser] = useState<any>();

  const {
    isMutating,
    isLoading,
    users,
    addRecord,
    deleteRecord,
    refresh,
  } = useSfUsersData(deploymentId, searchParams);

  const onConversationButtonClick = useCallback(async (user) => {
    setIsConversationModalOpen(true);
    setConversationUser(user);
  }, []);

  const onDeleteClick = useCallback(async ({ id }) => {
    setIsConfirmModalVisible(true);
    setDeleteId(id);
  }, []);

  const onConfirmDeleteModal = useCallback(async () => {
    setIsConfirmModalLoading(true);
    await deleteRecord({ ids: [deleteId] });
    refresh();
    setIsConfirmModalLoading(false);
    setIsConfirmModalVisible(false);
  }, [deleteId, deleteRecord, refresh]);

  const onAddUsers = useCallback(
    async (selected) => {
      try {
        const res = await addRecord({ ids: selected });

        if (res && res.status === 'error') {
          return toast(res.message, { type: 'error' });
        }

        setIsModalOpen(false);
        toast('Users added successfully', { type: 'success' });
      } catch (error) {
        toast(error.message, { type: 'error' });
      }
    },
    [addRecord]
  );

  return (
    <>
      <TopTable>
        <Permission resource={RESOURCE.SF_USER} action={ACTION.READ}>
          <SearchForm
            initialData={searchParams}
            inputLabel="Search users by name or email"
            onSubmit={setSearchParams}
          />
        </Permission>
        <Permission resource={RESOURCE.SF_USER} action={ACTION.READ}>
          <AddUsersButton
            background="greyDark"
            onClick={() => setIsModalOpen(true)}
          >
            Add users to deployment
          </AddUsersButton>

          <SfSyncModal
            deploymentId={deploymentId}
            isOpen={isModalOpen}
            onAddUsers={onAddUsers}
            onCloseClick={() => setIsModalOpen(false)}
            selectedUsers={users}
            synchedSfUsersAt={synchedSfUsersAt}
            title="Add users to deployment"
          />
        </Permission>
      </TopTable>
      <Permission resource={RESOURCE.SF_USER} action={ACTION.READ}>
        <SfUsersTable
          data={users}
          onConversationButtonClick={onConversationButtonClick}
          onDeleteClick={onDeleteClick}
        />
      </Permission>

      <LoadingIndicator isLoading={isLoading || isMutating} />

      <ConfirmModal
        confirmLabel="Remove"
        isLoading={isConfirmModalLoading}
        isOpen={isConfirmModalVisible}
        message="Are you sure you wish to remove this user? They will no longer be able to access the Rollio application"
        onCloseClick={() => setIsConfirmModalVisible(false)}
        onConfirm={onConfirmDeleteModal}
        small
        title="Remove User"
      />

      {conversationUser && (
        <ConversationListModal
          deploymentId={deploymentId}
          isOpen={isConversationModalOpen}
          onCloseClick={() => setIsConversationModalOpen(false)}
          sfUserId={conversationUser.id}
          title={`Conversations of ${conversationUser.first_name} ${conversationUser.last_name}`}
        />
      )}
    </>
  );
};
