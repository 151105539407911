import React, { useCallback, useRef } from 'react';
import { ModalProps } from '@rollioforce/rollio-ui';

import { useInstanceSfLinkedUsersData, useSfAuth } from 'src/hooks';

import { SfLoginModal } from './SfLoginModal';
import { LoadingIndicator } from '@rollioforce/rollio-admin-ui';

interface Props extends Omit<ModalProps, 'children' | 'small'> {
  instanceId: number;
  onLoginSuccess: (loginData: any) => Promise<void> | void;
  onLoginError: (message: string) => void;
}

export const SfLoginInstanceModal: React.FC<Props> = ({
  instanceId,
  onLoginSuccess,
  onLoginError,
  ...props
}) => {
  const loginPopup = useRef<Window | void>();
  const {
    isLoadingFetcher: isLinking,
    linkedUsers,
    deleteSfUserByEmail,
    linkUser,
    unlinkUser,
    refresh: refreshLinkedUsers,
  } = useInstanceSfLinkedUsersData(instanceId);
  const { login, isLoggingIn } = useSfAuth();

  const loginCallback = useCallback(
    async (username, isNew, success, data) => {
      if (success) {
        await onLoginSuccess({ username, data });
        refreshLinkedUsers();
      } else {
        onLoginError(data);
        // Login aborted... delete sfuser by email
        if (isNew) {
          await deleteSfUserByEmail(username);
        }
      }
    },
    [onLoginSuccess, onLoginError, refreshLinkedUsers, deleteSfUserByEmail]
  );

  const onSelectAccount = useCallback(
    async (username, isNew) => {
      if (isNew) {
        const res = await linkUser(username);
        if (res && res.status === 'error') {
          return onLoginError(res.message);
        }
      }

      await login(username, null, (success, data) =>
        loginCallback(username, isNew, success, data)
      ).then((popup) => {
        loginPopup.current = popup;
      });
    },
    [linkUser, login, loginCallback, onLoginError]
  );

  const onUnlinkAccount = useCallback(
    async (id) => {
      const res = await unlinkUser(id);
      if (res && res.status === 'error') {
        return onLoginError(res.message);
      }
      refreshLinkedUsers();
    },
    [unlinkUser, refreshLinkedUsers, onLoginError]
  );

  const onLoadingClick = useCallback(async () => {
    if (isLoggingIn && loginPopup.current) {
      loginPopup.current.close();
      loginPopup.current = null;
    }
  }, [isLoggingIn, loginPopup]);

  return (
    <>
      <SfLoginModal
        {...props}
        linkedUsers={linkedUsers}
        onSelectAccount={onSelectAccount}
        onUnlinkAccount={onUnlinkAccount}
      />
      <LoadingIndicator
        isLoading={isLinking || isLoggingIn}
        onClick={onLoadingClick}
      />
    </>
  );
};
