import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';
import { debounce } from 'lodash';
import { LoadingIndicator } from '@rollioforce/rollio-admin-ui';

import { mapFlow, mapSlot, formatExistFlow, mapTemplate } from 'src/parser';
import {
  useDispatch,
  useFlowData,
  useInstanceDeploymentsData,
  useInstanceObjectData,
  useObjectsFieldData,
  useStore,
} from 'src/hooks';
import { setLastSavedChanges } from 'src/store/actions';
import { Container } from 'src/components/Container';
import { FlowForm, DEFAULT_FLOW_FORM } from 'src/components/Forms';

import { ContainerInfo } from './ContainerInfo';
import { SidebarPanel } from './SidebarPanel';
import { Slots } from './Slots';
import { Templates } from './Templates';
import { FlowProvider } from '../../../hooks/context/FlowContext';

interface Props {
  flowId?: string;
}

const FlowContainer = styled.div`
  display: flex;
  padding-right: 430px;
`;

const DetailPanel = styled.div`
  flex: 1;
`;

export const FlowDetail: React.FC<Props> = ({ flowId }) => {
  const dispatch = useDispatch();
  const { activeInstance } = useStore();
  const { flow, isLoading, updateFlow } = useFlowData(flowId);

  const { deployments } = useInstanceDeploymentsData(activeInstance?.value);
  const { objects } = useInstanceObjectData(activeInstance?.value);
  const { fields } = useObjectsFieldData(flow?.object_id);

  const [flowData, setFlowData] = useState<FlowType>(DEFAULT_FLOW_FORM);
  const [templates, setTemplates] = useState<TemplateType[]>();
  const [slots, setSlots] = useState<SlotType[]>();
  const [graph, setGraph] = useState<Graph>();

  const pendingChanges = useRef();

  useEffect(() => {
    if (flow) {
      setFlowData(mapFlow(flow));
      setTemplates((flow.templates || []).map(mapTemplate));
      setSlots((flow.slots || []).map(mapSlot));
      setGraph(flow.graph || { nodes: [], edges: [] });
    }
  }, [flow]);

  const onSaveFlow = useCallback(
    async (newValues, debounced = false) => {
      dispatch(setLastSavedChanges(null));
      const data = formatExistFlow(newValues);
      const response = await updateFlow(data);
      if (response?.data?.graph) {
        setGraph(response.data.graph);
      }
      pendingChanges.current = null;
      if (debounced) {
        dispatch(setLastSavedChanges(new Date()));
      }
    },
    [dispatch, updateFlow]
  );

  const onSaveFlowDebounced = useMemo(
    () => debounce((newValues) => onSaveFlow(newValues, true), 5000),
    [onSaveFlow]
  );

  const onEditRecord = useCallback(
    (newValues) => {
      pendingChanges.current = newValues;
      onSaveFlowDebounced(newValues);
    },
    [onSaveFlowDebounced]
  );

  useEffect(() => {
    return () => {
      onSaveFlowDebounced.cancel();
      dispatch(setLastSavedChanges(undefined));

      if (pendingChanges.current) {
        onSaveFlow(pendingChanges.current);
      }
    };
  }, [dispatch, onSaveFlowDebounced, onSaveFlow]);

  const onFormChange = (newValues: FlowType) => {
    if (Object.keys(newValues).length) {
      setFlowData(newValues);
      onEditRecord({ ...newValues, templates, slots });
    }
  };

  const onChangeTemplates = (newTemplates: TemplateType[]) => {
    setTemplates(newTemplates);
    onEditRecord({ ...flowData, templates: newTemplates, slots });
  };

  const onChangeSlots = (newSlots: SlotType[]) => {
    setSlots(newSlots);
    onEditRecord({ ...flowData, templates, slots: newSlots });
  };

  return (
    <>
      <FlowContainer>
        <DetailPanel>
          <Container>
            <FlowForm
              initialData={flowData}
              showSave={false}
              step={0}
              deployments={deployments}
              onSubmit={() => {}}
              onChange={onFormChange}
            />
          </Container>
          <ContainerInfo
            title="Conversation Templates"
            info="Info Conversation Templates"
          >
            {templates && (
              <Templates
                templates={templates}
                onChangeTemplates={onChangeTemplates}
                fields={fields}
              />
            )}
          </ContainerInfo>
          <ContainerInfo
            title="Conversation Slots"
            info="Info Conversation Slots"
          >
            {slots && (
              <Slots
                slots={slots}
                onChangeSlots={onChangeSlots}
                objects={objects}
                fields={fields}
              />
            )}
          </ContainerInfo>
        </DetailPanel>
        <FlowProvider flowGraph={graph}>
          <SidebarPanel />
        </FlowProvider>
      </FlowContainer>
      <LoadingIndicator isLoading={isLoading} />
    </>
  );
};
