import React, { useCallback, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { Button } from '@rollioforce/rollio-ui';
import { LoadingIndicator } from '@rollioforce/rollio-admin-ui';

import {
  useFlowsData,
  useInstanceDeploymentsData,
  usePermission,
  useStore,
} from 'src/hooks';
import { formatFlow } from 'src/parser';
import { Container } from 'src/components/Container';
import { SearchForm } from 'src/components/Forms';
import { FlowModal } from 'src/components/Modals';
import { NothingToShow } from 'src/components/NothingToShow';
import { FlowsTable } from 'src/components/Tables';

const TopTable = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  margin-bottom: 10px;
`;

const AddNewButton = styled(Button)`
  margin-left: 10px;
`;

export const FlowsList: React.FC = () => {
  const { activeInstance } = useStore();
  const [searchParams, setSearchParams] = useState<SearchParams>();
  const [selected, setSelected] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formErrors, setFormErrors] = useState<Record<string, string>>({});

  const { isInternal } = usePermission();
  const { isLoading, flows, isMutating, addRecord } = useFlowsData(
    searchParams
  );

  const { deployments } = useInstanceDeploymentsData(activeInstance?.value);
  const DEPLOYMENTS_SELECT_VALUES = useMemo(
    () =>
      deployments.map((d) => ({
        label: isInternal ? `${d.name} [${d.id}]` : d.name,
        value: String(d.id),
      })),
    [deployments, isInternal]
  );

  const onSelectClick = useCallback(
    (flowId, isSelected) => {
      setSelected(
        isSelected
          ? [...selected, flowId]
          : selected.filter((id) => id !== flowId)
      );
    },
    [selected]
  );

  const onSelectAllClick = useCallback(
    (isSelected) => {
      setSelected(isSelected ? flows.map((flow) => flow.id) : []);
    },
    [flows]
  );

  const onAddNewButtonClick = useCallback(() => {
    setIsModalOpen(true);
  }, []);

  const onFormSubmit = useCallback(
    async (data: FlowType, fields: any[]) => {
      try {
        const res = await addRecord(formatFlow(data, fields));

        if (res && res.status === 'error') {
          if (res.errors) {
            return setFormErrors(res.errors);
          }
          return toast(res.message, { type: 'error' });
        }

        setIsModalOpen(false);
      } catch (e) {
        toast(e.message, { type: 'error' });
      }
    },
    [addRecord]
  );

  const isNothingToShowVisible = !isLoading && !searchParams && !flows.length;

  return (
    <>
      {!isNothingToShowVisible ? (
        <Container>
          <TopTable>
            <SearchForm
              initialData={searchParams}
              inputLabel="Search flows by name"
              fields={[
                {
                  key: 'deployment_ids',
                  type: 'select',
                  label: 'Deployment',
                  fieldProps: {
                    options: DEPLOYMENTS_SELECT_VALUES,
                    multiple: true,
                  },
                  valueFormat: (v) =>
                    v
                      ? DEPLOYMENTS_SELECT_VALUES.filter((d) =>
                          v.includes(d.value)
                        )
                      : [],
                  onUpdateFormat: (v) => (v || []).map(({ value }) => value),
                },
              ]}
              onSubmit={setSearchParams}
            />
            <AddNewButton icon="add" onClick={onAddNewButtonClick}>
              Add new
            </AddNewButton>
          </TopTable>
          <FlowsTable
            data={flows}
            keyField="id"
            allowRowSelect
            selectedRows={selected}
            onRowToggle={onSelectClick}
            onToggleAll={onSelectAllClick}
          />
        </Container>
      ) : (
        <NothingToShow
          message="No Flows found, create one now!"
          onButtonClick={onAddNewButtonClick}
        />
      )}

      <FlowModal
        title="Create Flow"
        isOpen={isModalOpen}
        onCloseClick={() => setIsModalOpen(false)}
        form={{
          errors: formErrors,
          initialData: {},
          isSubmitting: isMutating,
          onSubmit: onFormSubmit,
        }}
      />

      <LoadingIndicator isLoading={isLoading} />
    </>
  );
};
