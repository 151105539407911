import React, { useState } from 'react';
import styled from 'styled-components';
import { BigButton, Button, colors, Icon } from '@rollioforce/rollio-ui';

import { SLOT_OPTION } from 'src/constants';

interface AddSlotButtonProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, 'onSubmit'> {
  onSubmit: (option: SLOT_OPTION) => void;
  isChild?: boolean;
  isCancellable?: boolean;
  onCancel?: () => void;
}

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  border: 2px dashed ${colors.offWhite};
  padding: 10px 25px;
  position: relative;
`;

const StyledBigButton = styled(BigButton)`
  margin-left: 10px;
`;

const CloseIcon = styled(Icon)`
  position: absolute;
  z-index: 1;
  top: 4px;
  right: 4px;
  cursor: pointer;
`;

export const AddSlotButton: React.FC<AddSlotButtonProps> = ({
  onSubmit,
  isChild,
  isCancellable,
  onCancel,
  ...props
}) => {
  const [isOptionsVisible, setIsOptionsVisible] = useState(false);

  const onBigButtonClick = (type: SLOT_OPTION) => {
    setIsOptionsVisible(false);
    onSubmit(type);
  };

  return (
    <ButtonContainer {...props}>
      {!isOptionsVisible ? (
        <>
          <Button
            icon="add"
            background="white"
            dark
            onClick={() => setIsOptionsVisible(true)}
          >
            {!isChild ? 'Add Slot' : 'Add Child Slot'}
          </Button>

          {isCancellable && (
            <Button background="white" dark onClick={onCancel}>
              Cancel
            </Button>
          )}
        </>
      ) : (
        <>
          <BigButton
            icon="message"
            title="Prompt"
            description="Requests some data from the user via a dialogue."
            onClick={() => onBigButtonClick(SLOT_OPTION.PROMPT)}
          />
          <StyledBigButton
            icon="split"
            title="Decision Point"
            description="Marks the start of a conditional branch in the conversation."
            onClick={() => onBigButtonClick(SLOT_OPTION.DECISION_POINT)}
          />
          <CloseIcon
            name="close"
            size="small"
            color="greyLight"
            onClick={() => setIsOptionsVisible(false)}
          />
        </>
      )}
    </ButtonContainer>
  );
};
