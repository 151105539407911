import React from 'react';
import { capitalize } from 'lodash';

import { useDeleteModal } from 'src/hooks';

import { ConfirmModal } from './ConfirmModal';

export const ConfirmDeleteModal: React.FC = () => {
  const {
    isLoading,
    isVisible,
    modalData,
    closeDeleteModal,
    confirmDeleteModal,
  } = useDeleteModal();

  return (
    <ConfirmModal
      confirmLabel="Delete"
      isLoading={isLoading}
      isOpen={isVisible}
      message={`Are you sure you wish to delete ${modalData?.type} "${modalData?.data.name}"?`}
      onCloseClick={closeDeleteModal}
      onConfirm={confirmDeleteModal}
      small
      style={{ overlay: { zIndex: 30 } }}
      title={`Delete ${capitalize(modalData?.type)}`}
    />
  );
};
