import { useCallback } from 'react';
import { ORGANIZATIONS_URL } from 'src/constants';

import { useData, useFetcher } from './utils';

export const useOrganizationsData = (search?: SearchParams) => {
  const { send, isLoading } = useFetcher();

  const { data: { data: organizations = [] } = {}, ...data } = useData<
    { data: OrganizationType[] },
    OrganizationType
  >(ORGANIZATIONS_URL, search);

  const generateReport = useCallback(
    async (
      ids: number[],
      date_from: string,
      date_to: string,
      include_successful_failed: boolean
    ) => {
      return send(
        `${ORGANIZATIONS_URL}/report`,
        'POST',
        {
          organization_ids: ids,
          date_from,
          date_to,
          include_successful_failed,
        },
        { downloadFile: true }
      );
    },
    [send]
  );

  return {
    ...data,
    organizations,
    generateReport,
    isLoadingFetcher: isLoading,
  };
};
