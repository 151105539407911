import React, { useEffect, useRef } from 'react';
import { JsonEditor as Editor } from 'jsoneditor-react';

export const JsonEditor: React.FC<any> = ({ value, onChange, ...props }) => {
  const ref = useRef(null);

  useEffect(() => {
    if (ref.current !== null) {
      ref.current.set(value);
    }
  }, [value]);

  const setRef = (instance) => {
    ref.current = instance ? instance.jsonEditor : null;
  };

  return <Editor ref={setRef} value={value} onChange={onChange} {...props} />;
};
