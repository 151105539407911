import React from 'react';
import styled from 'styled-components';
import { Button, ToolTip } from '@rollioforce/rollio-ui';

export interface AddBeforeButtonProps {
  disabled?: boolean;
  onClick?: () => void;
}

const ButtonContainer = styled.div`
  position: relative;
`;

const InnerContainer = styled.div`
  position: absolute;
  left: calc(50% - 16px);
  top: -22px;
`;

const StyledButton = styled(Button)`
  border-radius: 50%;
`;

export const AddBeforeButton: React.FC<AddBeforeButtonProps> = ({
  disabled,
  onClick,
}) => {
  if (disabled) {
    return null;
  }

  return (
    <ButtonContainer>
      <InnerContainer>
        <ToolTip position="top" message="Add slot">
          <StyledButton
            icon="add"
            background="greyLight"
            small
            onClick={onClick}
          />
        </ToolTip>
      </InnerContainer>
    </ButtonContainer>
  );
};
