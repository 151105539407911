import React, { useMemo } from 'react';
import { Form, FormFields, FormProps } from '@rollioforce/rollio-admin-ui';

import { SLOT_OPTION } from 'src/constants';

export interface DecisionPointFormProps extends Partial<FormProps> {
  initialData: any;
  onSubmit: (formDetails: any) => void;
  slots?: SlotType[];
}

const DEFAULT_DECISION_POINT_FORM: Partial<any> = {};

export const DecisionPointForm: React.FC<DecisionPointFormProps> = ({
  initialData,
  slots,
  ...props
}) => {
  const initialFormData = useMemo(() => {
    return {
      ...DEFAULT_DECISION_POINT_FORM,
      ...initialData,
    };
  }, [initialData]);

  const facts = useMemo(
    () =>
      (slots || [])
        .filter((slot) => slot.type === SLOT_OPTION.PROMPT)
        .map((slot) => ({ label: slot.name, value: slot.name })),
    [slots]
  );

  const FORM_FIELDS: FormFields = useMemo(
    () => [
      {
        key: 'name',
        label: 'Name',
        type: 'input',
        validate: 'onChange',
      },
      {
        key: 'rules',
        label: 'Conditions',
        type: 'condition',
        fieldProps: {
          facts,
        },
      },
    ],
    [facts]
  );

  return (
    <Form
      {...props}
      fields={FORM_FIELDS}
      initialData={initialFormData}
      //TODO: Add schema for validation
    />
  );
};
