import React, { useCallback } from 'react';
import { LoginWrap } from '@rollioforce/rollio-admin-ui';

import rollioLogo from 'src/assets/rollio-logo.png';
import { useAuth } from 'src/hooks';

export const Login: React.FC = () => {
  const { login, isLoggingIn, loginError } = useAuth();

  const onFormSubmit = useCallback(
    ({ user, password }) => login(user, password),
    [login]
  );

  return (
    <LoginWrap
      form={{
        error: loginError,
        isSubmitting: isLoggingIn,
        onSubmit: onFormSubmit,
      }}
      logoImage={rollioLogo}
      titleLabel="Rollio CMS"
    />
  );
};
