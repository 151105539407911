import React, { useMemo } from 'react';
import { HeaderNav, HeaderNavProps } from '@rollioforce/rollio-admin-ui';
import { Link, Location, WindowLocation } from '@reach/router';

import { getBreadcrumbs, getRouteUrlById } from 'src/routes';
import { StatusBar } from './StatusBar';

type Props = Pick<HeaderNavProps, 'onMenuButtonClick' | 'padLeft'>;

interface PropsWithLocation extends Props {
  location: WindowLocation;
}

const AppHeaderNavWithLocation: React.FC<PropsWithLocation> = ({
  location,
  ...props
}) => {
  const linkItems = useMemo(
    () =>
      getBreadcrumbs(location.pathname).map(
        ({ id, label, LabelComponent, match, params }) => ({
          id,
          label: LabelComponent ? <LabelComponent {...params} /> : label,
          active: match,
          url: getRouteUrlById(id, params),
        })
      ),
    [location.pathname]
  );

  return (
    <HeaderNav
      {...props}
      linkItems={linkItems}
      renderLink={(id, linkInner) => {
        const linkItem = linkItems.find((item) => item.id === id);
        return (
          <Link to={linkItem?.url} style={{ textDecoration: 'none' }}>
            {linkInner}
          </Link>
        );
      }}
      statusBar={<StatusBar />}
    />
  );
};

export const AppHeaderNav: React.FC<Props> = (props) => (
  <Location>
    {({ location }) => (
      <AppHeaderNavWithLocation {...props} location={location} />
    )}
  </Location>
);
