import React, { useMemo } from 'react';
import { Form, FormFields, FormProps } from '@rollioforce/rollio-admin-ui';

export interface STTReportFormProps extends Partial<FormProps> {
  initialData?: STTReportType;
  onSubmit: (formDetails: STTReportType) => void;
}

export const DEFAULT_STT_SCORE_FORM: STTReportType = {
  score: null,
};

export const STTReportForm: React.FC<STTReportFormProps> = ({
  initialData,
  ...props
}) => {
  const formData = useMemo(
    () => ({
      ...DEFAULT_STT_SCORE_FORM,
      ...initialData,
    }),
    [initialData]
  );

  const FORM_FIELDS: FormFields = useMemo(
    () => [
      {
        key: 'topRow',
        type: 'row',
        fields: [
          {
            key: 'score',
            label: 'Score',
            type: 'input',
            validate: 'onChange',
            fieldProps: {
              type: 'number',
              input: {
                min: 0,
                max: 100,
              },
            },
          },
        ],
        style: { alignItems: 'center' },
      },
    ],
    []
  );

  return (
    <Form
      {...props}
      fields={FORM_FIELDS}
      enableReinitialize
      initialData={formData}
    />
  );
};
