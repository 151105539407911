import { useMemo } from 'react';

import { utils } from 'src/services';

import { useAuth } from './useAuth';

export const useUserProfile = () => {
  const { authToken } = useAuth();

  const userProfileInfo = useMemo(() => utils.getProfileFromToken(authToken), [
    authToken,
  ]);

  return userProfileInfo;
};
