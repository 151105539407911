import { useEffect } from 'react';
import { navigate } from '@reach/router';

import { getRouteUrlById, ROUTES } from 'src/routes';

import { useAuth } from './useAuth';

export const useNavGuard = () => {
  const { isLoggedIn } = useAuth();

  useEffect(() => {
    if (!isLoggedIn) {
      navigate(getRouteUrlById(ROUTES.login), { replace: true });
    }
  }, [isLoggedIn]);
};
