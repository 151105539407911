import { useMemo } from 'react';

import { USERS_URL } from 'src/constants';

import { useData } from './utils';

export const useUserDeploymentsData = (userId: string) => {
  const key = useMemo(() => `${USERS_URL}/${userId}/deployments`, [userId]);

  const { data: { data: deployments = [] } = {}, ...data } = useData<
    { data: DeploymentType[] },
    DeploymentType
  >(key);

  return {
    ...data,
    deployments,
  };
};
