import React from 'react';

import { usePermission } from 'src/hooks';
import { ROLE } from 'src/constants';

interface Props {
  roles: ROLE[];
}

export const Role: React.FC<Props> = ({ roles, children }) => {
  const { checkRole } = usePermission();

  const hasAccess = roles && roles.some(checkRole);
  return hasAccess && <>{children}</>;
};
