import React, { useMemo } from 'react';
import { Table, TableLabel, TableProps } from '@rollioforce/rollio-ui';
import { Columns } from '@rollioforce/rollio-ui/dist/types/components/Table';
import { Link } from '@reach/router';

import { usePermission } from 'src/hooks';
import { getRouteUrlById, ROUTES } from 'src/routes';
import { utils } from 'src/services';

import { TableBitCell } from './TableCells';

interface Props extends Omit<TableProps, 'columns'> {}

export const FlowsTable: React.FC<Props> = ({ data, ...props }) => {
  const { isInternal } = usePermission();

  const columns: Columns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
        width: 80,
        show: isInternal,
      },
      {
        Header: 'Name',
        accessor: 'name',
        Cell: ({
          value,
          original,
        }: {
          index: number;
          value: string;
          original: FlowType;
        }) => (
          <Link
            to={getRouteUrlById(ROUTES.flowDetail, {
              flowId: original.id,
            })}
            style={{ textDecoration: 'none' }}
          >
            <TableLabel isLink variant="header">
              {value}
            </TableLabel>
            {original.created_at && (
              <TableLabel variant="default">
                Last updated: {utils.formatDate(original.created_at)}
              </TableLabel>
            )}
          </Link>
        ),
      },
      {
        Header: 'Slots',
        accessor: 'slots',
        Cell: ({ value }) => (
          <TableLabel variant="code">{value?.length || 0}</TableLabel>
        ),
      },
      {
        Header: 'Deployment',
        accessor: 'deployment.name',
      },
      {
        Header: 'Active',
        accessor: 'is_active',
        Cell: TableBitCell,
      },
    ],
    [isInternal]
  );

  return (
    <Table
      {...props}
      columns={columns}
      data={data}
      showPagination={data.length >= 20}
    />
  );
};
