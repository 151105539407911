import React, { forwardRef } from 'react';
import styled from 'styled-components';

type PatternContainerProps = React.HTMLAttributes<HTMLDivElement>;

const StyledPatternContainer = styled.div`
  display: flex;
  background-color: #ffffff;
  background-image: repeating-linear-gradient(
      45deg,
      #f9f9f9 25%,
      transparent 25%,
      transparent 75%,
      #f9f9f9 75%,
      #f9f9f9
    ),
    repeating-linear-gradient(
      45deg,
      #f9f9f9 25%,
      #ffffff 25%,
      #ffffff 75%,
      #f9f9f9 75%,
      #f9f9f9
    );
  background-position: 0 0, 10px 10px;
  background-size: 20px 20px;
  height: 100%;
  width: 100%;
`;

const ChildrenWrapper = styled.div`
  display: block;
  margin: auto;
`;

export const BackgroundPatternContainer: React.FC<PatternContainerProps> = forwardRef(
  ({ children, ...props }, ref) => {
    return (
      <StyledPatternContainer ref={ref} {...props}>
        <ChildrenWrapper>{children}</ChildrenWrapper>
      </StyledPatternContainer>
    );
  }
);
