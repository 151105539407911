import { useCallback } from 'react';

import {
  setDeleteModal,
  setDeleteModalIsVisible,
  setDeleteModalIsLoading,
} from 'src/store/actions';

import { useDispatch } from './useDispatch';
import { useStore } from './useStore';

export const useDeleteModal = () => {
  const dispatch = useDispatch();
  const {
    deleteModal: { isLoading, isVisible, modalData },
  } = useStore();

  const closeDeleteModal = useCallback(() => {
    dispatch(setDeleteModalIsVisible(false));
  }, [dispatch]);

  const openDeleteModal = useCallback(
    (deleteModalData: DeleteModalData) => {
      dispatch(
        setDeleteModal({
          isLoading: false,
          isVisible: true,
          modalData: deleteModalData,
        })
      );
    },
    [dispatch]
  );

  const confirmDeleteModal = useCallback(() => {
    dispatch(setDeleteModalIsLoading(true));
    modalData.action().finally(() => {
      dispatch(setDeleteModalIsLoading(false));
      dispatch(setDeleteModalIsVisible(false));
      if (modalData.callback) {
        modalData.callback();
      }
    });
  }, [dispatch, modalData]);

  return {
    isLoading,
    isVisible,
    modalData,
    closeDeleteModal,
    openDeleteModal,
    confirmDeleteModal,
  };
};
