import { useCallback, useMemo } from 'react';

import { DEPLOYMENTS_URL } from 'src/constants';

import { useData, useFetcher } from './utils';

export const useSfLinkedUsersData = (deploymentId: number) => {
  const { send, isLoading } = useFetcher();

  const key = useMemo(
    () => `${DEPLOYMENTS_URL}/${deploymentId}/linked-sf-users`,
    [deploymentId]
  );

  const { data: { data: linkedUsers = [] } = {}, ...data } = useData<
    { data: any[] },
    any
  >(key);

  const linkUser = useCallback(
    async (username) => {
      return send(key, 'POST', { username });
    },
    [send, key]
  );

  const unlinkUser = useCallback(
    async (id) => {
      return send(key, 'DELETE', { id });
    },
    [send, key]
  );

  const deleteSfUserByEmail = useCallback(
    async (email) => {
      return send(
        `${DEPLOYMENTS_URL}/${deploymentId}/sf-user/${email}`,
        'DELETE'
      );
    },
    [send, deploymentId]
  );

  return {
    ...data,
    deleteSfUserByEmail,
    isLoadingFetcher: isLoading,
    linkedUsers,
    linkUser,
    unlinkUser,
  };
};
