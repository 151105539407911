import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from '@reach/router';
import { Tabs } from '@rollioforce/rollio-ui';
import { LoadingIndicator } from '@rollioforce/rollio-admin-ui';

import { useDeleteModal, useInstanceData, usePermission } from 'src/hooks';
import { DeleteButton } from 'src/components/Buttons';
import { Container } from 'src/components/Container';
import { DEFAULT_INSTANCE_FORM, InstanceForm } from 'src/components/Forms';
import { Permission } from 'src/components/Permission';
import { ACTION, RESOURCE, ROLE } from 'src/constants';
import { getRouteUrlById, ROUTES } from 'src/routes';
import { mapInstance } from 'src/parser';

import { Deployments } from './Deployments';
import { Users } from './Users';
import { SFUsers } from './SFUsers';
import { Data } from './Data';

interface Props {
  instanceId?: string;
}

export const InstanceDetail: React.FC<Props> = ({ instanceId }) => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(0);
  const [formErrors, setFormErrors] = useState<Record<string, string>>({});

  const {
    instance,
    error,
    isLoading,
    isMutating,
    editRecord,
    deleteRecord,
  } = useInstanceData(instanceId);
  const { openDeleteModal } = useDeleteModal();
  const { checkRole } = usePermission();

  const initialFormState = useMemo(
    () => (instance ? mapInstance(instance) : DEFAULT_INSTANCE_FORM),
    [instance]
  );

  const organizationId = useMemo(() => instance?.organization_id, [instance]);

  const isUsersVisible = useMemo(
    () => checkRole(ROLE.INTERNAL) || checkRole(ROLE.ORGANIZATION),
    [checkRole]
  );

  const TABS = useMemo(
    () => [
      {
        label: 'Deployments',
        content: (
          <Deployments
            instanceId={instanceId}
            organizationId={organizationId}
          />
        ),
      },
      ...(isUsersVisible
        ? [
            {
              label: 'Deployment Administrators',
              content: (
                <Users
                  instanceId={instanceId}
                  organizationId={organizationId}
                />
              ),
            },
          ]
        : []),
      {
        label: 'Salesforce Users',
        content: (
          <SFUsers
            instanceId={instanceId}
            synchedSfUsersAt={instance?.synced_instance_users_at}
          />
        ),
      },
      {
        label: 'Data',
        content: <Data instanceId={instanceId} />,
      },
    ],
    [instanceId, organizationId, isUsersVisible, instance]
  );

  useEffect(() => {
    if (error) {
      toast(error.message, { type: 'error' });
      navigate(getRouteUrlById(ROUTES.home));
    }
  }, [error, navigate]);

  const onFormSubmit = useCallback(
    async (data: OrganizationInstanceType) => {
      try {
        const res = await editRecord(data);

        if (res && res.status === 'error') {
          if (res.errors) {
            return setFormErrors(res.errors);
          }
          return toast(res.message, { type: 'error' });
        }

        toast('Instance updated successfully', { type: 'success' });
      } catch (error) {
        toast(error.message, { type: 'error' });
      }
    },
    [editRecord]
  );

  const onDeleteClick = useCallback(async () => {
    openDeleteModal({
      data: instance,
      type: 'instance',
      action: deleteRecord,
      callback: () =>
        navigate(
          getRouteUrlById(ROUTES.organizationDetail, {
            organizationId,
          })
        ),
    });
  }, [deleteRecord, navigate, openDeleteModal, instance, organizationId]);

  return (
    <>
      <Permission resource={RESOURCE.ORGANIZATION} action={ACTION.READ}>
        <Container>
          <InstanceForm
            errors={formErrors}
            initialData={initialFormState}
            isSubmitting={isMutating}
            onSubmit={onFormSubmit}
            organizationId={organizationId}
          />
        </Container>

        <Container>
          <Tabs
            tabs={TABS}
            onTabSelect={(index) => setActiveTab(index)}
            activeTab={activeTab}
          />
        </Container>
      </Permission>

      <Permission resource={RESOURCE.ORGANIZATION} action={ACTION.DELETE}>
        <DeleteButton label="Delete instance" onClick={onDeleteClick} />
      </Permission>
      <LoadingIndicator isLoading={isLoading} />
    </>
  );
};
