import React from 'react';
import styled from 'styled-components';
import { Button } from '@rollioforce/rollio-ui';

import { Modal, ModalProps } from './Modal';

const TextBodyContainer = styled.div`
  flex: 1;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const StyledButton = styled(Button)`
  margin-left: 10px;
`;

interface Props extends Omit<ModalProps, 'children'> {
  confirmLabel?: string;
  isLoading?: boolean;
  message: string;
  onConfirm?: () => void;
}

export const ConfirmModal: React.FC<Props> = ({
  confirmLabel = 'Confirm',
  isLoading = false,
  message,
  onConfirm,
  ...props
}) => (
  <Modal {...props}>
    <TextBodyContainer>{message}</TextBodyContainer>
    <ButtonContainer>
      <StyledButton
        background="white"
        dark
        disabled={isLoading}
        onClick={props.onCloseClick}
      >
        Cancel
      </StyledButton>
      <StyledButton
        background="red"
        disabled={isLoading}
        loading={isLoading}
        onClick={onConfirm}
      >
        {confirmLabel}
      </StyledButton>
    </ButtonContainer>
  </Modal>
);
