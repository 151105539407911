import React from 'react';
import styled, { css } from 'styled-components';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { colors } from '@rollioforce/rollio-ui';
import { FormData } from '@rollioforce/rollio-admin-ui/dist/types/components/Form';

import { SLOT_OPTION } from 'src/constants';
import {
  deleteSlot,
  findSlot,
  insertBeforeSlot,
  makeNewSlot,
  reorderSlots,
  updateNodePaths,
  updateSlot,
} from 'src/services/utils';

import { AddSlotButton } from './AddSlotButton';
import { EditableSlot } from './EditableSlot';

export interface SlotsProps {
  slots?: SlotType[];
  objects?: any[];
  fields?: any[];
  onChangeSlots: (slots: SlotType[]) => void;
}

const SlotsContainer = styled.div`
  margin-bottom: 10px;
`;

const DroppableContainer = styled.div(
  ({ isDraggingOver }) => css`
    padding: 10px;
    background: ${isDraggingOver ? colors.offWhite : colors.white};
  `
);

export const Slots: React.FC<SlotsProps> = ({
  slots = [],
  onChangeSlots,
  objects,
  fields,
}) => {
  const onAddSlot = (type: SLOT_OPTION) => {
    const nodePath = String(slots.length + 1);
    const newSlot = makeNewSlot(type, nodePath);
    onChangeSlots([...slots, newSlot]);
  };

  const onAddChildSlot = (
    type: SLOT_OPTION,
    slot: SlotType,
    condition: boolean
  ) => {
    const conditionField = condition ? 'condition_true' : 'condition_false';
    const children = slot[conditionField] || [];
    const nodePath = `${slot.node_path}.${String(children.length + 1)}${
      condition ? 'T' : 'F'
    }`;
    const newSlot = makeNewSlot(type, nodePath);
    const payload = {
      ...slot,
      [conditionField]: [...children, newSlot],
    };

    const newSlots = updateSlot(slots, slot.node_path, payload);
    onChangeSlots(newSlots);
  };

  const onChange = (data: FormData) => {
    const { node_path, detail } = data;
    const selectedField = fields?.find(
      (field) => field.api_name === detail.configuration.api
    );
    const payload = {
      name: detail.configuration.name || '',
      field_id: selectedField?.id,
      detail,
    };
    const newSlots = updateSlot(slots, node_path, payload);
    onChangeSlots(newSlots);
  };

  const onDeleteSlot = (slot: SlotType) => {
    const newSlots = updateNodePaths(deleteSlot(slots, slot.node_path));
    onChangeSlots(newSlots);
  };

  const onAddBeforeSlot = (type: SLOT_OPTION, slot: SlotType) => {
    const nodePath = String(slots.length + 1);
    const newSlot = makeNewSlot(type, nodePath);
    const newSlots = insertBeforeSlot(slots, slot.node_path, newSlot);
    onChangeSlots(updateNodePaths(newSlots));
  };

  const onDragEnd = (result) => {
    // dropped outside
    if (!result.destination) {
      return;
    }

    if (result.type === 'DEFAULT') {
      // dropped main slot
      const newSlots = reorderSlots(
        slots,
        result.source.index,
        result.destination.index
      );
      onChangeSlots(updateNodePaths(newSlots));
    } else {
      // dropped nested slot
      const [nodePath, condition] = result.type.split('-');
      const currentSlot = findSlot(slots, nodePath);
      const newSlot = {
        ...currentSlot,
        [condition]: reorderSlots(
          currentSlot[condition],
          result.source.index,
          result.destination.index
        ),
      };
      const newSlots = updateSlot(slots, nodePath, newSlot);
      onChangeSlots(updateNodePaths(newSlots));
    }
  };

  return (
    <>
      <SlotsContainer>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="main">
            {(provided, snapshot) => (
              <DroppableContainer
                {...provided.droppableProps}
                ref={provided.innerRef}
                isDraggingOver={snapshot.isDraggingOver}
              >
                {slots.map((slot, i) => {
                  const isActive = !slot.id;

                  return (
                    <EditableSlot
                      key={slot.internal_id}
                      isActive={isActive}
                      slot={slot}
                      onAddBeforeSlot={onAddBeforeSlot}
                      onAddChildSlot={onAddChildSlot}
                      onChange={onChange}
                      slots={slots}
                      objects={objects}
                      fields={fields}
                      onDeleteSlot={onDeleteSlot}
                      index={i}
                    />
                  );
                })}
                {provided.placeholder}
              </DroppableContainer>
            )}
          </Droppable>
        </DragDropContext>
      </SlotsContainer>

      <AddSlotButton onSubmit={onAddSlot} />
    </>
  );
};
