import React, { useMemo } from 'react';
import { Table, TableProps, TableLabel, Icon } from '@rollioforce/rollio-ui';
import { Columns } from '@rollioforce/rollio-ui/dist/types/components/Table';

import { usePermission } from 'src/hooks';
import { STT_AUDIO_URL } from 'src/constants';

import { TableDateCell } from './TableCells';

interface Props extends Omit<TableProps, 'columns'> {
  onEditSTTReport: (data: STTReportType) => void;
}

export const STTReportingTable: React.FC<Props> = ({
  data,
  onEditSTTReport,
  ...props
}) => {
  const { isInternal } = usePermission();

  const columns: Columns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
        width: 80,
        show: isInternal,
      },
      {
        Header: 'Audio',
        accessor: 'link',
        width: 360,
        Cell: ({ value }) => {
          const filename = value.split('/').pop();
          const src = `${STT_AUDIO_URL}/${filename}`;

          return (
            <audio controls>
              <source src={src} type="audio/wav" />
              Your browser does not support the audio element.
            </audio>
          );
        },
      },
      {
        Header: 'Transcript',
        accessor: 'transcript',
      },
      {
        Header: 'Post process text',
        accessor: 'text',
      },
      {
        Header: 'Message',
        accessor: 'message',
      },
      {
        Header: 'Created at',
        accessor: 'created_at',
        Cell: TableDateCell,
      },
      {
        Header: 'Score',
        accessor: 'score',
      },
      {
        Header: '',
        accessor: 'actions',
        Cell: ({ original }) => (
          <TableLabel
            isLink
            onClick={() => onEditSTTReport(original)}
            title="Edit score"
          >
            <Icon name="edit" size="small" />
          </TableLabel>
        ),
      },
    ],
    [onEditSTTReport, isInternal]
  );

  return (
    <Table
      {...props}
      columns={columns}
      data={data}
      showPagination={data.length >= 20}
    />
  );
};
