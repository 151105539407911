import React, { forwardRef, useMemo } from 'react';
import styled from 'styled-components';
import { Form, FormFields, FormProps } from '@rollioforce/rollio-admin-ui';
import { FormRef } from '@rollioforce/rollio-admin-ui/dist/types/components/Form';

import { colors } from '@rollioforce/rollio-ui';
import { conversation } from '@rollioforce/rollio-schemas';
import { FLOW_STEP } from 'src/constants';
import { usePermission } from 'src/hooks';

import { LabelField, SlotsField } from './CustomFields';

export interface FlowFormProps extends Partial<FormProps> {
  initialData: FlowType;
  step: number;
  deployments: any[];
  intents?: any[];
  objects?: any[];
  fields?: any[];
  onSubmit: (formDetails: FlowType, fields?: any[]) => void;
}

const SlotsFieldContainer = styled.div`
  border-left: 1px solid ${colors.offWhite};
  padding-left: 20px;
`;

export const DEFAULT_FLOW_FORM: FlowType = {};

export const FlowForm = forwardRef(
  (
    {
      initialData,
      step,
      deployments,
      intents,
      objects,
      fields,
      onSubmit,
      ...props
    }: FlowFormProps,
    ref: React.Ref<FormRef>
  ) => {
    const { isInternal } = usePermission();
    const initialFormData = useMemo(() => {
      return {
        ...DEFAULT_FLOW_FORM,
        ...initialData,
      };
    }, [initialData]);

    const onFormSubmit = (values: any) => {
      onSubmit(values, fields);
    };

    const DEPLOYMENTS_SELECT_VALUES = useMemo(
      () =>
        deployments.map((d) => ({
          label: isInternal ? `${d.name} [${d.id}]` : d.name,
          value: String(d.id),
        })),
      [deployments, isInternal]
    );

    const INTENT_ACTION_SELECT_VALUES = useMemo(
      () =>
        intents?.map((i) => ({
          label: i.type,
          value: String(i.id),
        })),
      [intents]
    );

    const INTENT_DATA_SELECT_VALUES = useMemo(
      () =>
        objects?.map((i) => ({
          label: i.label,
          value: String(i.id),
        })),
      [objects]
    );

    const AVAILABLE_FIELDS_DATA = useMemo(
      () =>
        fields?.map((i) => ({
          id: i.api_name,
          display: i.label,
        })),
      [fields]
    );

    const isReadOnly = useMemo(() => step === FLOW_STEP.CONFIRMATION, [step]);

    const FORM_FIELDS: FormFields = useMemo(
      () => [
        {
          key: 'firstStep',
          type: 'row',
          isVisible:
            step === FLOW_STEP.INITIAL_STEP || step === FLOW_STEP.CONFIRMATION,
          fields: [
            {
              key: 'name',
              label: 'Name',
              type: 'input',
              validate: 'onChange',
            },
            {
              key: 'deployment_id',
              label: 'Deployment',
              type: 'select',
              validate: 'onChange',
              fieldProps: {
                options: DEPLOYMENTS_SELECT_VALUES,
                maxMenuHeight: 200,
                menuPortalTarget: document.body,
              },
              valueFormat: (v) =>
                v ? DEPLOYMENTS_SELECT_VALUES.find((i) => i.value === v) : null,
              onUpdateFormat: ({ value }) => value,
            },
          ],
        },
        {
          key: 'secondStep',
          type: 'row',
          isVisible:
            step === FLOW_STEP.INTENT || step === FLOW_STEP.CONFIRMATION,
          fields: [
            {
              key: 'label_1',
              type: 'custom',
              isVisible: step === FLOW_STEP.INTENT,
              CustomComponent: () => <LabelField label="I want to" />,
            },
            {
              key: 'intent_id',
              label: 'Action',
              type: 'select',
              validate: 'onChange',
              fieldProps: {
                options: INTENT_ACTION_SELECT_VALUES,
                maxMenuHeight: 200,
                menuPortalTarget: document.body,
              },
              valueFormat: (v) =>
                v
                  ? INTENT_ACTION_SELECT_VALUES.find((i) => i.value === v)
                  : null,
              onUpdateFormat: ({ value }) => value,
            },
            {
              key: 'object_id',
              label: 'Data',
              type: 'select',
              validate: 'onChange',
              fieldProps: {
                options: INTENT_DATA_SELECT_VALUES,
                maxMenuHeight: 200,
                menuPortalTarget: document.body,
                isSearchable: true,
              },
              valueFormat: (v) =>
                v ? INTENT_DATA_SELECT_VALUES.find((i) => i.value === v) : null,
              onUpdateFormat: ({ value }) => value,
            },
          ],
        },
        {
          key: 'thirdStep',
          type: 'row',
          isVisible:
            step === FLOW_STEP.CONVERSATION_TEMPLATE ||
            step === FLOW_STEP.CONFIRMATION,
          fields: [
            {
              key: 'template',
              label: 'Conversation Template',
              type: 'textarea',
              fieldProps: {
                style: {
                  minWidth:
                    step === FLOW_STEP.CONVERSATION_TEMPLATE ? 500 : 580,
                },
                mentions: AVAILABLE_FIELDS_DATA,
                minHeight: 200,
              },
            },
            {
              key: 'column_slots',
              type: 'custom',
              isVisible: step === FLOW_STEP.CONVERSATION_TEMPLATE,
              CustomComponent: ({ formData }) => (
                <SlotsFieldContainer>
                  <SlotsField
                    label="Content Slots"
                    template={formData?.template}
                    fields={fields}
                  />
                </SlotsFieldContainer>
              ),
            },
          ],
        },
        {
          key: 'preview_column_slots',
          type: 'custom',
          isVisible: step === FLOW_STEP.CONFIRMATION,
          CustomComponent: ({ formData }) => (
            <SlotsField label="Content Slots" template={formData?.template} />
          ),
        },
      ],
      [
        step,
        fields,
        DEPLOYMENTS_SELECT_VALUES,
        INTENT_ACTION_SELECT_VALUES,
        INTENT_DATA_SELECT_VALUES,
        AVAILABLE_FIELDS_DATA,
      ]
    );

    return (
      <Form
        {...props}
        enableReinitialize
        fields={FORM_FIELDS}
        initialData={initialFormData}
        isReadOnly={isReadOnly}
        ref={ref}
        schema={conversation}
        onSubmit={onFormSubmit}
      />
    );
  }
);
