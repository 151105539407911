import {
  AuthToken,
  DeleteModal,
  InstanceType,
  IsSidebarExpanded,
} from './state';
import {
  SET_ACTIVE_DEPLOYMENT_ID,
  SET_ACTIVE_INSTANCE,
  SET_AUTH_TOKEN,
  SET_DELETE_MODAL,
  SET_DELETE_MODAL_IS_LOADING,
  SET_DELETE_MODAL_IS_VISIBLE,
  SET_IS_SIDEBAR_EXPANDED,
  SET_LAST_SAVED_CHANGES,
  SET_SF_TOKEN,
} from './actionTypes';

export const setActiveDeploymentId = (
  deploymentId: string
): SET_ACTIVE_DEPLOYMENT_ID => ({
  type: 'SET_ACTIVE_DEPLOYMENT_ID',
  payload: deploymentId,
});

export const setActiveInstance = (
  instance: InstanceType
): SET_ACTIVE_INSTANCE => ({
  type: 'SET_ACTIVE_INSTANCE',
  payload: instance,
});

export const setAuthToken = (authToken: AuthToken): SET_AUTH_TOKEN => ({
  type: 'SET_AUTH_TOKEN',
  payload: authToken,
});

export const setDeleteModal = (deleteModal: DeleteModal): SET_DELETE_MODAL => ({
  type: 'SET_DELETE_MODAL',
  payload: deleteModal,
});

export const setDeleteModalIsLoading = (
  isLoading: boolean
): SET_DELETE_MODAL_IS_LOADING => ({
  type: 'SET_DELETE_MODAL_IS_LOADING',
  payload: isLoading,
});

export const setDeleteModalIsVisible = (
  isVisible: boolean
): SET_DELETE_MODAL_IS_VISIBLE => ({
  type: 'SET_DELETE_MODAL_IS_VISIBLE',
  payload: isVisible,
});

export const setIsSidebarExpanded = (
  isExpanded: IsSidebarExpanded
): SET_IS_SIDEBAR_EXPANDED => ({
  type: 'SET_IS_SIDEBAR_EXPANDED',
  payload: isExpanded,
});

export const setLastSavedChanges = (date: Date): SET_LAST_SAVED_CHANGES => ({
  type: 'SET_LAST_SAVED_CHANGES',
  payload: date,
});

export const setSfToken = (sfToken: AuthToken): SET_SF_TOKEN => ({
  type: 'SET_SF_TOKEN',
  payload: sfToken,
});
