import React, { useContext, useEffect, useRef } from 'react';
import Graphin, {
  Behaviors,
  G6,
  GraphinContext,
  IG6GraphEvent,
} from '@antv/graphin';
import { INode, NodeConfig } from '@antv/g6';
import styled from 'styled-components';
import ActivateRelations from '@antv/graphin/es/behaviors/ActivateRelations';
import { colors } from '@rollioforce/rollio-ui';
import { BackgroundPatternContainer } from '../Container/BackgroundPatternContainer';
import { getBezierPath } from './GraphPositionUtils';
import { useFlowGraphData, useWindowSize } from '../../hooks';
import { sliceText } from '../../services/utils';

const { ZoomCanvas, DragNode } = Behaviors;

interface FlowChartProps {
  isOpen: boolean;
}

const initializeGraphComponents = () => {
  Graphin.registerEdge('line', {
    setState(name, value, item) {
      const group = item.getContainer();
      const shape = group.get('children')[0];

      if (name === 'active') {
        if (value) {
          shape.attr('stroke', colors.primary);
        } else {
          shape.attr('stroke', colors.secondary);
        }
      }
    },
    draw(cfg, group) {
      const { startPoint, endPoint } = cfg;
      const path = getBezierPath({
        sourceX: startPoint.x,
        sourceY: startPoint.y,
        targetX: endPoint.x,
        targetY: endPoint.y,
      });

      return group.addShape('path', {
        attrs: {
          path: path,
          stroke: colors.secondary,
          lineWidth: 1,
          startArrow: {
            path: G6.Arrow.circle(3, 0),
            d: 0,
            fill: colors.primary,
          },
          endArrow: {
            path: G6.Arrow.circle(3, 0),
            d: 0,
            fill: colors.primary,
          },
        },
        name: 'path-shape',
      });
    },
    afterDraw(cfg, group) {
      if (cfg.label) {
        const shape = group.get('children')[0];
        const midPoint = shape.getPoint(0.5);
        group.addShape('rect', {
          attrs: {
            width: 35,
            height: 15,
            fill: '#fff',
            lineWidth: 1,
            x: midPoint.x - 17,
            y: midPoint.y - 10,
          },
        });
        group.addShape('text', {
          attrs: {
            fontSize: 10,
            textAlign: 'center',
            x: midPoint.x,
            y: midPoint.y + 3,
            text: cfg.label,
            fill: colors.greyDark,
            fontWeight: 600,
          },
          draggable: true,
          name: 'text',
        });
      }
    },
  });
  Graphin.registerNode(
    'Prompt',
    {
      options: {
        style: {},
        stateStyles: {
          selected: {
            stroke: colors.primary,
            fill: '#FFFFFF',
            lineWidth: 1.5,
          },
        },
      },
      draw(cfg, group) {
        const keyshape = group.addShape('rect', {
          attrs: {
            x: -85,
            y: -30,
            width: 170,
            height: 60,
            radius: 4,
            stroke: colors.secondary,
            fill: '#FFFFFF',
            lineWidth: 1.5,
            shadowColor: 'rgba(0,0,0,0.15)',
            shadowOffsetX: 5,
            shadowOffsetY: 5,
            shadowBlur: 20,
          },
          draggable: true,
          name: 'circle-floor',
        });
        group.addShape('text', {
          attrs: {
            fontSize: 12,
            textAlign: 'center',
            overflow: 'hidden',
            x: 0,
            y: 0,
            text: sliceText(cfg.name, 20),
            fill: colors.greyDark,
            fontWeight: 600,
          },
          draggable: true,
          name: 'text',
        });
        if (cfg.text) {
          group.addShape('text', {
            attrs: {
              text: sliceText(cfg.text, 26),
              x: 0,
              y: cfg.name ? 10 : 0,
              fill: colors.greyLight,
              fontSize: 10,
              textAlign: 'center',
              textBaseline: 'middle',
            },
          });
        }
        return keyshape;
      },
      getAnchorPoints() {
        return [
          [0.5, 0],
          [0.5, 1],
        ];
      },
      update(cfg, node) {
        const group = node.getContainer();
        const name = group.get('children')[1];
        const text = group.get('children')[2];
        text.attr({ text: sliceText(cfg.text, 26) });
        name.attr({ text: sliceText(cfg.name, 20) });
      },
    },
    'single-node'
  );
  Graphin.registerNode(
    'Decision Point',
    {
      options: {
        style: {},
        stateStyles: {
          selected: {
            stroke: colors.primary,
            fill: '#FFFFFF',
            lineWidth: 1.5,
          },
        },
      },
      draw(cfg, group) {
        const keyshape = group.addShape('polygon', {
          attrs: {
            points: [
              [-85, 0],
              [0, 30],
              [85, 0],
              [0, -30],
            ],
            lineJoin: 'round',
            fill: 'white',
            stroke: colors.secondary,
            lineWidth: 1.5,
            shadowColor: 'rgba(0,0,0,0.15)',
            shadowOffsetX: 5,
            shadowOffsetY: 5,
            shadowBlur: 20,
          },
          name: 'polygon-shape',
        });

        group.addShape('text', {
          attrs: {
            fontSize: 10,
            textAlign: 'center',
            x: 0,
            y: 5,
            text: sliceText(cfg.name, 20),
            fill: colors.greyDark,
            fontWeight: 600,
          },
          draggable: true,
          name: 'text',
        });

        return keyshape;
      },
      getAnchorPoints() {
        return [
          [0.5, 0],
          [0.5, 1],
        ];
      },
      update(cfg, node) {
        const group = node.getContainer();
        const name = group.get('children')[1];
        name.attr({ text: sliceText(cfg.name, 20) });
      },
    },
    'single-node'
  );
};

initializeGraphComponents();

const StyledBackgroundPatternContainer = styled(BackgroundPatternContainer)`
  flex: 1;
  overflow: hidden;
`;

const FlowChart: React.FC<FlowChartProps> = ({ isOpen }) => {
  const flowGraphData = useFlowGraphData();
  const windowSize = useWindowSize();
  const graphinRef = useRef(null);
  const containerRef = useRef(null);

  useEffect(() => {
    if (containerRef.current && graphinRef.current) {
      const { clientWidth, clientHeight } = containerRef.current;
      graphinRef.current.graph.changeSize(clientWidth, clientHeight);
    }
  }, [isOpen, windowSize]);

  const OnClickNode: React.FC = () => {
    const { graph, apis } = useContext(GraphinContext);
    useEffect(() => {
      const handleClick = (evt: IG6GraphEvent) => {
        const node = evt.item as INode;
        const model = node.getModel() as NodeConfig;
        apis.focusNodeById(model.id);
        node.setState('active', true);
      };
      graph.on('node:click', handleClick);
      return () => {
        graph.off('node:click', handleClick);
      };
    }, [graph, apis]);
    return null;
  };

  return (
    <StyledBackgroundPatternContainer ref={containerRef}>
      <Graphin
        ref={graphinRef}
        data={flowGraphData}
        layout={{ type: 'dagre' }}
        theme={{ background: 'rgba(0,0,0,0)' }}
      >
        <OnClickNode />
        <ZoomCanvas enableOptimize disabled />
        <DragNode disabled />
        <ActivateRelations trigger="click" />
      </Graphin>
    </StyledBackgroundPatternContainer>
  );
};

export default FlowChart;
