import React, { useEffect } from 'react';

export interface FlowContextInterface {
  graph: Graph;
}

const FlowContext = React.createContext<FlowContextInterface | null>(null);

export function FlowProvider(props) {
  const { flowGraph } = props;
  const [graph, setGraph] = React.useState(flowGraph);

  useEffect(() => {
    setGraph(flowGraph);
  }, [flowGraph]);

  const flowContextValue: FlowContextInterface = { graph };

  return <FlowContext.Provider value={flowContextValue} {...props} />;
}

export function useFlowContext(): FlowContextInterface {
  const context = React.useContext(FlowContext);
  if (!context) {
    throw new Error(`useFlowContext must be used inside FlowContext`);
  }
  return context;
}
