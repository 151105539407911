import React, { useMemo } from 'react';
import styled from 'styled-components';

import { TableMessage } from './TableMessage';
import { TextMessage } from './TextMessage';

interface Props {
  messages?: ConversationMessageType[];
}

interface MessageProps {
  message: Rollio.Message;
  createdAt: string;
  isBot: boolean;
}

enum ConversationMessageTypes {
  TEXT = 0,
  PICKLIST = 1,
  TABLE = 2,
  SEARCH = 3,
  QUICK_REPLY = 4,
  MULTI_PICKLIST = 5,
}

const StyledMessageList = styled.ul`
  margin: 0;
  padding: 0;
  position: relative;
  list-style: none;
`;

const StyledMessage = styled.li`
  display: flex;
  margin-top: 12px;
  flex-direction: row;
  justify-content: ${({ isBot }) => (!isBot ? 'flex-end' : 'flex-start')};
  align-items: flex-start;
  width: 100%;
`;

const Message: React.FC<MessageProps> = ({ message, createdAt, isBot }) => {
  const Component = useMemo(() => {
    switch (message.type) {
      case ConversationMessageTypes.TEXT: {
        const props = message as Messages.TextMessage;
        return <TextMessage {...props} isBot={isBot} createdAt={createdAt} />;
      }
      case ConversationMessageTypes.TABLE: {
        const props = message as Messages.ViewRecordMessage;
        return <TableMessage {...props} createdAt={createdAt} />;
      }
      default:
        return null;
    }
  }, [createdAt, isBot, message]);

  return Component;
};

export const MessageList: React.FC<Props> = ({ messages }) => (
  <StyledMessageList>
    {messages?.map(({ id, isBot, createdAt, ...message }) => (
      <StyledMessage key={id} isBot={isBot}>
        <Message message={message} createdAt={createdAt} isBot={isBot} />
      </StyledMessage>
    ))}
  </StyledMessageList>
);
