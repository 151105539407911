import React, { useMemo } from 'react';
import { Form, FormFields, FormProps } from '@rollioforce/rollio-admin-ui';
import { instance } from '@rollioforce/rollio-schemas';

export interface InstanceFormProps extends Partial<FormProps> {
  initialData?: Omit<OrganizationInstanceType, 'organization_id'>;
  onSubmit: (formDetails: OrganizationInstanceType) => void;
  organizationId: number;
}

export const DEFAULT_INSTANCE_FORM: Omit<
  OrganizationInstanceType,
  'organization_id'
> = {
  name: '',
  is_active: 1,
  instance_url: null,
  login_url: '',
};

export const InstanceForm: React.FC<InstanceFormProps> = ({
  initialData,
  ...props
}) => {
  const formData = useMemo(() => {
    return {
      ...DEFAULT_INSTANCE_FORM,
      ...initialData,
    };
  }, [initialData]);

  const isEditing = useMemo(() => !!initialData, [initialData]);

  const FORM_FIELDS: FormFields = useMemo(
    () => [
      {
        key: 'topRow',
        type: 'row',
        fields: [
          {
            key: 'name',
            label: 'Name',
            type: 'input',
            validate: 'onChange',
          },
          {
            key: 'is_active',
            label: 'Active',
            type: 'checkbox',
            validate: 'onChange',
            valueFormat: (v) => v === 1,
            onUpdateFormat: (v) => (v ? 1 : 0),
          },
        ],
        style: { alignItems: 'center' },
      },
      {
        key: 'urlsRow',
        type: 'row',
        fields: [
          {
            key: 'instance_url',
            label: 'Instance URL',
            type: 'input',
            isVisible: isEditing,
            fieldProps: {
              disabled: true,
            },
          },
          {
            key: 'login_url',
            label: 'Login URL',
            type: 'input',
            validate: 'onChange',
          },
        ],
      },
    ],
    [isEditing]
  );

  return (
    <Form
      {...props}
      enableReinitialize
      fields={FORM_FIELDS}
      initialData={formData}
      schema={instance}
    />
  );
};
