import React from 'react';
import styled from 'styled-components';
import { Button, colors } from '@rollioforce/rollio-ui';
import { navigate } from '@reach/router';

import { getRouteUrlById, ROUTES } from 'src/routes';

interface Props {
  title: string;
}

const ErrorContainer = styled.div`
  padding: 20px;
  color: ${colors.greyDark};
  text-align: center;
`;
const ErrorTitle = styled.h1`
  font-size: 30px;
  font-weight: 600;
  margin: 0 0 12px;
`;
const ErrorDescription = styled.p`
  font-size: 20px;
  font-weight: 600;
  margin: 0 0 30px;
`;

export const Error: React.FC<Props> = ({ title, children }) => (
  <ErrorContainer>
    <ErrorTitle>{title}</ErrorTitle>
    <ErrorDescription>{children}</ErrorDescription>
    <Button dark onClick={() => navigate(getRouteUrlById(ROUTES.home))}>
      Back to Dashboard
    </Button>
  </ErrorContainer>
);
