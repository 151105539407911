import { useMemo } from 'react';

import { INSTANCES_URL } from 'src/constants';

import { useData } from './utils';

export const useInstanceUsersData = (
  instanceId: string,
  search?: SearchParams
) => {
  const key = useMemo(() => `${INSTANCES_URL}/${instanceId}/users`, [
    instanceId,
  ]);

  const { data: { data: users = [] } = {}, ...data } = useData<
    { data: OrganizationUserType[] },
    OrganizationUserType
  >(key, search);

  return {
    ...data,
    users,
  };
};
