import { INTENTS_URL } from 'src/constants';

import { useData } from './utils';

export const useIntentsData = () => {
  const { data: { data: intents = [] } = {}, ...data } = useData<
    { data: IntentType[] },
    IntentType
  >(INTENTS_URL);

  return {
    ...data,
    intents,
  };
};
