import React from 'react';
import styled from 'styled-components';
import {
  Modal as RollioModal,
  ModalProps as RollioModalProps,
} from '@rollioforce/rollio-ui';

export interface ModalProps extends RollioModalProps {}

const StyledRollioModal = styled(RollioModal)`
  &__content {
    position: relative;
    height: auto;
  }
`;

export const Modal: React.FC<ModalProps> = (props) => (
  <StyledRollioModal {...props} />
);
