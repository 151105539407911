import React from 'react';
import { RouteComponentProps } from '@reach/router';

import { Dashboard as DashboardLayout } from 'src/layouts/Dashboard';
import { PageWrap } from 'src/components/PageWrap';

export const Dashboard: React.FC<RouteComponentProps> = () => (
  <PageWrap>
    <DashboardLayout />
  </PageWrap>
);
