import React from 'react';

import { useOrganizationData, useDeploymentData } from 'src/hooks';

export const OrganizationName: React.FC<any> = ({ organizationId }) => {
  const { organization, isLoading } = useOrganizationData(organizationId);
  return <>{isLoading ? '...' : organization?.name}</>;
};

export const DeploymentName: React.FC<any> = ({ deploymentId }) => {
  const { deployment, isLoading } = useDeploymentData(deploymentId);
  return <>{isLoading ? '...' : deployment?.name}</>;
};
