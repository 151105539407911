import React from 'react';

import {
  STTReportForm,
  STTReportFormProps,
} from 'src/components/Forms';

import { Modal, ModalProps } from './Modal';

interface Props extends Omit<ModalProps, 'children' | 'small'> {
  form: STTReportFormProps;
}

export const STTReportModal: React.FC<Props> = ({ form, ...props }) => (
  <Modal {...props}>
    <STTReportForm {...form} />
  </Modal>
);
