import React from 'react';
import styled from 'styled-components';
import { colors, fonts, Icon } from '@rollioforce/rollio-ui';

import { formatDate } from 'src/services/utils';

import { Footer } from './Footer';

interface TableMessageProps
  extends Pick<Messages.ViewRecordMessage, 'fields' | 'title'> {
  createdAt: string;
}

const TableOuter = styled.div`
  padding-top: 12px;
  max-width: 94%;
`;

const TableWrap = styled.div`
  background: ${colors.greyDark};
  border-radius: 8px;
  padding-bottom: 1px;
`;

const HeaderWrap = styled.div`
  flex-direction: row;
  padding: 12px 10px;
`;

const HeaderTitle = styled.span`
  color: ${colors.white};
  font-family: ${fonts.default};
  font-weight: 700;
  font-size: 13px;
  text-transform: uppercase;
  flex: 1;
  padding-right: 10px;
`;

const HeaderRight = styled.span`
  color: ${colors.greyDark};
  font-family: ${fonts.default};
  font-size: 12px;
`;

const TableRowsOuter = styled.div`
  background: ${colors.white};
  border-radius: 4px;
  margin: 0 5px 5px;
`;

const RowWrap = styled.div`
  flex-direction: row;
  padding: 8px 5px;
  border-color: ${colors.greyDark};
  border-bottom-width: ${({ isLast }) => (!isLast ? '1px' : 0)};
  display: flex;
`;

const RowTitle = styled.span`
  width: 30%;
  font-family: ${fonts.default};
  font-weight: 700;
  font-size: 13px;
  text-align: right;
  margin-right: 10px;
  color: ${colors.greyLight};
  padding-top: 1px;
`;

const RowTextValue = styled.span`
  flex: 1;
  font-family: ${fonts.default};
  font-size: 15px;
  color: ${colors.greyLight};
`;

const TableHeader = ({ title, time }: { title: string; time?: string }) => (
  <HeaderWrap>
    <HeaderTitle>{title}</HeaderTitle>
    <HeaderRight>{time}</HeaderRight>
  </HeaderWrap>
);

const RowValue = ({ type, value }: { type: string; value: any }) => {
  const v = value ? value.messageText || value : '';

  if (type === 'Checkbox') {
    return !!v && <Icon name="check" size="small" color="greyLight" />;
  }

  return <RowTextValue>{v}</RowTextValue>;
};

const TableRows = ({ data }: { data: any }) => (
  <>
    {data.map(({ name, value, type }, index) => {
      const isLast = index === data.length - 1;

      return (
        <RowWrap key={name} isLast={isLast}>
          <RowTitle>{name}</RowTitle>
          <RowValue type={type} value={value} />
        </RowWrap>
      );
    })}
  </>
);

export const TableMessage: React.FC<TableMessageProps> = ({
  createdAt,
  fields,
  title,
}) => {
  return (
    <TableOuter>
      <TableWrap>
        <TableHeader title={title} time={formatDate(createdAt)} />

        <TableRowsOuter>
          <TableRows data={fields} />
        </TableRowsOuter>
      </TableWrap>

      <Footer isBot time={formatDate(createdAt)} />
    </TableOuter>
  );
};
