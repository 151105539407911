import React, { useMemo } from 'react';
import { Form, FormFields, FormProps } from '@rollioforce/rollio-admin-ui';
import { configuration } from '@rollioforce/rollio-schemas';

import { JsonEditor } from '../JsonEditor';

export interface ConfigurationFormProps extends Partial<FormProps> {
  initialData?: ConfigurationType;
  onSubmit: (formDetails: ConfigurationType) => void;
}

export const DEFAULT_CONFIGURATION_FORM: ConfigurationType = {
  config: '{}',
  is_active: 1,
};

export const ConfigurationForm: React.FC<ConfigurationFormProps> = ({
  initialData,
  ...props
}) => {
  const formData = useMemo(() => {
    return {
      ...DEFAULT_CONFIGURATION_FORM,
      ...initialData,
    };
  }, [initialData]);

  const FORM_FIELDS: FormFields = useMemo(
    () => [
      {
        key: 'config',
        label: 'Config',
        type: 'custom',
        validate: 'onChange',
        CustomComponent: JsonEditor,
        fieldProps: {
          allowedModes: ['tree', 'text'],
        },
        valueFormat: (v) => {
          try {
            return JSON.parse(v);
          } catch (error) {
            return v;
          }
        },
        onUpdateFormat: (v) => JSON.stringify(v),
      },
    ],
    []
  );

  return (
    <Form
      {...props}
      fields={FORM_FIELDS}
      enableReinitialize
      initialData={formData}
      schema={configuration}
    />
  );
};
