import React, { useCallback, useMemo } from 'react';
import { Button } from '@rollioforce/rollio-ui';
import { sfLogin } from '@rollioforce/rollio-schemas';

import { SfLoginForm } from 'src/components/Forms';
import { List } from 'src/components/List';

import { Modal, ModalProps } from './Modal';

interface Props extends Omit<ModalProps, 'children' | 'small'> {
  linkedUsers: any[];
  onSelectAccount: (username: string, isNew: boolean) => void;
  onUnlinkAccount: (id: number) => void;
}

export const SfLoginModal: React.FC<Props> = ({
  linkedUsers,
  onSelectAccount,
  onUnlinkAccount,
  ...props
}) => {
  const isFormVisible = useMemo(() => linkedUsers.length === 0, [linkedUsers]);

  const onRowClick = useCallback(
    (id) => {
      const email = linkedUsers.find((linkedUser) => linkedUser.id === id)
        ?.email;
      onSelectAccount(email, false);
    },
    [linkedUsers, onSelectAccount]
  );

  const onFormSubmit = useCallback(
    async ({ email }) => {
      await onSelectAccount(email, true);
    },
    [onSelectAccount]
  );

  const onUnlinkButtonClick = useCallback(() => {
    onUnlinkAccount(linkedUsers[0].id);
  }, [linkedUsers, onUnlinkAccount]);

  return (
    <Modal
      {...props}
      title="Login with Salesforce"
      style={{
        content: {
          width: 'auto',
          height: 'auto',
        },
      }}
    >
      {linkedUsers.length > 0 && !isFormVisible ? (
        <>
          <List
            items={[
              ...linkedUsers.map(
                ({ id, first_name, last_name, email, profile_image_url }) => ({
                  id,
                  title: `${first_name} ${last_name}`,
                  description: email,
                  avatar: profile_image_url,
                })
              ),
            ]}
            onRowClick={onRowClick}
          />
          <Button dark onClick={onUnlinkButtonClick} small>
            Use another account
          </Button>
        </>
      ) : (
        <SfLoginForm
          onSubmit={onFormSubmit}
          fields={[
            {
              key: 'email',
              label: 'Email',
              type: 'input',
              validate: 'onChange',
            },
          ]}
          schema={sfLogin}
          saveButtonLabel="Login"
        />
      )}
    </Modal>
  );
};
