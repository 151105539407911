import { useMemo } from 'react';

import { FIELDS_URL } from 'src/constants';

import { useData } from './utils';

export const useObjectsFieldData = (objectId: number) => {
  const key = useMemo(
    () => (objectId ? `${FIELDS_URL}/object-fields/${objectId}` : null),
    [objectId]
  );
  const { data: { data: fields = null } = {}, ...data } = useData<
    { data: any[] },
    any
  >(key);

  return {
    ...data,
    fields,
  };
};
