import React from 'react';
import styled from 'styled-components';
import { Button, ButtonProps } from '@rollioforce/rollio-ui';

interface Props extends Omit<ButtonProps, 'background' | 'icon'> {
  label?: string;
}

const StyledButton = styled(Button)`
  display: block;
  margin: 20px 0 20px auto;
`;

export const DeleteButton: React.FC<Props> = ({ label, ...props }) => (
  <StyledButton background="red" icon="delete" {...props}>
    {label || 'Delete'}
  </StyledButton>
);
