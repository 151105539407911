const cmsApiUrl = `${process.env.REACT_APP_CMS_API_URL}/api`;
const loginApiUrl = process.env.REACT_APP_AUTH_API_URL;
const sttApiUrl = process.env.REACT_APP_STT_API_URL;

export const SENTRY_DNS = process.env.REACT_APP_SENTRY_DNS;
export const SENTRY_ENV = process.env.REACT_APP_SENTRY_ENV || 'development';
export const RELEASE_VERSION = process.env.DEPLOY_ID;

export const LOGIN_URL = `${cmsApiUrl}/authentication/firebase-token`;
export const SALESFORCE_LOGIN_URL = `${loginApiUrl}/redirect-url`;
export const SALESFORCE_LOGOUT_URL = `${loginApiUrl}/logout`;
export const DEPLOYMENTS_URL = `${cmsApiUrl}/deployments`;
export const ORGANIZATIONS_URL = `${cmsApiUrl}/organizations`;
export const USERS_URL = `${cmsApiUrl}/users`;
export const CONFIGURATIONS_URL = `${cmsApiUrl}/configurations`;
export const FLOWS_URL = `${cmsApiUrl}/conversations`;
export const INSTANCES_URL = `${cmsApiUrl}/instances`;
export const INTENTS_URL = `${cmsApiUrl}/intents`;
export const FIELDS_URL = `${cmsApiUrl}/fields`;
export const STT_REPORTS_URL = `${cmsApiUrl}/stt-reports`;
export const STT_AUDIO_URL = `${sttApiUrl}/audio`;

export const SELECT_VALUES_A_B = ['A', 'B'].map((el) => ({
  label: el,
  value: el,
}));

export const DATE_FORMATS = {
  DEFAULT: 'MM/dd/yyyy HH:mm',
  FORM: 'yyyy-MM-dd',
};

export const FIREBASE_CONFIG = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_API_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_API_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

export enum ACTION {
  CREATE = 'create',
  READ = 'read',
  UPDATE = 'update',
  DELETE = 'delete',
  ACTIVATE = 'activate',
}

export enum RESOURCE {
  ORGANIZATION = 'Organization',
  DEPLOYMENT = 'Deployment',
  ROLLIO_USER = 'RollioUser',
  SF_USER = 'SfUser',
  ROLE = 'Role',
}

export enum ROLE {
  INTERNAL = 'Internal',
  ORGANIZATION = 'Organization',
  DEPLOYMENT = 'Deployment',
}

export enum CONFIGURATION_TYPE {
  CORE = 'core',
  NLP = 'nlp',
  STT = 'stt',
  CHROME = 'chrome',
}

export enum FLOW_STEP {
  INITIAL_STEP = 0,
  INTENT = 1,
  CONVERSATION_TEMPLATE = 2,
  CONFIRMATION = 3,
}

export enum SLOT_OPTION {
  PROMPT = 'Prompt',
  DECISION_POINT = 'Decision Point',
}
