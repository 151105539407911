import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';
import { Button, Stepper } from '@rollioforce/rollio-ui';
import {
  FormData,
  FormRef,
} from '@rollioforce/rollio-admin-ui/dist/types/components/Form';

import {
  useInstanceDeploymentsData,
  useInstanceObjectData,
  useIntentsData,
  useObjectsFieldData,
  useStore,
} from 'src/hooks';
import { FLOW_STEP } from 'src/constants';
import { FlowForm, FlowFormProps } from 'src/components/Forms';

import { Modal, ModalProps } from './Modal';
import { StepInfo } from '../StepInfo';

interface Props extends Omit<ModalProps, 'children' | 'small'> {
  form: Omit<FlowFormProps, 'step' | 'showSave' | 'deployments'>;
}

const StyledStepper = styled(Stepper)`
  padding: 0 200px;
  margin-bottom: 30px;
`;

const FormContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 60px;
`;

const ModalFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  box-shadow: 0 -8px 16px -8px rgba(137, 137, 137, 0.2);
  padding: 20px;
  padding-bottom: 0;
  margin-left: -20px;
  margin-right: -20px;
`;

const NextButton = styled(Button)`
  margin-left: 10px;
`;

const stepsData = [
  {
    title: 'Let’s go!',
    description:
      'To begin creating a new flow, we’ll need a name and the target deployment.',
    fields: ['name', 'deployment_id'],
  },
  {
    title: 'What’s the intent?',
    description:
      'To assist you in setting up your conversation slots, we’ll need to know some details regarding the flow’s intent.',
    fields: ['intent_id', 'object_id'],
  },
  {
    title: 'Conversation Structure',
    description:
      'Please provide a basic structure for your conversation. You can specify Data sources and create custom Flow contexts via the @ key.',
    fields: ['template'],
  },
  {
    title: 'Flow Confirmation',
    description: 'Please verify the selected configuration for your new flow.',
    fields: [],
  },
];

export const FlowModal: React.FC<Props> = ({ form, ...props }) => {
  const formRef = useRef<FormRef>(null);
  const [activeStep, setActiveStep] = useState<FLOW_STEP>(
    FLOW_STEP.INITIAL_STEP
  );
  const { activeInstance } = useStore();
  const [formData, setFormData] = useState<FormData>();
  const [initialData, setInitialData] = useState<FormData>(form.initialData);

  const { deployments } = useInstanceDeploymentsData(activeInstance?.value);
  const { intents } = useIntentsData();
  const { objects } = useInstanceObjectData(activeInstance?.value);
  const { fields, isLoading: isLoadingFields } = useObjectsFieldData(
    formData?.object_id
  );

  const currentStep = useMemo(() => stepsData[activeStep], [activeStep]);
  const steps = useMemo(() => Array(stepsData.length).fill({}), []);

  const setDefaultTemplate = useCallback(async () => {
    const intent = intents.find((x) => x.id === Number(formData?.intent_id));
    const object = objects.find((x) => x.id === Number(formData?.object_id));

    let template = `${intent?.type} ${object?.label}`;

    const requiredFields = fields?.filter((x) => x.required) || [];
    const templateRequiredFileds = requiredFields
      .map(
        (x, i) =>
          `${x.label}${i !== 0 ? ' is' : ''} @[${x.label}](${x.api_name})`
      )
      .join(', ');

    if (templateRequiredFileds.length) {
      template += ` with ${templateRequiredFileds}`;
    }

    setInitialData({ ...formData, template });
  }, [formData, intents, objects, fields]);

  const onNextClick = useCallback(async () => {
    if (activeStep < FLOW_STEP.CONFIRMATION) {
      const isValid = await formRef.current.validate(currentStep.fields);
      if (isValid) {
        if (activeStep === FLOW_STEP.INTENT) {
          setDefaultTemplate();
        }
        setActiveStep((prevStep) => prevStep + 1);
      }
    } else {
      formRef.current.submit();
    }
  }, [activeStep, currentStep, setDefaultTemplate]);

  const onBackClick = useCallback(() => {
    if (activeStep > FLOW_STEP.INITIAL_STEP) {
      setActiveStep((prevStep) => prevStep - 1);
    }
  }, [activeStep]);

  const onFormChange = useCallback((values: any) => {
    setFormData(values);
  }, []);

  useEffect(() => {
    setActiveStep(FLOW_STEP.INITIAL_STEP);
  }, [props.isOpen]);

  return (
    <Modal {...props}>
      <StyledStepper steps={steps} activeStep={activeStep} />

      <StepInfo title={currentStep.title} message={currentStep.description} />

      <FormContainer>
        <FlowForm
          {...form}
          ref={formRef}
          showSave={false}
          step={activeStep}
          deployments={deployments}
          intents={intents}
          objects={objects}
          fields={fields}
          onChange={onFormChange}
          initialData={initialData}
        />
      </FormContainer>

      <ModalFooter>
        {activeStep > FLOW_STEP.INITIAL_STEP && (
          <Button dark background="white" onClick={onBackClick}>
            Back
          </Button>
        )}
        <NextButton
          onClick={onNextClick}
          loading={isLoadingFields}
          disabled={isLoadingFields}
        >
          {activeStep === FLOW_STEP.CONFIRMATION ? 'Save' : 'Next'}
        </NextButton>
      </ModalFooter>
    </Modal>
  );
};
