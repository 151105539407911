import React from 'react';
import styled from 'styled-components';
import { colors } from '@rollioforce/rollio-ui';

interface LabelFieldProps extends React.HTMLAttributes<HTMLDivElement> {
  label: string;
}

const Field = styled.div`
  color: ${colors.greyDark};
  height: 56px;
  display: flex;
  align-items: center;
  font-weight: 600;
`;

export const LabelField: React.FC<LabelFieldProps> = ({ label, ...props }) => (
  <Field {...props}>{label}</Field>
);
