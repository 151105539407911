import React, { useCallback, useMemo } from 'react';
import { Router } from '@reach/router';
import { createGlobalStyle } from 'styled-components';
import * as Sentry from '@sentry/react';
import { colors, fonts } from '@rollioforce/rollio-ui';
import { MainWrap } from '@rollioforce/rollio-admin-ui';

import { StoreProvider } from 'src/store';
import { setIsSidebarExpanded } from 'src/store/actions';
import { useStore, useDispatch, useAuth, useUserProfile } from 'src/hooks';
import { routes } from 'src/routes';
import { AppHeaderNav } from 'src/components/AppHeaderNav';
import { AppSidebar } from 'src/components/AppSidebar';
import { ConfirmDeleteModal } from 'src/components/Modals';
import { ToastContainer } from 'src/components/ToastContainer';

import FirebaseProvider from './FirebaseProvider';

const GlobalStyles = createGlobalStyle`
  body {
    background: ${colors.offWhite};
    font-family: ${fonts.default};
  }
`;

const AppInner: React.FC = () => {
  const { isSidebarExpanded } = useStore();
  const dispatch = useDispatch();
  const { logout, isLoggedIn } = useAuth();
  const userProfile = useUserProfile();

  const wrapStyles = useMemo(() => (!isLoggedIn ? { padding: 0 } : {}), [
    isLoggedIn,
  ]);

  const toggleSidebar = useCallback(
    () => dispatch(setIsSidebarExpanded(!isSidebarExpanded)),
    [dispatch, isSidebarExpanded]
  );

  const usernameLabel = useMemo(
    () => `${userProfile?.first_name} ${userProfile?.last_name}`,
    [userProfile]
  );

  return (
    <MainWrap
      padLeft={isLoggedIn && isSidebarExpanded}
      padTop={isLoggedIn}
      style={wrapStyles}
    >
      {isLoggedIn && (
        <AppSidebar
          usernameLabel={usernameLabel}
          isVisible={isSidebarExpanded}
          onLogoutClick={logout}
        />
      )}
      {isLoggedIn && (
        <AppHeaderNav
          onMenuButtonClick={toggleSidebar}
          padLeft={isSidebarExpanded}
        />
      )}
      {isLoggedIn && <ConfirmDeleteModal />}
      <Router>
        {routes.map(({ id, path, Component }) => (
          <Component key={id} path={path} />
        ))}
      </Router>
    </MainWrap>
  );
};

export const App: React.FC = () => (
  <Sentry.ErrorBoundary fallback="An error has occured">
    <GlobalStyles />

    <StoreProvider>
      <FirebaseProvider>
        <AppInner />
      </FirebaseProvider>
    </StoreProvider>

    <ToastContainer />
  </Sentry.ErrorBoundary>
);
