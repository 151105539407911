import React from 'react';
import styled from 'styled-components';
import { colors, fonts, Button } from '@rollioforce/rollio-ui';

import { ReactComponent as RollioLogo } from '../../assets/rollio-logo-svg.svg';

interface Props {
  isLoading?: boolean;
  message?: string;
  placeholder?: string;
}

interface StyledChatBubbleWithIconProps {
  message: string;
}

const StyledBox = styled.div`
  width: 250px;
  min-height: 40px;
  height: auto;
  padding: 10px 20px;
  border-radius: 4px;
  font-style: italic;
  display: flex;
  align-items: center;
  color: ${colors.greyLight};
  font-family: ${fonts.default};
  font-size: 12px;
  box-shadow: 0 2px 5px 0 rgba(197, 197, 197, 0.5);
  background-color: ${colors.white};
`;

const StyledChatBubble = styled.div`
  min-height: 52px;
  max-width: 400px;
  width: auto;
  height: auto;
  display: inline-flex;
  color: ${colors.greyDark};
  border-radius: 5px 30px 30px 30px;
  padding: 15px 39px 15px 30px;
  background-color: ${colors.offWhite};
  font-size: 18px;
`;

const StyledLoadingIndicator = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 4px;
  font-style: italic;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 5px 0 rgba(197, 197, 197, 0.5);
  background-color: ${colors.offWhite2};
  margin-left: 105px;
  margin-right: 105px;
`;

const StyledChatBubbleWithIcon: React.FC<StyledChatBubbleWithIconProps> = ({
  message,
}) => {
  return (
    <>
      <IconWrapper>
        <RollioLogo />
      </IconWrapper>
      <StyledChatBubble>{message}</StyledChatBubble>
    </>
  );
};

const IconWrapper = styled.div`
  width: 15px;
  height: 15px;
  max-width: 15px;
  max-height: 15px;
  border-radius: 50%;
  background-color: ${colors.primary};
  position: relative;
  align-items: center;
  justify-content: center;
  display: flex;
  top: 7px;
  left: 5px;

  svg {
    width: 9px;
    height: 16px;
    path {
      fill: ${colors.secondary};
    }
  }
`;

const LoadingIndicator: React.FC = () => (
  <StyledLoadingIndicator>
    <Button loading textColor="primary" as="div" background="offWhite2" />
  </StyledLoadingIndicator>
);

export const FieldPromptPreview: React.FC<Props> = ({
  message,
  isLoading,
  placeholder,
}) => (
  <>
    {placeholder && !message && !isLoading && (
      <StyledBox>{placeholder}</StyledBox>
    )}
    {isLoading && <LoadingIndicator />}
    {!isLoading && message && <StyledChatBubbleWithIcon message={message} />}
  </>
);
