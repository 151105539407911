import React from 'react';
import styled from 'styled-components';
import { colors, Icon, ToolTip } from '@rollioforce/rollio-ui';

import { Container } from 'src/components/Container';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  title: string;
  info?: string;
}

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const HeaderTitle = styled.h4`
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  color: ${colors.greyDark};
`;

export const ContainerInfo: React.FC<Props> = ({
  title,
  info,
  children,
  ...props
}) => {
  return (
    <Container {...props}>
      <Header>
        <HeaderTitle>{title}</HeaderTitle>
        {info && (
          <ToolTip message={info}>
            <Icon name="info" size="small" color="greyLight" />
          </ToolTip>
        )}
      </Header>
      {children}
    </Container>
  );
};
