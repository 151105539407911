import React, { useCallback, useMemo } from 'react';
import { Table, TableLabel, TableProps } from '@rollioforce/rollio-ui';
import { Columns } from '@rollioforce/rollio-ui/dist/types/components/Table';
import { Link } from '@reach/router';

import { ACTION, RESOURCE } from 'src/constants';
import { usePermission } from 'src/hooks';
import { getRouteUrlById, ROUTES } from 'src/routes';

import { TableBitCell, TableDateCell } from './TableCells';

interface Props extends Omit<TableProps, 'columns'> {
  organizationId: number;
  hiddenColumns?: string[];
}

export const DeploymentsTable: React.FC<Props> = ({
  organizationId,
  hiddenColumns,
  data,
  ...props
}) => {
  const { checkPermission, isInternal } = usePermission();

  const canAccessToDeployment = useMemo(
    () => checkPermission(RESOURCE.DEPLOYMENT, ACTION.READ),
    [checkPermission]
  );

  const getDeploymentUrl = useCallback(
    (deploymentId) =>
      getRouteUrlById(
        isInternal ? ROUTES.deploymentDetail : ROUTES.deployment,
        {
          organizationId,
          deploymentId,
        }
      ),
    [isInternal, organizationId]
  );

  const getInstanceUrl = useCallback(
    (instanceId) =>
      getRouteUrlById(isInternal ? ROUTES.instanceDetail : ROUTES.instance, {
        organizationId,
        instanceId,
      }),
    [isInternal, organizationId]
  );

  const columns: Columns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
        width: 80,
        show: isInternal,
      },
      {
        Header: 'Name',
        accessor: 'name',
        Cell: ({
          value,
          original,
        }: {
          index: number;
          value: string;
          original: DeploymentType;
        }) =>
          canAccessToDeployment ? (
            <Link
              to={getDeploymentUrl(original.id)}
              style={{ textDecoration: 'none' }}
            >
              <TableLabel isLink variant="header">
                {value}
              </TableLabel>
            </Link>
          ) : (
            <TableLabel variant="header">{value}</TableLabel>
          ),
      },
      {
        Header: 'Instance',
        accessor: 'instance.name',
        Cell: ({
          value,
          original,
        }: {
          index: number;
          value: string;
          original: DeploymentType;
        }) => (
          <Link
            to={getInstanceUrl(original.instance_id)}
            style={{ textDecoration: 'none' }}
          >
            <TableLabel isLink variant="header">
              {value}
            </TableLabel>
          </Link>
        ),
      },
      {
        Header: 'Available licenses',
        accessor: 'available_licenses',
        cellVariant: 'code',
      },
      {
        Header: 'Created at',
        accessor: 'created_at',
        Cell: TableDateCell,
      },
      {
        Header: 'Last updated',
        accessor: 'updated_at',
        Cell: TableDateCell,
      },
      {
        Header: 'Active',
        accessor: 'is_active',
        Cell: TableBitCell,
        show: checkPermission(RESOURCE.DEPLOYMENT, ACTION.ACTIVATE),
      },
    ],
    [
      canAccessToDeployment,
      checkPermission,
      getDeploymentUrl,
      getInstanceUrl,
      isInternal,
    ]
  );

  const tableColumns = useMemo(
    () =>
      hiddenColumns
        ? columns.filter(
            (column) => !hiddenColumns.includes(String(column.accessor))
          )
        : columns,
    [columns, hiddenColumns]
  );

  return (
    <Table
      {...props}
      columns={tableColumns}
      data={data}
      showPagination={data.length >= 20}
    />
  );
};
