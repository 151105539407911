import { useCallback, useState, useMemo, useEffect, useRef } from 'react';

import { useDispatch } from 'src/hooks/useDispatch';
import { setSfToken as setAuthToken } from 'src/store/actions';
import { auth, utils } from 'src/services';

import { useStore } from './useStore';

export const useSfAuth = () => {
  const dispatch = useDispatch();
  const { sfToken } = useStore();
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [loginError, setLoginError] = useState('');
  const callback = useRef(null);

  const isLoggedIn = useMemo(() => !!sfToken, [sfToken]);

  const updateToken = useCallback(
    (newAuthToken) => {
      dispatch(setAuthToken(newAuthToken));
    },
    [dispatch]
  );

  const setCallback = (cb) => {
    callback.current = cb;
  };

  const runCallback = useCallback((success: boolean, data?: string) => {
    if (callback.current) {
      callback.current(success, data);
      callback.current = null;
    }
  }, []);

  const login = useCallback(
    async (username: string, deploymentId?: number, cb = null) => {
      setLoginError('');
      setIsLoggingIn(true);
      setCallback(cb);
      const popupUrlResponse = await auth.getRedirectUrl(
        username,
        true,
        deploymentId
      );
      if (popupUrlResponse.success) {
        const popupWindow = utils.openPopup(popupUrlResponse.url);
        const interval = setInterval(() => {
          if (popupWindow.closed) {
            setIsLoggingIn(false);
            clearInterval(interval);
            runCallback(false);
          }
        }, 500);
        return popupWindow;
      } else {
        setIsLoggingIn(false);
        runCallback(false, popupUrlResponse.message);
        setLoginError(popupUrlResponse.message);
      }
    },
    [runCallback]
  );

  const decodedToken = useMemo(() => utils.decodeAuthToken(sfToken), [sfToken]);

  const sfLogout = useCallback(() => dispatch(setAuthToken(null)), [dispatch]);

  useEffect(() => {
    const listener = (event) => {
      if (
        event?.data?.type === 'ROLLIO/AUTH_TOKEN' &&
        utils.verifyAuthToken(event.data.payload)
      ) {
        updateToken(event.data.payload);
        runCallback(true, event.data.payload);
      }
    };
    window.addEventListener('message', listener, false);
    return () => {
      window.removeEventListener('message', listener, false);
    };
  }, [updateToken, runCallback]);

  return {
    isLoggedIn,
    isLoggingIn,
    updateToken,
    sfToken,
    loginError,
    login,
    decodedToken,
    sfLogout,
  };
};
