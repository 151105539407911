import React from 'react';
import { LoadingIndicator } from '@rollioforce/rollio-admin-ui';

import { useUserDeploymentsData } from 'src/hooks';

import { Modal, ModalProps } from './Modal';
import { DeploymentsTable } from '../Tables';

interface Props extends Omit<ModalProps, 'children' | 'small'> {
  organizationId: number;
  userId: string;
}

export const UserDeploymentsModal: React.FC<Props> = ({
  organizationId,
  userId,
  ...props
}) => {
  const { isLoading, deployments } = useUserDeploymentsData(userId);

  return (
    <Modal {...props}>
      <DeploymentsTable data={deployments} organizationId={organizationId} />
      <LoadingIndicator isLoading={isLoading} />
    </Modal>
  );
};
