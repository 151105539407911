import { useCallback, useMemo } from 'react';

import { DEPLOYMENTS_URL, ORGANIZATIONS_URL } from 'src/constants';

import { useData, useFetcher } from './utils';

export const useOrganizationDeploymentsData = (
  organizationId: string,
  search?: SearchParams
) => {
  const { send, isLoading } = useFetcher();

  const key = useMemo(
    () => `${ORGANIZATIONS_URL}/${organizationId}/deployments`,
    [organizationId]
  );

  const { data: { data: deployments = [] } = {}, ...data } = useData<
    { data: DeploymentType[] },
    DeploymentType
  >(key, search);

  const generateReport = useCallback(
    async (
      ids: number[],
      date_from: string,
      date_to: string,
      include_successful_failed: boolean
    ) => {
      return send(
        `${DEPLOYMENTS_URL}/report`,
        'POST',
        {
          deployment_ids: ids,
          date_from,
          date_to,
          include_successful_failed,
        },
        { downloadFile: true }
      );
    },
    [send]
  );

  return {
    ...data,
    deployments,
    generateReport,
    isLoadingFetcher: isLoading,
  };
};
