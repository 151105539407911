import React from 'react';

import { OrganizationForm, OrganizationFormProps } from 'src/components/Forms';

import { Modal, ModalProps } from './Modal';

interface Props extends Omit<ModalProps, 'children' | 'small'> {
  form: OrganizationFormProps;
}

export const OrganizationModal: React.FC<Props> = ({ form, ...props }) => (
  <Modal {...props}>
    <OrganizationForm {...form} />
  </Modal>
);
