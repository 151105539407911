import React, { useEffect, useState } from 'react';
import { formatDistance, differenceInSeconds } from 'date-fns/esm';

import { useStore } from 'src/hooks';

export const StatusBar: React.FC = () => {
  const { lastSavedChanges } = useStore();

  const [now, setNow] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(() => setNow(new Date()), 1000);
    return () => clearInterval(interval);
  }, []);

  const getTime = () => {
    const lastDate = new Date(lastSavedChanges);
    const nowDate = new Date(now);
    const diff = differenceInSeconds(nowDate, lastDate);
    if (diff <= 20) {
      return 'was seconds ago';
    }
    return formatDistance(lastDate, nowDate, {
      addSuffix: true,
    });
  };

  if (lastSavedChanges === null) {
    return <span>Saving...</span>;
  }

  return lastSavedChanges ? <span>Last saved {getTime()}</span> : null;
};
