import React from 'react';
import styled, { css } from 'styled-components';
import { Badge, colors, Icon } from '@rollioforce/rollio-ui';
import { SLOT_OPTION } from 'src/constants';

export interface SlotProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, 'slot'> {
  slot: SlotType;
  showBadge?: boolean;
  showIcon?: boolean;
  isOpen?: boolean;
  isActive?: boolean;
  isRequired?: boolean;
  fieldType?: string;
  onDeleteClick?: () => void;
}

const StyledSlot = styled.div`
  display: flex;
  flex-direction: row;
  background: ${colors.offWhite2};
  padding: 10px;
  border-radius: 4px;
  min-width: 200px;
  align-items: center;
`;

const StyledBadge = styled(Badge)`
  margin-right: 10px;
`;

const SlotLabel = styled.span((props) => {
  const color = colors[props.$active ? 'primary' : 'greyDark'];

  return css`
    flex: 1;
    color: ${color};
    font-size: 14px;
    font-weight: 500;
  `;
});

const SlotToggleIcon = styled(Icon)((props) => {
  const rotation = props.$isOpen ? '180' : '0';

  return css`
    margin-left: 10px;
    transform: rotate(${rotation}deg);
    transition: transform 0.2s ease;
  `;
});

const RequiredLabel = styled.span`
  color: ${colors.greyLight};
  font-size: 10px;
  line-height: 16px;
`;

const StyledIcon = styled(Icon)`
  cursor: pointer;
`;

export const Slot: React.FC<SlotProps> = ({
  slot,
  showBadge,
  showIcon,
  isOpen,
  isActive,
  isRequired,
  fieldType,
  onDeleteClick,
  ...props
}) => {
  const { node_path, name, type } = slot;

  return (
    <StyledSlot {...props}>
      {node_path && (
        <StyledBadge small background={isActive ? 'primary' : 'greyLight'}>
          {node_path}
        </StyledBadge>
      )}
      <SlotLabel $active={isActive}>
        {name ||
          (type === SLOT_OPTION.PROMPT ? 'New Prompt' : 'New Decision Point')}
      </SlotLabel>
      {fieldType && (
        <StyledBadge small background="greyLight">
          {fieldType}
        </StyledBadge>
      )}
      {isRequired && <RequiredLabel>Required</RequiredLabel>}
      {!isRequired && onDeleteClick && (
        <StyledIcon
          name="delete"
          size="small"
          color="greyLight"
          onClick={(e: MouseEvent) => {
            onDeleteClick();
            e.stopPropagation();
          }}
        />
      )}
      {showIcon && (
        <SlotToggleIcon
          name="chevronUp"
          size="small"
          color="greyLight"
          $isOpen={isOpen}
        />
      )}
    </StyledSlot>
  );
};
