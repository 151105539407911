import React from 'react';

import {
  GenerateReportForm,
  GenerateReportFormProps,
} from 'src/components/Forms';

import { Modal, ModalProps } from './Modal';

interface Props extends Omit<ModalProps, 'children' | 'small'> {
  form: GenerateReportFormProps;
}

export const GenerateReportModal: React.FC<Props> = ({ form, ...props }) => (
  <Modal {...props}>
    <GenerateReportForm {...form} />
  </Modal>
);
