import React, { useState, useEffect, useCallback } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { Button } from '@rollioforce/rollio-ui';
import { LoadingIndicator } from '@rollioforce/rollio-admin-ui';

import { Container } from 'src/components/Container';
import { SearchForm } from 'src/components/Forms';
import { OrganizationModal, GenerateReportModal } from 'src/components/Modals';
import { Permission } from 'src/components/Permission';
import { OrganizationsTable } from 'src/components/Tables';
import { ACTION, RESOURCE } from 'src/constants';
import { useOrganizationsData } from 'src/hooks';
import { formatOrganization } from 'src/parser';

const TopTable = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  margin-bottom: 10px;
`;

const AddNewButton = styled(Button)`
  margin-left: 10px;
`;

export const OrganizationsList: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isGenerateReportModalOpen, setIsGenerateReportModalOpen] = useState(
    false
  );
  const [formErrors, setFormErrors] = useState<Record<string, string>>({});
  const [searchParams, setSearchParams] = useState<SearchParams>();
  const [selectedTableRows, setSelectedTableRows] = useState([]);

  const {
    isLoading,
    isMutating,
    organizations,
    addRecord,
    generateReport,
    isLoadingFetcher: isGeneratingReport,
  } = useOrganizationsData(searchParams);

  useEffect(() => {
    if (!isModalOpen) {
      setFormErrors({});
    }
  }, [isModalOpen]);

  const onFormSubmit = useCallback(
    async (data: OrganizationType) => {
      try {
        const res = await addRecord(formatOrganization(data));

        if (res && res.status === 'error') {
          if (res.errors) {
            return setFormErrors(res.errors);
          }
          return toast(res.message, { type: 'error' });
        }

        setIsModalOpen(false);
      } catch (e) {
        toast(e.message, { type: 'error' });
      }
    },
    [addRecord]
  );

  const onTableRowToggle = useCallback((orgId, isSelected) => {
    setSelectedTableRows((prevSelected) =>
      isSelected
        ? [...prevSelected, orgId]
        : prevSelected.filter((id) => id !== orgId)
    );
  }, []);

  const onTableRowToggleAll = useCallback(
    (isSelected) => {
      setSelectedTableRows(
        isSelected ? organizations.map((organization) => organization.id) : []
      );
    },
    [organizations]
  );

  const onGenerateReportFormSubmit = useCallback(
    (values) => {
      const { date_from, date_to, include_successful_failed } = values;
      generateReport(
        selectedTableRows,
        date_from,
        date_to,
        include_successful_failed
      );
    },
    [generateReport, selectedTableRows]
  );

  return (
    <>
      <Container>
        <TopTable>
          <Permission resource={RESOURCE.ORGANIZATION} action={ACTION.READ}>
            <SearchForm
              initialData={searchParams}
              inputLabel="Search organizations by name"
              onSubmit={setSearchParams}
            />
          </Permission>
          <Permission resource={RESOURCE.ORGANIZATION} action={ACTION.READ}>
            <Button
              background="greyLight"
              disabled={selectedTableRows.length === 0}
              onClick={() => setIsGenerateReportModalOpen(true)}
            >
              Generate report
            </Button>
            <GenerateReportModal
              form={{
                onSubmit: onGenerateReportFormSubmit,
              }}
              isOpen={isGenerateReportModalOpen}
              onCloseClick={() => setIsGenerateReportModalOpen(false)}
              title="Generate Report"
            />
          </Permission>
          <Permission resource={RESOURCE.ORGANIZATION} action={ACTION.CREATE}>
            <AddNewButton icon="add" onClick={() => setIsModalOpen(true)}>
              Add new
            </AddNewButton>
            <OrganizationModal
              form={{
                errors: formErrors,
                isSubmitting: isMutating,
                onSubmit: onFormSubmit,
              }}
              isOpen={isModalOpen}
              onCloseClick={() => setIsModalOpen(false)}
              title="Add New Organization"
            />
          </Permission>
        </TopTable>
        <Permission resource={RESOURCE.ORGANIZATION} action={ACTION.READ}>
          <OrganizationsTable
            data={organizations}
            keyField="id"
            allowRowSelect
            selectedRows={selectedTableRows}
            onRowToggle={onTableRowToggle}
            onToggleAll={onTableRowToggleAll}
          />
        </Permission>
      </Container>

      <LoadingIndicator isLoading={isLoading || isGeneratingReport} />
    </>
  );
};
