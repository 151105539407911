import React from 'react';
import { RouteComponentProps } from '@reach/router';

import { OrganizationsList } from 'src/layouts/Organization';
import { PageWrap } from 'src/components/PageWrap';

export const Organizations: React.FC<RouteComponentProps> = () => (
  <PageWrap>
    <OrganizationsList />
  </PageWrap>
);
