import React from 'react';
import styled from 'styled-components';
import { Button, colors, Icon } from '@rollioforce/rollio-ui';

import { Container } from './Container';

export interface NothingToShowProps {
  message: string;
  buttonLabel?: string;
  onButtonClick?: () => void;
}

const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Title = styled.h3`
  color: ${colors.primary};
  margin: 20px 0 10px;
`;

const Message = styled.p`
  color: ${colors.greyDark};
  margin: 0 0 40px;
`;

export const NothingToShow: React.FC<NothingToShowProps> = ({
  message,
  buttonLabel = 'Get started',
  onButtonClick,
}) => (
  <StyledContainer>
    <Icon name="emptyBox" size="xxlarge" color="greyLight" />
    <Title>Nothing to show</Title>
    <Message>{message}</Message>
    <Button onClick={onButtonClick}>{buttonLabel}</Button>
  </StyledContainer>
);
