import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { LoadingIndicator } from '@rollioforce/rollio-admin-ui';
import {
  AccordionList,
  Button,
  Checkbox,
  colors,
  Icon,
} from '@rollioforce/rollio-ui';

import { useInstanceDataData, useSfAuth } from 'src/hooks';
import { formatDate } from 'src/services/utils';
import { SfLoginInstanceModal } from 'src/components/Modals';

const ListContainer = styled.div`
  margin: 0 0 20px;
  padding: 0;
  position: relative;
  list-style: none;
  > div {
    outline: none;
    &:hover,
    &:focus {
      text-decoration: none;
      background-color: rgba(0, 0, 0, 0.04);
    }
  }
`;

const ListItem = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  box-sizing: border-box;
  text-align: left;
  align-items: center;
  padding: 8px 16px;
  justify-content: flex-start;
  text-decoration: none;
`;

const ListItemText = styled.span`
  flex: 1;
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  color: ${colors.greyLight};
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const Title = styled.span`
  font-weight: 500;
  font-size: 18px;
  display: flex;
  align-items: center;
  margin-bottom: 2px;
`;

const Description = styled.span`
  font-size: 14px;
`;

const FieldsListContainer = styled.ul`
  list-style: none;
  padding: 8px 16px 8px 18px;
`;

const FieldsListItem = styled.li`
  color: ${colors.greyDark};
  font-size: 14px;
  font-weight: 500;
`;

const StyledButton = styled(Button)`
  margin-top: 10px;
`;

const FieldsList = ({ fields = [] }) => {
  const [expanded, setExpanded] = useState(false);
  const displayItems = 10;

  return (
    <FieldsListContainer>
      {fields
        .filter((_field, i) => expanded || i < displayItems)
        .map((field) => (
          <FieldsListItem key={field.api_name}>{field.label}</FieldsListItem>
        ))}

      {fields.length > displayItems && (
        <StyledButton
          background="offWhite2"
          dark
          small
          onClick={() => setExpanded(!expanded)}
        >
          {expanded ? 'Show less' : 'Show more'}
        </StyledButton>
      )}
    </FieldsListContainer>
  );
};

export const Data = ({ instanceId }) => {
  const [isSfLoginModalOpen, setIsSfLoginModalOpen] = useState(false);
  const [objects, setObjects] = useState<any[]>([]);
  const [selectedObjects, setSelectedObjects] = useState<string[]>([]);
  const [activeAccordions, setActiveAccordions] = useState<string[]>([]);

  const { isLoggedIn, sfToken } = useSfAuth();
  const {
    isLoading,
    isLoadingFetcher,
    getObjectList,
    syncMetadata,
  } = useInstanceDataData(instanceId);

  const onFetchObjectList = useCallback(async () => {
    const objectList = await getObjectList(sfToken);
    setObjects(objectList?.data || []);
  }, [getObjectList, sfToken]);

  useEffect(() => {
    if (isLoggedIn) {
      onFetchObjectList();
    }
  }, [isLoggedIn, onFetchObjectList]);

  const onRowClick = useCallback(
    (objName: string) => {
      const isSelected = selectedObjects.includes(objName);
      setSelectedObjects((prevSelectedObjects) =>
        isSelected
          ? prevSelectedObjects.filter((name) => name !== objName)
          : [...prevSelectedObjects, objName]
      );
    },
    [selectedObjects]
  );

  const onSyncSelectedObjects = useCallback(async () => {
    try {
      await syncMetadata(selectedObjects, sfToken);
      await onFetchObjectList();
      toast(`${selectedObjects.length} objects synced successfully`, {
        type: 'success',
      });
    } catch (error) {
      toast(error.message, { type: 'error' });
    }
  }, [onFetchObjectList, syncMetadata, selectedObjects, sfToken]);

  const onAccordionSelect = useCallback(
    (id: string, isOpen: boolean): void =>
      setActiveAccordions(
        !isOpen
          ? [...activeAccordions, id]
          : activeAccordions.filter((accordionId) => id !== accordionId)
      ),
    [activeAccordions]
  );

  return (
    <>
      {isLoggedIn && (
        <>
          {objects.length > 0 && (
            <ButtonContainer>
              <Button
                background="greyDark"
                onClick={onSyncSelectedObjects}
                disabled={!selectedObjects.length}
              >
                Sync selected data
              </Button>
            </ButtonContainer>
          )}

          <ListContainer>
            <AccordionList
              items={objects.map(
                ({
                  api_name: objName,
                  label,
                  synced_object_at,
                  fields = [],
                }) => {
                  const isSelected = selectedObjects.includes(objName);
                  const isDisabled = !synced_object_at;

                  return {
                    id: objName,
                    disabled: isDisabled,
                    label: (
                      <ListItem key={objName}>
                        <Icon name="folder" color="greyLight" />
                        <ListItemText>
                          <Title>
                            {label}
                            {!isDisabled && (
                              <Icon name="caretDown" color="greyLight" />
                            )}
                          </Title>
                          <Description>
                            {!isDisabled
                              ? `${
                                  fields.length
                                } Fields (Synced at ${formatDate(
                                  synced_object_at
                                )})`
                              : ' Not synced yet'}
                          </Description>
                        </ListItemText>

                        <Checkbox
                          onChange={() => onRowClick(objName)}
                          checked={isSelected}
                        >
                          Import
                        </Checkbox>
                      </ListItem>
                    ),
                    content: <FieldsList fields={fields} />,
                  };
                }
              )}
              activeAccordions={activeAccordions}
              onAccordionSelect={onAccordionSelect}
            />
          </ListContainer>
        </>
      )}
      {!isLoggedIn && (
        <Button
          background="greyDark"
          onClick={() => setIsSfLoginModalOpen(true)}
        >
          Salesforce Login
        </Button>
      )}

      <SfLoginInstanceModal
        instanceId={instanceId}
        isOpen={isSfLoginModalOpen}
        onCloseClick={() => setIsSfLoginModalOpen(false)}
        onLoginSuccess={() => setIsSfLoginModalOpen(false)}
        onLoginError={(message) => {
          setIsSfLoginModalOpen(false);
          toast(message, { type: 'error' });
        }}
      />

      <LoadingIndicator isLoading={isLoading || isLoadingFetcher} />
    </>
  );
};
