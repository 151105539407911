import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { Button } from '@rollioforce/rollio-ui';
import { LoadingIndicator } from '@rollioforce/rollio-admin-ui';

import { SearchForm } from 'src/components/Forms';
import { UserDeploymentsModal, UserModal } from 'src/components/Modals';
import { Permission } from 'src/components/Permission';
import { UsersTable } from 'src/components/Tables';
import { useDeleteModal, useUserData, useUsersData } from 'src/hooks';
import { ACTION, RESOURCE } from 'src/constants';
import { mapOrganizationUser } from 'src/parser';

const TopTable = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  margin-bottom: 10px;
`;

const AddNewButton = styled(Button)`
  margin-left: 10px;
`;

export const Users = ({ organizationId }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentId, setCurrentId] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [formErrors, setFormErrors] = useState<Record<string, string>>({});
  const [searchParams, setSearchParams] = useState<SearchParams>();
  const [isUserDeploymentsModalOpen, setIsUserDeploymentsModalOpen] = useState(
    false
  );
  const [selectedUser, setSelectedUser] = useState(null);

  const {
    isLoading,
    isMutating,
    users,
    refresh: refreshUsers,
    addRecord,
  } = useUsersData(organizationId, searchParams);
  const {
    isLoading: isUserLoading,
    isMutating: isUserMutating,
    user,
    editRecord,
    deleteRecord,
  } = useUserData(currentId);
  const { isLoading: isDeleteLoading, openDeleteModal } = useDeleteModal();

  useEffect(() => {
    if (user) {
      setIsModalOpen(true);
      setCurrentUser(mapOrganizationUser(user));
    }
  }, [user]);

  useEffect(() => {
    if (!isModalOpen) {
      setCurrentId(null);
      setCurrentUser(null);
      setFormErrors({});
    }
  }, [isModalOpen]);

  const onViewDeploymentsClick = useCallback(async (original) => {
    setIsUserDeploymentsModalOpen(true);
    setSelectedUser(original);
  }, []);

  const onFormSubmit = useCallback(
    async (data: OrganizationUserType) => {
      const action = currentId ? editRecord : addRecord;
      try {
        const res = await action(data);

        if (res && res.status === 'error') {
          if (res.errors) {
            return setFormErrors(res.errors);
          }
          return toast(res.message, { type: 'error' });
        }

        setIsModalOpen(false);
        refreshUsers();
      } catch (e) {
        toast(e.message, { type: 'error' });
      }
    },
    [currentId, editRecord, addRecord, refreshUsers]
  );

  const onDeleteClick = useCallback(async () => {
    openDeleteModal({
      data: { id: currentUser.id, name: currentUser.first_name },
      type: 'user',
      action: deleteRecord,
      callback: () => {
        setIsModalOpen(false);
        refreshUsers();
      },
    });
  }, [currentUser, deleteRecord, openDeleteModal, refreshUsers]);

  const isSubmitting = useMemo(
    () => (isMutating || isUserMutating) && !isDeleteLoading,
    [isMutating, isUserMutating, isDeleteLoading]
  );

  return (
    <>
      <TopTable>
        <Permission resource={RESOURCE.ROLLIO_USER} action={ACTION.READ}>
          <SearchForm
            initialData={searchParams}
            inputLabel="Search users by name or email"
            onSubmit={setSearchParams}
          />
        </Permission>
        <Permission resource={RESOURCE.ROLLIO_USER} action={ACTION.CREATE}>
          <AddNewButton
            background="greyDark"
            onClick={() => setIsModalOpen(true)}
          >
            Add new
          </AddNewButton>

          <UserModal
            isOpen={isModalOpen}
            form={{
              errors: formErrors,
              initialData: currentUser,
              isSubmitting,
              onSubmit: onFormSubmit,
              organizationId,
            }}
            onCloseClick={() => setIsModalOpen(false)}
            onDelete={onDeleteClick}
            title={
              !currentUser
                ? 'Add New Deployment Administrator'
                : 'Edit Deployment Administrator'
            }
          />
        </Permission>
      </TopTable>
      <Permission resource={RESOURCE.ROLLIO_USER} action={ACTION.READ}>
        <UsersTable
          data={users}
          onViewDeploymentsClick={onViewDeploymentsClick}
          onRowClick={setCurrentId}
        />
      </Permission>

      {selectedUser && (
        <UserDeploymentsModal
          isOpen={isUserDeploymentsModalOpen}
          onCloseClick={() => setIsUserDeploymentsModalOpen(false)}
          organizationId={organizationId}
          title={`Deployments of ${selectedUser.first_name} ${selectedUser.last_name}`}
          userId={selectedUser.id}
        />
      )}

      <LoadingIndicator isLoading={isLoading || isUserLoading} />
    </>
  );
};
