import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from '@reach/router';
import { Tabs } from '@rollioforce/rollio-ui';
import { LoadingIndicator } from '@rollioforce/rollio-admin-ui';

import { useDeleteModal, useOrganizationData, usePermission } from 'src/hooks';
import { DeleteButton } from 'src/components/Buttons';
import { Container } from 'src/components/Container';
import {
  OrganizationForm,
  DEFAULT_ORGANIZATION_FORM,
} from 'src/components/Forms';
import { Permission } from 'src/components/Permission';
import { ACTION, RESOURCE, ROLE } from 'src/constants';
import { getRouteUrlById, ROUTES } from 'src/routes';
import { formatOrganization, mapOrganization } from 'src/parser';

import { Instances } from './Instances';
import { Deployments } from './Deployments';
import { Users } from './Users';

interface Props {
  organizationId?: string;
}

export const OrganizationDetail: React.FC<Props> = ({ organizationId }) => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(0);
  const [formErrors, setFormErrors] = useState<Record<string, string>>({});

  const {
    organization,
    error,
    isLoading,
    isMutating,
    editRecord,
    deleteRecord,
  } = useOrganizationData(organizationId);
  const { openDeleteModal } = useDeleteModal();
  const { checkRole } = usePermission();

  const initialFormState = useMemo(
    () =>
      organization ? mapOrganization(organization) : DEFAULT_ORGANIZATION_FORM,
    [organization]
  );

  const isUsersVisible = useMemo(
    () => checkRole(ROLE.INTERNAL) || checkRole(ROLE.ORGANIZATION),
    [checkRole]
  );

  const TABS = useMemo(
    () => [
      {
        label: 'Instances',
        content: <Instances organizationId={organizationId} />,
      },
      {
        label: 'Deployments',
        content: <Deployments organizationId={organizationId} />,
      },
      ...(isUsersVisible
        ? [
            {
              label: 'Deployment Administrators',
              content: <Users organizationId={organizationId} />,
            },
          ]
        : []),
    ],
    [organizationId, isUsersVisible]
  );

  useEffect(() => {
    if (error) {
      toast(error.message, { type: 'error' });
      navigate(getRouteUrlById(ROUTES.home));
    }
  }, [error, navigate]);

  const onFormSubmit = useCallback(
    async (data: OrganizationType) => {
      try {
        const res = await editRecord(formatOrganization(data));

        if (res && res.status === 'error') {
          if (res.errors) {
            return setFormErrors(res.errors);
          }
          return toast(res.message, { type: 'error' });
        }

        toast('Organization updated successfully', { type: 'success' });
      } catch (error) {
        toast(error.message, { type: 'error' });
      }
    },
    [editRecord]
  );

  const onDeleteClick = useCallback(async () => {
    openDeleteModal({
      data: organization,
      type: 'organization',
      action: deleteRecord,
      callback: () => navigate(getRouteUrlById(ROUTES.organizations)),
    });
  }, [deleteRecord, navigate, openDeleteModal, organization]);

  return (
    <>
      <Permission resource={RESOURCE.ORGANIZATION} action={ACTION.READ}>
        <Container>
          <OrganizationForm
            errors={formErrors}
            initialData={initialFormState}
            isSubmitting={isMutating}
            onSubmit={onFormSubmit}
          />
        </Container>

        <Container>
          <Tabs
            tabs={TABS}
            onTabSelect={(index) => setActiveTab(index)}
            activeTab={activeTab}
          />
        </Container>
      </Permission>

      <Permission resource={RESOURCE.ORGANIZATION} action={ACTION.DELETE}>
        <DeleteButton label="Delete organization" onClick={onDeleteClick} />
      </Permission>
      <LoadingIndicator isLoading={isLoading} />
    </>
  );
};
