import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { useNavigate } from '@reach/router';
import { toast } from 'react-toastify';
import { Tabs } from '@rollioforce/rollio-ui';
import { LoadingIndicator } from '@rollioforce/rollio-admin-ui';

import { useDeleteModal, useDeploymentData, usePermission } from 'src/hooks';
import { DeleteButton } from 'src/components/Buttons';
import { DeploymentForm, DEFAULT_DEPLOYMENT_FORM } from 'src/components/Forms';
import { getRouteUrlById, ROUTES } from 'src/routes';
import { Container } from 'src/components/Container';
import { Permission } from 'src/components/Permission';
import { ACTION, RESOURCE } from 'src/constants';
import { formatDeployment, mapDeployment } from 'src/parser';

import { SfUsers } from './SfUsers';
import { STTReporting } from './STTReporting';

interface Props {
  deploymentId?: string;
}

export const DeploymentDetail: React.FC<Props> = ({ deploymentId }) => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(0);
  const [formErrors, setFormErrors] = useState<Record<string, string>>({});

  const {
    deployment,
    error,
    isLoading,
    isMutating,
    editRecord,
    deleteRecord,
  } = useDeploymentData(deploymentId);
  const { openDeleteModal } = useDeleteModal();
  const { checkPermission } = usePermission();

  const initialFormState = useMemo(
    () => (deployment ? mapDeployment(deployment) : DEFAULT_DEPLOYMENT_FORM),
    [deployment]
  );

  const isSTTReportingVisible = useMemo(() => !!deployment?.record_sync_stt, [
    deployment,
  ]);

  const TABS = useMemo(
    () => [
      {
        label: 'Salesforce Users',
        content: (
          <SfUsers
            deploymentId={deploymentId}
            synchedSfUsersAt={deployment?.synched_sfusers_at}
          />
        ),
      },
      ...(isSTTReportingVisible
        ? [
            {
              label: 'STT Reporting',
              content: <STTReporting deploymentId={deploymentId} />,
            },
          ]
        : []),
    ],
    [deploymentId, deployment, isSTTReportingVisible]
  );

  const canEditDeployment = useMemo(
    () => checkPermission(RESOURCE.DEPLOYMENT, ACTION.UPDATE),
    [checkPermission]
  );

  useEffect(() => {
    if (error) {
      toast(error.message, { type: 'error' });
      navigate(getRouteUrlById(ROUTES.home));
    }
  }, [error, navigate]);

  const onFormSubmit = useCallback(
    async (data: DeploymentType) => {
      try {
        const res = await editRecord(formatDeployment(data));

        if (res && res.status === 'error') {
          if (res.errors) {
            return setFormErrors(res.errors);
          }
          return toast(res.message, { type: 'error' });
        }

        toast('Deployment updated successfully', { type: 'success' });
      } catch (error) {
        toast(error.message, { type: 'error' });
      }
    },
    [editRecord]
  );

  const onDeleteClick = useCallback(async () => {
    openDeleteModal({
      data: deployment,
      type: 'deployment',
      action: deleteRecord,
      callback: () =>
        navigate(
          getRouteUrlById(ROUTES.organizationDetail, {
            organizationId: deployment.organization_id,
          })
        ),
    });
  }, [deleteRecord, navigate, openDeleteModal, deployment]);

  return (
    <>
      <Permission resource={RESOURCE.DEPLOYMENT} action={ACTION.READ}>
        <Container>
          <DeploymentForm
            errors={formErrors}
            initialData={initialFormState}
            isReadOnly={!canEditDeployment}
            isSubmitting={isMutating}
            onSubmit={onFormSubmit}
            organizationId={deployment?.organization_id}
          />
        </Container>

        <Container>
          <Tabs
            tabs={TABS}
            onTabSelect={(index) => setActiveTab(index)}
            activeTab={activeTab}
          />
        </Container>
      </Permission>

      <Permission resource={RESOURCE.DEPLOYMENT} action={ACTION.DELETE}>
        <DeleteButton label="Delete deployment" onClick={onDeleteClick} />
      </Permission>
      <LoadingIndicator isLoading={isLoading} />
    </>
  );
};
