import React from 'react';
import { RouteComponentProps } from '@reach/router';

import { FlowsList } from 'src/layouts/Flow';
import { PageWrap } from 'src/components/PageWrap';

export const Flows: React.FC<RouteComponentProps> = () => (
  <PageWrap>
    <FlowsList />
  </PageWrap>
);
