import React, { useCallback, useMemo } from 'react';
import { Table, TableLabel, TableProps } from '@rollioforce/rollio-ui';
import { Columns } from '@rollioforce/rollio-ui/dist/types/components/Table';
import { Link } from '@reach/router';

import { usePermission } from 'src/hooks';
import { getRouteUrlById, ROUTES } from 'src/routes';

import { TableBitCell, TableDateCell } from './TableCells';

interface Props extends Omit<TableProps, 'columns'> {
  organizationId: number;
}

export const InstancesTable: React.FC<Props> = ({
  organizationId,
  data,
  ...props
}) => {
  const { isInternal } = usePermission();

  const getInstanceUrl = useCallback(
    (instanceId) =>
      getRouteUrlById(isInternal ? ROUTES.instanceDetail : ROUTES.instance, {
        organizationId,
        instanceId,
      }),
    [isInternal, organizationId]
  );

  const columns: Columns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
        width: 80,
        show: isInternal,
      },
      {
        Header: 'Name',
        accessor: 'name',
        Cell: ({
          value,
          original,
        }: {
          index: number;
          value: string;
          original: OrganizationInstanceType;
        }) => (
          <Link
            to={getInstanceUrl(original.id)}
            style={{ textDecoration: 'none' }}
          >
            <TableLabel isLink variant="header">
              {value}
            </TableLabel>
          </Link>
        ),
      },
      {
        Header: 'Instance URL',
        accessor: 'instance_url',
      },
      {
        Header: 'Login URL',
        accessor: 'login_url',
      },
      {
        Header: 'Created at',
        accessor: 'created_at',
        Cell: TableDateCell,
      },
      {
        Header: 'Last updated',
        accessor: 'updated_at',
        Cell: TableDateCell,
      },
      {
        Header: 'Active',
        accessor: 'is_active',
        Cell: TableBitCell,
      },
    ],
    [isInternal, getInstanceUrl]
  );

  return (
    <Table
      {...props}
      columns={columns}
      data={data}
      showPagination={data.length >= 20}
    />
  );
};
