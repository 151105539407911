import { useMemo } from 'react';

import { INSTANCES_URL } from 'src/constants';

import { useData } from './utils';

export const useInstanceData = (instanceId: string) => {
  const key = useMemo(
    () => (instanceId ? `${INSTANCES_URL}/${instanceId}` : null),
    [instanceId]
  );
  const { data: { data: instance = null } = {}, ...data } = useData<
    { data: OrganizationInstanceType },
    OrganizationInstanceType
  >(key);

  return {
    ...data,
    instance,
  };
};
