import React, { useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { Accordion, colors, LabelBox } from '@rollioforce/rollio-ui';
import { FormData } from '@rollioforce/rollio-admin-ui/dist/types/components/Form';

import { SLOT_OPTION } from 'src/constants';
import { DecisionPointForm, PromptForm } from 'src/components/Forms';
import { Slot } from 'src/components/Slot';
import { BackgroundPatternContainer } from 'src/components/Container/BackgroundPatternContainer';
import { FieldPromptPreview } from 'src/components/Fields/FieldPromptPreview';

import { AddSlotButton } from './AddSlotButton';
import { AddBeforeButton } from './AddBeforeButton';

export interface EditableSlotProps {
  slot: SlotType;
  isActive?: boolean;
  onAddBeforeSlot: (
    option: SLOT_OPTION,
    slot: SlotType,
    condition: boolean
  ) => void;
  onAddChildSlot: (
    option: SLOT_OPTION,
    slot: SlotType,
    condition: boolean
  ) => void;
  onChange: (formData: FormData) => void;
  onDeleteSlot: (slot: SlotType) => void;
  slots?: SlotType[];
  objects?: any[];
  fields?: any[];
  index?: number;
}

const SlotContainer = styled.div`
  border: 2px solid ${colors.offWhite2};
  border-radius: 4px;
  margin-bottom: 10px;
`;

const FormContainer = styled.div`
  padding: 15px;
  background-color: ${colors.white};
`;

const StyledDecisionPointForm = styled(DecisionPointForm)`
  margin-bottom: 0px;
`;

const StyledBackgroundPatternContainer = styled(BackgroundPatternContainer)`
  height: 120px;
  border-bottom: 2px solid ${colors.offWhite2};
`;

const SlotConditionContainer = styled(LabelBox)`
  margin-bottom: 15px;
`;

const StyledAddSlotButton = styled(AddSlotButton)`
  margin-bottom: 10px;
`;

const DroppableContainer = styled.div(
  ({ isDraggingOver }) => css`
    padding: 10px;
    background: ${isDraggingOver ? colors.offWhite : colors.white};
  `
);

const DraggableContainer = styled.div`
  userselect: 'none';
`;

export const EditableSlot: React.FC<EditableSlotProps> = ({
  slot,
  isActive,
  onAddBeforeSlot,
  onAddChildSlot,
  onChange,
  onDeleteSlot,
  slots,
  objects,
  fields,
  index,
}) => {
  const [isOpen, setIsOpen] = useState(isActive);
  const [isAddBeforeVisible, setIsAddBeforeVisible] = useState(false);
  const [isAddingBefore, setisAddingBefore] = useState(false);

  const onFormChange = (formData: FormData) => {
    onChange({
      ...slot,
      detail: {
        ...slot.detail,
        configuration: formData,
      },
    });
  };

  const onAddBefore = (type) => {
    onAddBeforeSlot(type, slot, true);
    setisAddingBefore(false);
  };

  const field = useMemo(
    () =>
      fields?.find(
        (field) => field.api_name === slot.detail?.configuration.api
      ),
    [fields, slot]
  );

  const availableFields = useMemo(() => {
    const slotsFields = slots.reduce((acc, slot) => {
      if (slot.detail?.configuration.api) {
        acc.push(slot.detail.configuration.api);
      }
      return acc;
    }, []);

    return fields?.filter(
      (f) => !slotsFields.includes(f.api_name) || f.api_name === field?.api_name
    );
  }, [fields, slots, field]);

  return (
    <Draggable key={slot.node_path} draggableId={slot.node_path} index={index}>
      {(provided, snapshot) => (
        <DraggableContainer
          ref={provided.innerRef}
          {...provided.draggableProps}
          isDragging={snapshot.isDragging}
          draggableStyle={provided.draggableProps.style}
        >
          <>
            {isAddingBefore && (
              <StyledAddSlotButton
                onSubmit={onAddBefore}
                isCancellable
                onCancel={() => setisAddingBefore(false)}
              />
            )}

            <SlotContainer
              onMouseEnter={() => setIsAddBeforeVisible(true)}
              onMouseLeave={() => setIsAddBeforeVisible(false)}
            >
              {isAddBeforeVisible && !isAddingBefore && (
                <AddBeforeButton onClick={() => setisAddingBefore(true)} />
              )}

              <Accordion
                label={
                  <Slot
                    slot={slot}
                    showIcon
                    isOpen={isOpen}
                    isActive={isActive}
                    isRequired={field?.required}
                    fieldType={field?.type}
                    onDeleteClick={() => onDeleteSlot(slot)}
                    {...provided.dragHandleProps}
                  />
                }
                content={() => (
                  <>
                    {slot.type === SLOT_OPTION.PROMPT && (
                      <>
                        <StyledBackgroundPatternContainer>
                          <FieldPromptPreview
                            placeholder={`Configure "New Prompt" to prevew`}
                            message={slot.detail?.configuration.prompts?.[0]}
                          />
                        </StyledBackgroundPatternContainer>
                        <FormContainer>
                          <PromptForm
                            initialData={slot.detail?.configuration}
                            onSubmit={() => {}}
                            onChange={onFormChange}
                            showSave={false}
                            objects={objects}
                            availableFields={availableFields}
                            field={field}
                          />
                        </FormContainer>
                      </>
                    )}
                    {slot.type === SLOT_OPTION.DECISION_POINT && (
                      <FormContainer>
                        <StyledDecisionPointForm
                          initialData={slot.detail?.configuration}
                          onSubmit={() => {}}
                          onChange={onFormChange}
                          showSave={false}
                          slots={slots}
                        />

                        <SlotConditionContainer label="Condition true">
                          <Droppable
                            droppableId={`${slot.node_path}T`}
                            type={`${slot.node_path}-condition_true`}
                          >
                            {(provided, snapshot) => (
                              <DroppableContainer
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                isDraggingOver={snapshot.isDraggingOver}
                              >
                                {slot.condition_true &&
                                  slot.condition_true.map((child, i) => (
                                    <EditableSlot
                                      key={child.internal_id}
                                      slot={child}
                                      isActive={isActive}
                                      onAddBeforeSlot={onAddBeforeSlot}
                                      onAddChildSlot={onAddChildSlot}
                                      onChange={onChange}
                                      slots={slots}
                                      objects={objects}
                                      fields={fields}
                                      onDeleteSlot={onDeleteSlot}
                                      index={i}
                                    />
                                  ))}
                                {provided.placeholder}
                              </DroppableContainer>
                            )}
                          </Droppable>
                          <AddSlotButton
                            onSubmit={(type) =>
                              onAddChildSlot(type, slot, true)
                            }
                            isChild
                          />
                        </SlotConditionContainer>

                        <SlotConditionContainer label="Condition false">
                          <Droppable
                            droppableId={`${slot.node_path}F`}
                            type={`${slot.node_path}-condition_false`}
                          >
                            {(provided, snapshot) => (
                              <DroppableContainer
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                isDraggingOver={snapshot.isDraggingOver}
                              >
                                {slot.condition_false &&
                                  slot.condition_false.map((child, i) => (
                                    <EditableSlot
                                      key={child.internal_id}
                                      slot={child}
                                      isActive={isActive}
                                      onAddBeforeSlot={onAddBeforeSlot}
                                      onAddChildSlot={onAddChildSlot}
                                      onChange={onChange}
                                      slots={slots}
                                      objects={objects}
                                      fields={fields}
                                      onDeleteSlot={onDeleteSlot}
                                      index={i}
                                    />
                                  ))}
                                {provided.placeholder}
                              </DroppableContainer>
                            )}
                          </Droppable>
                          <AddSlotButton
                            onSubmit={(type) =>
                              onAddChildSlot(type, slot, false)
                            }
                            isChild
                          />
                        </SlotConditionContainer>
                      </FormContainer>
                    )}
                  </>
                )}
                isOpen={isOpen}
                onSelect={() => setIsOpen((prevIsOpen) => !prevIsOpen)}
              />
            </SlotContainer>
          </>
        </DraggableContainer>
      )}
    </Draggable>
  );
};
