import { useMemo } from 'react';

import { ORGANIZATIONS_URL } from 'src/constants';

import { useData } from './utils';

export const useOrganizationInstancesData = (
  organizationId: number,
  search?: SearchParams
) => {
  const key = useMemo(
    () =>
      organizationId
        ? `${ORGANIZATIONS_URL}/${organizationId}/instances`
        : null,
    [organizationId]
  );

  const { data: { data: instances = [] } = {}, ...data } = useData<
    { data: OrganizationInstanceType[] },
    OrganizationInstanceType
  >(key, search);

  return {
    ...data,
    instances,
  };
};
