import { ORGANIZATIONS_URL } from 'src/constants';
import { useData } from './utils';

export interface OrganizationWithInstancesType extends OrganizationType {
  instances: OrganizationInstanceType[];
}

export const useOrganizationsWithInstances = () => {
  const key = `${ORGANIZATIONS_URL}/instances`;

  const { data: { data: organizations = [] } = {}, ...data } = useData<
    { data: OrganizationWithInstancesType[] },
    OrganizationWithInstancesType
  >(key);

  return {
    ...data,
    organizations,
  };
};
