import React from 'react';
import { RouteComponentProps } from '@reach/router';

import { Data as DataDetail } from 'src/layouts/Instance/InstanceDetail/Data';
import { useStore } from 'src/hooks';
import { Container } from 'src/components/Container';
import { PageWrap } from 'src/components/PageWrap';

export const Data: React.FC<RouteComponentProps> = () => {
  const { activeInstance } = useStore();
  return (
    <PageWrap>
      <Container>
        <DataDetail instanceId={activeInstance?.value} />
      </Container>
    </PageWrap>
  );
};
