import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { Icon, colors, fonts } from '@rollioforce/rollio-ui';
import { IconName } from '@rollioforce/rollio-ui/dist/types/components/Icon';

import salesforceLogo from 'src/assets/salesforce-logo.png';

export interface ListProps extends React.HTMLAttributes<HTMLUListElement> {
  items: Array<Omit<ListItemProps, 'onClick'>>;
  onRowClick?: (id: number | string) => void;
}

interface ListItemProps {
  id: number | string;
  title: string;
  description?: string;
  avatar?: string;
  icon?: IconName;
  onClick?: () => void;
}

interface ListItemTextProps {
  primary: string;
  secondary?: string;
}

interface ListItemAvatarProps {
  src: string;
  alt: string;
}

interface ListItemIconProps {
  name: IconName;
}

const StyledList = styled.ul`
  margin: 0;
  padding: 0;
  position: relative;
  list-style: none;
`;

const StyledListItem = styled.li`
  width: 100%;
  display: flex;
  position: relative;
  box-sizing: border-box;
  text-align: left;
  align-items: center;
  padding: 8px 16px;
  justify-content: flex-start;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    text-decoration: none;
    background-color: rgba(0, 0, 0, 0.04);
  }
`;

const StyledListItemAvatar = styled.div`
  min-width: 56px;
  flex-shrink: 0;
`;

const StyledAvatar = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  overflow: hidden;
  position: relative;
  font-size: 1.25rem;
  align-items: center;
  flex-shrink: 0;
  font-family: ${fonts.default};
  line-height: 1;
  user-select: none;
  border-radius: 50%;
  justify-content: center;
`;

const StyledAvatarImg = styled.img`
  color: transparent;
  width: 100%;
  height: 100%;
  object-fit: cover;
  text-align: center;
  text-indent: 10000px;
`;

const StyledListItemIcon = styled.div`
  color: rgba(0, 0, 0, 0.54);
  display: inline-flex;
  min-width: 56px;
  flex-shrink: 0;
`;

const StyledListItemText = styled.div`
  flex: 1 1 auto;
  min-width: 0;
  margin-top: 6px;
  margin-bottom: 6px;
`;

const StyledListItemTextPrimary = styled.span`
  font-size: 1rem;
  font-family: ${fonts.default};
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  color: ${colors.greyDark};
`;

const StyledListItemTextSecondary = styled.p`
  margin: 0;
  font-size: 0.875rem;
  font-family: ${fonts.default};
  font-weight: 400;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  color: ${colors.greyLight};
`;

const ListItemText: React.FC<ListItemTextProps> = ({ primary, secondary }) => (
  <StyledListItemText>
    <StyledListItemTextPrimary>{primary}</StyledListItemTextPrimary>
    {secondary && (
      <StyledListItemTextSecondary>{secondary}</StyledListItemTextSecondary>
    )}
  </StyledListItemText>
);

const ListItemAvatar: React.FC<ListItemAvatarProps> = ({ src, alt }) => {
  const [error, setError] = useState(false);
  const imageSrc = useMemo(() => (!error ? src : salesforceLogo), [src, error]);

  return (
    <StyledListItemAvatar>
      <StyledAvatar>
        <StyledAvatarImg
          src={imageSrc}
          alt={alt}
          onError={() => setError(true)}
        />
      </StyledAvatar>
    </StyledListItemAvatar>
  );
};
const ListItemIcon: React.FC<ListItemIconProps> = ({ name }) => (
  <StyledListItemIcon>
    <Icon name={name} />
  </StyledListItemIcon>
);

const ListItem: React.FC<ListItemProps> = ({
  title,
  description,
  avatar,
  icon,
  onClick,
}) => (
  <StyledListItem onClick={onClick}>
    {avatar && <ListItemAvatar alt={title} src={avatar} />}
    {icon && <ListItemIcon name={icon} />}
    <ListItemText primary={title} secondary={description} />
  </StyledListItem>
);

export const List: React.FC<ListProps> = ({ items, onRowClick, ...props }) => (
  <StyledList {...props}>
    {items.map((item) => (
      <ListItem
        key={item.id}
        onClick={(): void => {
          if (onRowClick) {
            onRowClick(item.id);
          }
        }}
        {...item}
      />
    ))}
  </StyledList>
);
