import { useMemo } from 'react';

import { INSTANCES_URL } from 'src/constants';

import { useData } from './utils';

export const useInstanceDeploymentsData = (
  instanceId: string,
  search?: SearchParams
) => {
  const key = useMemo(
    () => (instanceId ? `${INSTANCES_URL}/${instanceId}/deployments` : null),
    [instanceId]
  );

  const { data: { data: deployments = [] } = {}, ...data } = useData<
    { data: DeploymentType[] },
    DeploymentType
  >(key, search);

  return {
    ...data,
    deployments,
  };
};
