import React, { useMemo } from 'react';
import { Form, FormField, FormProps } from '@rollioforce/rollio-admin-ui';

export interface PromptFormProps extends Partial<FormProps> {
  initialData: any;
  objects?: any[];
  availableFields?: any[];
  field?: any;
  onSubmit: (formDetails: any) => void;
}

const DEFAULT_PROMPT_FORM: Partial<any> = {};

export const PromptForm: React.FC<PromptFormProps> = ({
  initialData,
  objects,
  availableFields,
  field,
  ...props
}) => {
  const initialFormData = useMemo(() => {
    return {
      ...DEFAULT_PROMPT_FORM,
      ...initialData,
    };
  }, [initialData]);

  const OBJECTS_DATA = useMemo(
    () =>
      objects?.map((i) => ({
        label: i.label,
        value: String(i.id),
      })),
    [objects]
  );

  const AVAILABLE_FIELDS_DATA = useMemo(
    () =>
      availableFields?.map((i) => ({
        label: i.label,
        value: i.api_name,
      })),
    [availableFields]
  );

  const defautlValueField: FormField = useMemo(() => {
    const baseField = {
      key: 'defaultValue',
      label: 'Default Value',
    };

    switch (field?.type) {
      case 'Number':
      case 'Currency':
        return {
          ...baseField,
          type: 'input',
          validate: 'onChange',
          fieldProps: {
            type: 'number',
          },
        };
      case 'Checkbox':
        return {
          ...baseField,
          type: 'checkbox',
          validate: 'onChange',
        };
      case 'LongTextArea':
        return {
          ...baseField,
          type: 'textarea',
          validate: 'onChange',
        };
      default:
        return {
          ...baseField,
          type: 'input',
          validate: 'onChange',
        };
    }
  }, [field?.type]);

  const extraFields: FormField[] = useMemo(() => {
    switch (field?.type) {
      case 'Lookup':
      case 'MultiLookup':
        return [
          {
            key: 'lookupObjects',
            label: 'Lookup Objects',
            type: 'select',
            validate: 'onChange',
            fieldProps: {
              options: OBJECTS_DATA,
              multiple: true,
            },
            valueFormat: (v) =>
              v ? OBJECTS_DATA.filter((d) => v.includes(d.value)) : [],
            onUpdateFormat: (v) => (v || []).map(({ value }) => value),
          },
        ];
      case 'Picklist':
      case 'MultiPicklist':
      case 'QuickReply':
        return [
          {
            key: 'allowedValues',
            label: 'Allowed Values',
            type: 'table',
            fieldProps: {
              allowRowSelect: true,
              allowRowReorder: true,
              noDataText: 'No allowed values found',
              miniFormAddNewLabel: 'Add Allowed Value',
              TheadComponent: () => null,
              style: { minHeight: 110 },
              miniFormFields: [
                // TODO: Temporarily disabled
                // {
                //   type: 'input',
                //   key: 'name',
                //   label: 'Allowed Value',
                // },
              ],
            },
          },
          // TODO: Temporarily removed
          // {
          //   key: 'standardAllowedValues',
          //   label: 'Standard Allowed Values',
          //   type: 'table',
          //   fieldProps: {
          //     allowRowSelect: true,
          //     allowRowReorder: true,
          //     noDataText: 'No standard allowed values found',
          //     miniFormAddNewLabel: 'Add Standard Allowed Value',
          //     TheadComponent: () => null,
          //     style: { minHeight: 110 },
          //     miniFormFields: [
          //       {
          //         type: 'input',
          //         key: 'name',
          //         label: 'Standard Allowed Value',
          //       },
          //     ],
          //   },
          // },
        ];
      case 'Text':
      case 'LongTextArea':
        return [
          {
            key: 'append',
            label: 'Append',
            type: 'checkbox',
            validate: 'onChange',
            valueFormat: (v) => v === 1,
            onUpdateFormat: (v) => (v ? 1 : 0),
          },
          {
            key: 'appendTimestampFormat',
            label: 'Append Timestamp Format',
            type: 'input',
            validate: 'onChange',
            isVisible: true, // TODO: Make visible only when append is checked
          },
        ];
      default:
        return [];
    }
  }, [OBJECTS_DATA, field?.type]);

  const FORM_FIELDS: FormField[] = useMemo(
    () => [
      {
        key: 'row',
        type: 'row',
        fields: [
          {
            key: 'name',
            label: 'Name',
            type: 'input',
            validate: 'onChange',
          },
          {
            key: 'api',
            label: 'Data',
            type: 'select',
            validate: 'onChange',
            fieldProps: {
              options: AVAILABLE_FIELDS_DATA,
              disabled: field?.required,
            },
            valueFormat: (v) =>
              v ? AVAILABLE_FIELDS_DATA.find((i) => i.value === v) : null,
            onUpdateFormat: ({ value }) => value,
          },
        ],
      },
      defautlValueField,
      {
        key: 'prompts',
        type: 'table',
        label: 'Prompt Messages',
        fieldProps: {
          allowRowSelect: true,
          allowRowReorder: true,
          noDataText: 'No prompt message found',
          miniFormAddNewLabel: 'Add Prompt Message',
          TheadComponent: () => null,
          style: { minHeight: 110 },
          miniFormFields: [
            {
              type: 'input',
              key: 'name',
              label: 'Prompt message',
            },
          ],
        },
      },
      {
        key: 'fallbackPrompts',
        type: 'table',
        label: 'Fallback Messages',
        fieldProps: {
          allowRowSelect: true,
          allowRowReorder: true,
          noDataText: 'No fallback message found',
          miniFormAddNewLabel: 'Add Fallback Message',
          TheadComponent: () => null,
          style: { minHeight: 110 },
          miniFormFields: [
            {
              type: 'input',
              key: 'name',
              label: 'Fallback message',
            },
          ],
        },
      },
      ...extraFields,
    ],
    [AVAILABLE_FIELDS_DATA, defautlValueField, extraFields, field]
  );

  return (
    <Form
      {...props}
      fields={FORM_FIELDS}
      initialData={initialFormData}
      //TODO: Add schema for validation
    />
  );
};
