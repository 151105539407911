import React from 'react';
import { TableLabel } from '@rollioforce/rollio-ui';

import { utils } from 'src/services';

interface Props {
  value?: string;
}

export const TableDateCell: React.FC<Props> = ({ value }) => (
  <TableLabel>{value ? utils.formatDate(value) : '-'}</TableLabel>
);
