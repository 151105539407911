import { useMemo } from 'react';

import { DEPLOYMENTS_URL } from 'src/constants';

import { useData } from './utils';

export const useDeploymentData = (depoymentId: string) => {
  const key = useMemo(
    () => (depoymentId ? `${DEPLOYMENTS_URL}/${depoymentId}` : null),
    [depoymentId]
  );
  const { data: { data: deployment = null } = {}, ...data } = useData<
    { data: DeploymentType },
    DeploymentType
  >(key);

  return {
    ...data,
    deployment,
  };
};
