import React from 'react';
import { RouteComponentProps } from '@reach/router';

import { FlowDetail } from 'src/layouts/Flow';
import { PageWrap } from 'src/components/PageWrap';

interface Props extends RouteComponentProps {
  flowId?: string;
}

export const Flow: React.FC<Props> = ({ flowId }) => (
  <PageWrap>
    <FlowDetail flowId={flowId} />
  </PageWrap>
);
