import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { Button, colors } from '@rollioforce/rollio-ui';
import { LoadingIndicator } from '@rollioforce/rollio-admin-ui';

import { SearchForm } from 'src/components/Forms';
import { SfLoginInstanceModal } from 'src/components/Modals';
import { SfUsersTable } from 'src/components/Tables';
import { useInstanceSfUsersData } from 'src/hooks';
import { formatDate } from 'src/services/utils';

const TopTable = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  margin-bottom: 10px;
`;

const SyncButton = styled(Button)`
  margin-left: 10px;
`;

const StyledSynchedDate = styled.div`
  text-align: right;
  font-size: 11px;
  color: ${colors.greyLight};
  margin-top: 10px;
`;

export const SFUsers = ({ instanceId, synchedSfUsersAt }) => {
  const [searchParams, setSearchParams] = useState<SearchParams>();
  const {
    users,
    isLoading,
    isLoadingFetcher: isSyncing,
    refresh,
    syncUsers,
  } = useInstanceSfUsersData(instanceId, searchParams);
  const [isSfLoginModalOpen, setIsSfLoginModalOpen] = useState(false);

  const onSyncUsers = useCallback(
    async (username, sfToken) => {
      try {
        const res = await syncUsers(username, sfToken);

        if (res && res.status === 'error') {
          toast(res.message, { type: 'error' });
          return;
        }

        refresh();
        toast('Users synced successfully', { type: 'success' });
      } catch (error) {
        toast(error.message, { type: 'error' });
      }
    },
    [refresh, syncUsers]
  );

  return (
    <>
      <TopTable>
        <SearchForm
          initialData={searchParams}
          inputLabel="Search users by name or email"
          onSubmit={setSearchParams}
        />
        <SyncButton
          background="greyDark"
          onClick={() => setIsSfLoginModalOpen(true)}
        >
          Sync (Requires Salesforce Login)
        </SyncButton>

        <SfLoginInstanceModal
          instanceId={instanceId}
          isOpen={isSfLoginModalOpen}
          onCloseClick={() => setIsSfLoginModalOpen(false)}
          onLoginSuccess={({ username, data }) => {
            setIsSfLoginModalOpen(false);
            onSyncUsers(username, data);
          }}
          onLoginError={(message) => {
            setIsSfLoginModalOpen(false);
            toast(message, { type: 'error' });
          }}
        />
      </TopTable>
      <SfUsersTable data={users} />
      {synchedSfUsersAt && (
        <StyledSynchedDate>
          Last synched: {formatDate(synchedSfUsersAt)}
        </StyledSynchedDate>
      )}

      <LoadingIndicator isLoading={isLoading || isSyncing} />
    </>
  );
};
