import { useCallback, useMemo } from 'react';

import { FLOWS_URL } from 'src/constants';

import { useData, useFetcher } from './utils';

export const useFlowData = (flowId: string) => {
  const { send, isLoading } = useFetcher();

  const key = useMemo(() => `${FLOWS_URL}/${flowId}`, [flowId]);

  const { data: { data: flow = null } = {}, ...data } = useData<
    { data: FlowType },
    FlowType
  >(key);

  const updateFlow = useCallback(
    async (newData) => {
      return send(FLOWS_URL, 'POST', newData);
    },
    [send]
  );

  return {
    ...data,
    flow,
    updateFlow,
    isLoadingFetcher: isLoading,
  };
};
