import React, { useMemo } from 'react';
import { groupBy } from 'ramda';
import { Form, FormFields, FormProps } from '@rollioforce/rollio-admin-ui';
import { user } from '@rollioforce/rollio-schemas';

import { useOrganizationDeploymentsData, usePermission } from 'src/hooks';

export interface UserFormProps extends Partial<FormProps> {
  initialData?: OrganizationUserType;
  onSubmit: (formDetails: OrganizationUserType) => void;
  organizationId: number;
  instanceId?: string;
}

const DEFAULT_USER_FORM: Omit<OrganizationUserType, 'organization_id'> = {
  first_name: '',
  last_name: '',
  email: '',
  deployment_ids: null,
  profile_image_url: '',
  is_active: 1,
};

export const UserForm: React.FC<UserFormProps> = ({
  initialData,
  organizationId,
  instanceId,
  ...props
}) => {
  const { deployments } = useOrganizationDeploymentsData(
    String(organizationId)
  );
  const { isInternal } = usePermission();

  const formData = useMemo(() => {
    return {
      ...DEFAULT_USER_FORM,
      ...initialData,
    };
  }, [initialData]);

  const isEditing = useMemo(() => !!initialData, [initialData]);

  const DEPLOYMENTS_SELECT_VALUES = useMemo(() => {
    const groups = groupBy<DeploymentType>(({ instance }) => instance?.name)(
      deployments.filter((d) =>
        instanceId ? String(d.instance_id) === instanceId : true
      )
    );

    return Object.entries(groups).map(([label, options]) => ({
      label,
      value: '',
      options: options.map((d) => ({
        label: isInternal ? `${d.name} [${d.id}]` : d.name,
        value: String(d.id),
      })),
    }));
  }, [deployments, instanceId, isInternal]);

  const FORM_FIELDS: FormFields = useMemo(
    () => [
      {
        key: 'topRow',
        type: 'row',
        fields: [
          {
            key: 'first_name',
            label: 'First Name',
            type: 'input',
            validate: 'onChange',
          },
          {
            key: 'last_name',
            label: 'Last Name',
            type: 'input',
            validate: 'onChange',
          },
        ],
      },
      {
        key: 'userDetails',
        type: 'row',
        fields: [
          {
            key: 'email',
            label: 'Email',
            type: 'input',
            validate: 'onBlur',
            disabled: !isEditing,
          },
          {
            key: 'profile_image_url',
            label: 'Profile Image URL',
            type: 'input',
            validate: 'onBlur',
          },
        ],
      },
      {
        key: 'settingsRow',
        type: 'row',
        fields: [
          {
            key: 'deployment_ids',
            label: 'Deployments',
            type: 'select',
            validate: 'onChange',
            fieldProps: {
              options: DEPLOYMENTS_SELECT_VALUES,
              multiple: true,
            },
            valueFormat: (v) => {
              // return only DEPLOYMENTS_SELECT_VALUES items whose ids are selected
              const values = DEPLOYMENTS_SELECT_VALUES.reduce(
                (acc, item) => [...acc, ...item.options],
                []
              );
              return v ? values.filter((d) => v.includes(d.value)) : [];
            },
            onUpdateFormat: (v) => (v || []).map(({ value }) => value),
          },
          {
            key: 'is_active',
            label: 'Active',
            type: 'checkbox',
            validate: 'onChange',
            valueFormat: (v) => v === 1,
            onUpdateFormat: (v) => (v ? 1 : 0),
          },
        ],
        style: { alignItems: 'center' },
      },
    ],
    [DEPLOYMENTS_SELECT_VALUES, isEditing]
  );

  return (
    <Form
      {...props}
      enableReinitialize
      fields={FORM_FIELDS}
      initialData={formData}
      schema={user}
    />
  );
};
