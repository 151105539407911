import React, { useCallback, useMemo, useState } from 'react';
import { LoadingIndicator } from '@rollioforce/rollio-admin-ui';
import { Table, TableLabel } from '@rollioforce/rollio-ui';
import { Columns } from '@rollioforce/rollio-ui/dist/types/components/Table';

import { useConversationsData, usePermission } from 'src/hooks';

import { Modal, ModalProps } from './Modal';
import { ConversationModal } from './ConversationModal';
import { TableDateCell } from '../Tables/TableCells';

interface Props extends Omit<ModalProps, 'children' | 'small'> {
  deploymentId: string;
  sfUserId: string;
}

export const ConversationListModal: React.FC<Props> = ({
  deploymentId,
  sfUserId,
  ...props
}) => {
  const [pagination, setPagination] = useState<PaginationType>({
    limit: 5,
    offset: 0,
  });
  const [selected, setSelected] = useState<ConversationType>(null);
  const { conversations, totalItems, isLoading } = useConversationsData(
    deploymentId,
    sfUserId,
    pagination
  );
  const { isInternal } = usePermission();

  const onPageChange = useCallback(
    (page: number) => {
      const offset = page * pagination.limit;
      setPagination({ ...pagination, offset });
    },
    [pagination]
  );

  const onPageSizeChange = useCallback(
    (pageSize: number, page: number) => {
      const offset = page * pagination.limit;
      setPagination({ limit: pageSize, offset });
    },
    [pagination]
  );

  const pages = useMemo(() => Math.ceil(totalItems / pagination.limit), [
    pagination,
    totalItems,
  ]);

  const columns: Columns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
        width: 80,
        show: isInternal,
      },
      {
        Header: 'Title',
        accessor: 'title',
        Cell: ({ original }) => (
          <TableLabel
            variant="header"
            isLink
            onClick={() => setSelected(original)}
          >
            {original.title}
          </TableLabel>
        ),
      },
      {
        Header: 'Messages',
        accessor: 'messages',
        Cell: ({ original }) => (
          <TableLabel variant="code">{original.messagesCount}</TableLabel>
        ),
      },
      {
        Header: 'Status',
        accessor: 'status',
      },
      {
        Header: 'Created at',
        accessor: 'created_at',
        Cell: TableDateCell,
      },
    ],
    [isInternal]
  );

  const isConversationModalOpen = useMemo(() => !!selected, [selected]);

  return (
    <Modal {...props}>
      <Table
        columns={columns}
        data={conversations}
        manual
        showPagination
        pageSize={pagination.limit}
        pages={pages}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
      />
      <LoadingIndicator isLoading={isLoading} />

      {
        isConversationModalOpen ?
          <ConversationModal
            deploymentId={deploymentId}
            conversationId={selected?.id.toString()}
            sfUserId={sfUserId}
            isOpen={isConversationModalOpen}
            onCloseClick={() => setSelected(null)}
            title={selected?.title}
          /> : null
      }
    </Modal>
  );
};
