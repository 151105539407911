import React, { useMemo } from 'react';
import { Icon, Table, TableLabel, TableProps } from '@rollioforce/rollio-ui';
import { Columns } from '@rollioforce/rollio-ui/dist/types/components/Table';

import { ACTION, RESOURCE } from 'src/constants';
import { usePermission } from 'src/hooks';

import { TableBitCell, TableDateCell } from './TableCells';

interface Props extends Omit<TableProps, 'columns'> {
  onViewDeploymentsClick: (user: any) => void;
  onRowClick: (id: number) => void;
}

export const UsersTable: React.FC<Props> = ({
  onViewDeploymentsClick,
  onRowClick,
  data,
  ...props
}) => {
  const { checkPermission, isInternal } = usePermission();

  const columns: Columns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
        width: 80,
        show: isInternal,
      },
      {
        Header: 'Name',
        accessor: 'name',
        Cell: ({ original }) => (
          <TableLabel
            isLink
            variant="header"
            onClick={() => onRowClick(original.id)}
          >
            {original.first_name} {original.last_name}
          </TableLabel>
        ),
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'Created at',
        accessor: 'created_at',
        Cell: TableDateCell,
      },
      {
        Header: 'Last updated',
        accessor: 'updated_at',
        Cell: TableDateCell,
      },
      {
        Header: 'Active',
        accessor: 'is_active',
        Cell: TableBitCell,
        show: checkPermission(RESOURCE.ROLLIO_USER, ACTION.ACTIVATE),
      },
      {
        Header: '',
        accessor: 'actions',
        show: checkPermission(RESOURCE.DEPLOYMENT, ACTION.READ),
        Cell: ({ original }) => (
          <TableLabel
            isLink
            onClick={() => onViewDeploymentsClick(original)}
            title="View deployments"
          >
            <Icon name="widgets" size="small" />
          </TableLabel>
        ),
      },
    ],
    [checkPermission, isInternal, onRowClick, onViewDeploymentsClick]
  );

  return (
    <Table
      {...props}
      columns={columns}
      data={data}
      showPagination={data.length >= 20}
    />
  );
};
