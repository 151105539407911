import React, { useEffect } from 'react';
import { navigate, RouteComponentProps } from '@reach/router';

import { Login as LoginLayout } from 'src/layouts/Auth';
import { useAuth } from 'src/hooks';
import { getRouteUrlById, ROUTES } from 'src/routes';

export const Login: React.FC<RouteComponentProps> = () => {
  const { isLoggedIn } = useAuth();

  useEffect(() => {
    if (isLoggedIn) {
      navigate(getRouteUrlById(ROUTES.home), { replace: true });
    }
  }, [isLoggedIn]);

  return <LoginLayout />;
};
