import React from 'react';
import {
  ToastContainer as ReactToastContainer,
  ToastContainerProps,
} from 'react-toastify';
import styled from 'styled-components';
import { colors } from '@rollioforce/rollio-ui';

const StyledToastContainer = styled(ReactToastContainer)`
  .Toastify__toast {
    background-color: ${colors.white};
    color: ${colors.greyDark};
    padding-left: 17px;

    &:before {
      content: '';
      width: 7px;
      position: absolute;
      bottom: 0;
      left: 0;
      top: 0;
    }

    .Toastify__close-button {
      color: ${colors.greyLight};
    }

    &--success:before {
      background: ${colors.secondary};
    }

    &--error:before {
      background: ${colors.red};
    }
  }
`;

export const ToastContainer: React.FC<ToastContainerProps> = (props) => (
  <StyledToastContainer autoClose={3000} hideProgressBar {...props} />
);
