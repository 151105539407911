import { useMemo } from 'react';

import { USERS_URL } from 'src/constants';

import { useData } from './utils';

export const useUserData = (userId: string) => {
  const key = useMemo(() => (userId ? `${USERS_URL}/${userId}` : null), [
    userId,
  ]);
  const { data: { data: user = null } = {}, ...data } = useData<
    { data: OrganizationUserType },
    OrganizationUserType
  >(key);

  return {
    ...data,
    user,
  };
};
