import React from 'react';
import styled from 'styled-components';
import { colors, fonts } from '@rollioforce/rollio-ui';

interface HighlightedTextProps {
  text: string;
  regex?: RegExp;
  removeTextRegex?: RegExp;
  textStyle?: React.CSSProperties;
  style?: React.CSSProperties;
}

export const StyledText = styled.span`
  color: ${colors.primary};
  font-family: ${fonts.default};
  font-weight: 600;
`;

const TextWrapper = styled.span``;

export const HighlightedText: React.FC<HighlightedTextProps> = ({
  text,
  regex = /\[\[(.*?)\]\]/g,
  removeTextRegex,
  textStyle,
  ...props
}) => {
  const wordsToColor = text.replace(removeTextRegex, '').split(regex);
  const splitFormattedText = wordsToColor.map((word, index) => {
    if (index % 2) {
      return (
        <StyledText style={textStyle} key={`${index}${word[index]}`}>
          {word}
        </StyledText>
      );
    }
    return word;
  });

  return <TextWrapper {...props}>{splitFormattedText}</TextWrapper>;
};
