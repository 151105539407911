import React from 'react';
import { RouteComponentProps } from '@reach/router';

import { ConfigurationDetail } from 'src/layouts/Configuration';
import { PageWrap } from 'src/components/PageWrap';
import { CONFIGURATION_TYPE } from 'src/constants';

interface Props extends RouteComponentProps {
  type: CONFIGURATION_TYPE;
}

export const Configuration: React.FC<Props> = ({ type }) => (
  <PageWrap>
    <ConfigurationDetail type={type} />
  </PageWrap>
);
