import React from 'react';
import { RouteComponentProps } from '@reach/router';

import { ConfigurationDashboard } from 'src/layouts/Configuration';
import { PageWrap } from 'src/components/PageWrap';

export const Configurations: React.FC<RouteComponentProps> = () => (
  <PageWrap>
    <ConfigurationDashboard />
  </PageWrap>
);
