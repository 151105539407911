import React from 'react';
import { RouteComponentProps } from '@reach/router';

import { OrganizationDetail } from 'src/layouts/Organization';
import { PageWrap } from 'src/components/PageWrap';
import { useUserProfile } from 'src/hooks';

export const MyOrganization: React.FC<RouteComponentProps> = () => {
  const userProfile = useUserProfile();
  return (
    <PageWrap>
      <OrganizationDetail
        organizationId={String(userProfile?.organization_id)}
      />
    </PageWrap>
  );
};
