import React from 'react';
import { LoadingIndicator } from '@rollioforce/rollio-admin-ui';

import { useAuth, useNavGuard, usePermission } from 'src/hooks';

import { Error } from './Error';

export const PageWrap: React.FC = ({ children }) => {
  useNavGuard();
  const { isLoggedIn } = useAuth();
  const { hasRouteAccess } = usePermission();

  if (!isLoggedIn) {
    return <LoadingIndicator isLoading />;
  }

  if (!hasRouteAccess) {
    return (
      <Error title="403 Forbidden">
        You don't have permission to access this page
      </Error>
    );
  }

  return <>{children}</>;
};
