import React from 'react';
import styled from 'styled-components';
import { cardStyle } from '@rollioforce/rollio-ui';

type Props = React.HTMLAttributes<HTMLDivElement>;

const StyledContainer = styled.div`
  ${cardStyle}
  padding: 20px;

  &:not(:last-of-type) {
    margin-bottom: 20px;
  }
`;

export const Container: React.FC<Props> = ({ children, ...props }) => {
  return <StyledContainer {...props}>{children}</StyledContainer>;
};
