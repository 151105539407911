import { useCallback } from 'react';

import { INSTANCES_URL } from 'src/constants';

import { useData, useFetcher } from './utils';

export const useInstanceDataData = (instanceId: string) => {
  const { send, isLoading } = useFetcher();

  const { data: { data: instanceData = [] } = {}, ...data } = useData<
    { data: any[] },
    any
  >(null);

  const getObjectList = useCallback(
    async (sfToken) => {
      return send(
        `${INSTANCES_URL}/${instanceId}/object-list`,
        'GET',
        undefined,
        {
          headers: {
            'sf-authorization': sfToken,
          },
        }
      );
    },
    [instanceId, send]
  );

  const syncMetadata = useCallback(
    async (objectNames, sfToken) => {
      return send(
        `${INSTANCES_URL}/${instanceId}/metadata-sync`,
        'POST',
        { objectNames },
        {
          headers: {
            'sf-authorization': sfToken,
          },
        }
      );
    },
    [instanceId, send]
  );

  return {
    ...data,
    instanceData,
    isLoadingFetcher: isLoading,
    getObjectList,
    syncMetadata,
  };
};
