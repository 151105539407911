import { useCallback, useMemo } from 'react';

import { STT_REPORTS_URL } from 'src/constants';

import { useData, useFetcher } from './utils';

export const useSTTReportsData = (
  deploymentId: string,
  search?: SearchParams
) => {
  const { send, isLoading } = useFetcher();
  const key = useMemo(() => `${STT_REPORTS_URL}/${deploymentId}`, [
    deploymentId,
  ]);

  const { data: { data: sttData = [] } = {}, ...data } = useData<
    { data: any[] },
    any
  >(key, search);

  const generateReport = useCallback(
    async (date_from: string, date_to: string) => {
      return send(
        `${STT_REPORTS_URL}/report`,
        'POST',
        {
          date_from,
          date_to,
        },
        { downloadFile: true }
      );
    },
    [send]
  );

  const editReport = useCallback(
    async (id: number, params: STTReportType) => {
      return send(
        `${STT_REPORTS_URL}/${deploymentId}/data/${id}`,
        'PUT',
        params,
        { doNotMutate: true }
      );
    },
    [send, deploymentId]
  );

  return {
    ...data,
    sttData,
    generateReport,
    editReport,
    isLoadingFetcher: isLoading,
  };
};
