import React, { useMemo } from 'react';
import { Table, TableLabel, TableProps } from '@rollioforce/rollio-ui';
import { Columns } from '@rollioforce/rollio-ui/dist/types/components/Table';

import { usePermission } from 'src/hooks';

import { TableDateCell } from './TableCells';

interface Props extends Omit<TableProps, 'columns'> {}

export const SfAvailableUsersTable: React.FC<Props> = ({ data, ...props }) => {
  const { isInternal } = usePermission();

  const columns: Columns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
        width: 80,
        show: isInternal,
      },
      {
        Header: 'Name',
        accessor: 'name',
        Cell: ({ original }) => (
          <TableLabel variant="header">
            {original.first_name} {original.last_name}
          </TableLabel>
        ),
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'Created at',
        accessor: 'created_at',
        Cell: TableDateCell,
      },
      {
        Header: 'Last updated',
        accessor: 'updated_at',
        Cell: TableDateCell,
      },
    ],
    [isInternal]
  );

  return (
    <Table
      {...props}
      columns={columns}
      data={data}
      showPagination={data.length >= 20}
    />
  );
};
