import React, { useCallback, useState } from 'react';
import styled, { css } from 'styled-components';
import { AccordionList, colors, Icon } from '@rollioforce/rollio-ui';
import { IconName } from '@rollioforce/rollio-ui/dist/types/components/Icon';
import FlowChart from '../../../components/Chart/FlowChart';

interface Props {}

interface LabelProps {
  icon: IconName;
  label: string;
  isOpen?: boolean;
}

const StyledSidebarPanel = styled.div`
  position: fixed;
  width: 430px;
  height: calc(100% - 74px);
  right: 0;
  margin-top: -10px;
  display: flex;
  flex-direction: column;
`;

const StyledLabel = styled.div`
  position: relative;
  background-color: ${colors.white};
  box-shadow: 0 2px 4px 0 rgba(137, 137, 137, 0.2);
  display: flex;
  flex-direction: row;
  padding: 4px 10px;
  cursor: pointer;
`;

const LabelText = styled.span`
  margin-left: 10px;
  color: ${colors.greyDark};
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  flex: 1;
`;

const Content = styled.div`
  background-color: ${colors.white};
  flex: 1;
`;

const StyledIcon = styled(Icon)((props) => {
  const rotation = props.$isOpen ? '180' : '0';

  return css`
    transform: rotate(${rotation}deg);
    transition: transform 0.2s ease;
  `;
});

const Label: React.FC<LabelProps> = ({ icon, label, isOpen }) => (
  <StyledLabel>
    <Icon name={icon} size="small" color="primary" />
    <LabelText>{label}</LabelText>
    <StyledIcon
      name="chevronUp"
      size="small"
      color="greyLight"
      $isOpen={isOpen}
    />
  </StyledLabel>
);

export const SidebarPanel: React.FC<Props> = () => {
  const [activeAccordions, setActiveAccordions] = useState(['chart', 'chat']);
  const onAccordionSelect = useCallback(
    (id: string, isOpen: boolean) =>
      setActiveAccordions((prevActiveAccordions) =>
        !isOpen
          ? [...prevActiveAccordions, id]
          : prevActiveAccordions.filter((x) => x !== id)
      ),
    []
  );

  return (
    <StyledSidebarPanel>
      <AccordionList
        items={[
          {
            id: 'chart',
            label: (isOpen) => (
              <Label icon="timeline" label="Flow Chart" isOpen={isOpen} />
            ),
            content: <FlowChart isOpen={activeAccordions.includes('chart') && activeAccordions.length === 1}/>
          },
          {
            id: 'chat',
            label: (isOpen) => (
              <Label icon="conversation" label="Chat" isOpen={isOpen} />
            ),
            content: <Content />,
          },
        ]}
        activeAccordions={activeAccordions}
        onAccordionSelect={onAccordionSelect}
      />
    </StyledSidebarPanel>
  );
};
