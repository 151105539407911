import React from 'react';
import { RouteComponentProps } from '@reach/router';

import { InstanceDetail } from 'src/layouts/Instance';
import { PageWrap } from 'src/components/PageWrap';

interface Props extends RouteComponentProps {
  instanceId?: string;
}

export const Instance: React.FC<Props> = ({ instanceId }) => (
  <PageWrap>
    <InstanceDetail instanceId={instanceId} />
  </PageWrap>
);
