import React from 'react';
import styled from 'styled-components';
import { navigate } from '@reach/router';
import { Card } from '@rollioforce/rollio-ui';

import { getRouteUrlById, ROUTES } from 'src/routes';
import { CONFIGURATION_TYPE } from 'src/constants';
import { useStore } from 'src/hooks';

import { ActiveDeployment } from './ActiveDeployment';

const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
const CardItem = styled.div`
  padding: 0 10px 10px 0;
`;

export const ConfigurationDashboard: React.FC = () => {
  const { activeDeploymentId } = useStore();

  return (
    <>
      <ActiveDeployment />

      {activeDeploymentId && (
        <CardContainer>
          <CardItem>
            <Card
              title="CORE"
              description="Manage CORE deployment configuration."
              buttonLabel="View More"
              onButtonClick={() =>
                navigate(
                  getRouteUrlById(ROUTES.configurationDetail, {
                    deploymentId: activeDeploymentId,
                    type: CONFIGURATION_TYPE.CORE,
                  })
                )
              }
            />
          </CardItem>
          <CardItem>
            <Card
              title="NLP"
              description="Manage NLP deployment configuration."
              buttonLabel="View More"
              onButtonClick={() =>
                navigate(
                  getRouteUrlById(ROUTES.configurationDetail, {
                    deploymentId: activeDeploymentId,
                    type: CONFIGURATION_TYPE.NLP,
                  })
                )
              }
            />
          </CardItem>
          <CardItem>
            <Card
              title="STT"
              description="Manage STT deployment configuration."
              buttonLabel="View More"
              onButtonClick={() =>
                navigate(
                  getRouteUrlById(ROUTES.configurationDetail, {
                    deploymentId: activeDeploymentId,
                    type: CONFIGURATION_TYPE.STT,
                  })
                )
              }
            />
          </CardItem>
          <CardItem>
            <Card
              title="CHROME"
              description="Manage CHROME deployment configuration."
              buttonLabel="View More"
              onButtonClick={() =>
                navigate(
                  getRouteUrlById(ROUTES.configurationDetail, {
                    deploymentId: activeDeploymentId,
                    type: CONFIGURATION_TYPE.CHROME,
                  })
                )
              }
            />
          </CardItem>
        </CardContainer>
      )}
    </>
  );
};
