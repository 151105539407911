import React from 'react';
import styled from 'styled-components';
import { Icon, TableLabel } from '@rollioforce/rollio-ui';
import { ColorName } from '@rollioforce/rollio-ui/dist/types/theme';

interface TableStatusReferenceItem {
  value: number;
  text: string;
  color: ColorName;
}
export type TableStatusReference = TableStatusReferenceItem[];

const LabelContainer = styled.span`
  display: flex;
`;

const LabelText = styled.span`
  margin-left: 5px;
`;

interface Props {
  value?: number;
  reference?: TableStatusReference;
}

export const TableStatusCell: React.FC<Props> = ({ value, reference }) => {
  const current = reference.find((ref) => ref.value === value);

  if (!current) {
    return null;
  }

  return (
    <TableLabel>
      <LabelContainer>
        <Icon name="circle" color={current.color} size="small" />
        <LabelText>{current.text} </LabelText>
      </LabelContainer>
    </TableLabel>
  );
};
