import React, { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { Button, colors } from '@rollioforce/rollio-ui';
import { LoadingIndicator } from '@rollioforce/rollio-admin-ui';

import { useSfAvailableUsersData } from 'src/hooks';
import { formatDate } from 'src/services/utils';

import { Modal, ModalProps } from './Modal';
import { SfLoginDeploymentModal } from './SfLoginDeploymentModal';
import { SearchForm } from '../Forms';
import { SfAvailableUsersTable } from '../Tables';

interface Props extends Omit<ModalProps, 'children' | 'small'> {
  deploymentId: number;
  onAddUsers?: (selected: string[]) => Promise<any>;
  selectedUsers?: UserType[];
  synchedSfUsersAt?: string;
}

const ButtonContainer = styled.div`
  display: flex;
`;

const AddButton = styled(Button)`
  margin-top: 20px;
`;

const TopTable = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  margin-bottom: 10px;
`;

const SyncButton = styled(Button)`
  margin-left: 10px;
`;

const StyledSynchedDate = styled.div`
  text-align: right;
  font-size: 11px;
  color: ${colors.greyLight};
  margin-top: 10px;
`;

export const SfSyncModal: React.FC<Props> = ({
  deploymentId,
  onAddUsers,
  selectedUsers,
  synchedSfUsersAt,
  ...props
}) => {
  const [selected, setSelected] = useState([]);
  const [isSfLoginModalOpen, setIsSfLoginModalOpen] = useState(false);
  const [searchParams, setSearchParams] = useState<SearchParams>();

  const {
    availableUsers,
    isLoading,
    isLoadingFetcher: isSyncing,
    refresh,
    syncUsers,
  } = useSfAvailableUsersData(deploymentId, searchParams);

  const onSyncUsers = useCallback(
    async (username, sfToken) => {
      try {
        const res = await syncUsers(username, sfToken);

        if (res && res.status === 'error') {
          toast(res.message, { type: 'error' });
          return;
        }

        refresh();
        toast('Users synced successfully', { type: 'success' });
      } catch (error) {
        toast(error.message, { type: 'error' });
      }
    },
    [refresh, syncUsers]
  );

  const onSelectClick = useCallback(
    (userId, isSelected) => {
      setSelected(
        isSelected
          ? [...selected, userId]
          : selected.filter((id) => id !== userId)
      );
    },
    [selected]
  );

  const onSelectAllClick = useCallback(
    (isSelected) => {
      setSelected(isSelected ? availableUsers.map((user) => user.id) : []);
    },
    [availableUsers]
  );

  const onAddClick = useCallback(async () => {
    await onAddUsers(selected);
    setSelected([]);
    refresh();
  }, [onAddUsers, selected, refresh]);

  return (
    <>
      <Modal {...props}>
        <TopTable>
          <SearchForm
            initialData={searchParams}
            inputLabel="Search users by name or email"
            onSubmit={setSearchParams}
          />
          <SyncButton
            background="greyDark"
            onClick={() => setIsSfLoginModalOpen(true)}
          >
            Sync (Requires Salesforce Login)
          </SyncButton>

          <SfLoginDeploymentModal
            deploymentId={deploymentId}
            isOpen={isSfLoginModalOpen}
            onCloseClick={() => setIsSfLoginModalOpen(false)}
            onLoginSuccess={({ username, data }) => {
              setIsSfLoginModalOpen(false);
              onSyncUsers(username, data);
            }}
            onLoginError={(message) => {
              setIsSfLoginModalOpen(false);
              toast(message, { type: 'error' });
            }}
          />
        </TopTable>

        <SfAvailableUsersTable
          data={availableUsers}
          keyField="id"
          allowRowSelect
          selectedRows={selected}
          onRowToggle={onSelectClick}
          onToggleAll={onSelectAllClick}
        />
        {synchedSfUsersAt && (
          <StyledSynchedDate>
            Last synched: {formatDate(synchedSfUsersAt)}
          </StyledSynchedDate>
        )}

        <ButtonContainer>
          <AddButton
            background="primary"
            onClick={onAddClick}
            disabled={!selected.length}
          >
            Add Selected Users
          </AddButton>
        </ButtonContainer>

        <LoadingIndicator isLoading={isLoading || isSyncing} />
      </Modal>
    </>
  );
};
