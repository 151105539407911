import React, { createContext, Dispatch, useEffect, useReducer } from 'react';

import { State, initialState, storeStateLocally } from './state';
import { ActionType } from './actionTypes';
import { reducer } from './reducer';

export const Store = createContext<{
  store: State;
  dispatch: Dispatch<ActionType>;
}>(null);

export const StoreProvider: React.FC = ({ children }) => {
  const [store, dispatch]: [State, Dispatch<ActionType>] = useReducer(
    reducer,
    initialState
  );

  useEffect(() => {
    storeStateLocally(store);
  }, [store]);

  return (
    <Store.Provider value={{ store, dispatch }}>{children}</Store.Provider>
  );
};
