import React from 'react';
import styled from 'styled-components';
import { max } from 'lodash';
import { Button } from '@rollioforce/rollio-ui';

import { Template } from 'src/components/Template';

export interface TemplatesProps {
  templates?: TemplateType[];
  onChangeTemplates: (templates: TemplateType[]) => void;
  fields?: any[];
}

const TemplatesContainer = styled.div`
  margin-bottom: 20px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const Templates: React.FC<TemplatesProps> = ({
  templates = [],
  fields,
  onChangeTemplates,
}) => {
  const onAddTemplate = () => {
    const maxOrder = max(templates.map((t) => t.order)) ?? -1;
    onChangeTemplates([...templates, { order: maxOrder + 1, text: '' }]);
  };

  const onDeleteTemplate = (index) => {
    onChangeTemplates(templates.filter((t, i) => i !== index));
  };

  const onChangeTemplate = (index, template) => {
    onChangeTemplates(
      templates.map((t, i) => (i === index ? { ...t, ...template } : t))
    );
  };

  return (
    <>
      <TemplatesContainer>
        {(templates || []).map((template, i) => (
          <Template
            key={i}
            data={template}
            showActons
            onDeleteTemplate={() => onDeleteTemplate(i)}
            onChangeTemplate={(text) => onChangeTemplate(i, text)}
            fields={fields}
          />
        ))}
      </TemplatesContainer>

      <ButtonContainer>
        <Button background="greyLight" onClick={onAddTemplate}>
          Add Template
        </Button>
      </ButtonContainer>
    </>
  );
};
