import React, { useMemo } from 'react';
import { Form, FormFields, FormProps } from '@rollioforce/rollio-admin-ui';
import { deployment } from '@rollioforce/rollio-schemas';

import { SELECT_VALUES_A_B } from 'src/constants';
import { useOrganizationInstancesData, usePermission } from 'src/hooks';

export interface DeploymentFormProps extends Partial<FormProps> {
  initialData?: Omit<DeploymentType, 'organization_id'>;
  onSubmit: (formDetails: DeploymentType) => void;
  organizationId: number;
  instanceId?: string;
}

export const DEFAULT_DEPLOYMENT_FORM: Omit<
  DeploymentType,
  'organization_id'
> = {
  name: '',
  is_active: 1,
  language_customization_id_a: null,
  language_customization_id_b: null,
  record_sync_stt: 0,
  active_language_customization: 'A',
  timeformat: '12-hour',
  instance_id: null,
  available_licenses: null,
  used_licenses: null,
  sso_configured: 0,
};

export const DeploymentForm: React.FC<DeploymentFormProps> = ({
  initialData,
  organizationId,
  instanceId,
  ...props
}) => {
  const { isInternal } = usePermission();
  const { instances } = useOrganizationInstancesData(organizationId);

  const INSTANCES_SELECT_VALUES = useMemo(
    () =>
      instances.map((i) => ({
        label: isInternal ? `${i.name} [${i.id}]` : i.name,
        value: String(i.id),
      })),
    [instances, isInternal]
  );

  const formData = useMemo(() => {
    return {
      ...DEFAULT_DEPLOYMENT_FORM,
      instance_id: instanceId,
      ...initialData,
    };
  }, [initialData, instanceId]);

  const isEditing = useMemo(() => !!initialData, [initialData]);

  const FORM_FIELDS: FormFields = useMemo(
    () => [
      {
        key: 'topRow',
        type: 'row',
        fields: [
          {
            key: 'name',
            label: 'Name',
            type: 'input',
            validate: 'onChange',
          },
          {
            key: 'is_active',
            label: 'Active',
            type: 'checkbox',
            validate: 'onChange',
            valueFormat: (v) => v === 1,
            onUpdateFormat: (v) => (v ? 1 : 0),
          },
          {
            key: 'record_sync_stt',
            label: 'Enable stt reporting',
            type: 'checkbox',
            validate: 'onChange',
            isVisible: isInternal,
            valueFormat: (v) => v === 1,
            onUpdateFormat: (v) => (v ? 1 : 0),
          },
        ],
        style: { alignItems: 'center' },
      },
      {
        key: 'customizationIDs',
        type: 'row',
        isVisible: isInternal,
        fields: [
          {
            key: 'language_customization_id_a',
            label: 'Language Customization ID a',
            type: 'input',
            validate: 'onChange',
            fieldProps: {
              disabled: true,
            },
          },
          {
            key: 'language_customization_id_b',
            label: 'Language Customization ID b',
            type: 'input',
            validate: 'onChange',
            fieldProps: {
              disabled: true,
            },
          },
        ],
      },
      {
        key: 'thirdRow',
        type: 'row',
        fields: [
          {
            type: 'select',
            key: 'active_language_customization',
            label: 'Active Language Customization',
            validate: 'onChange',
            isVisible: isInternal,
            fieldProps: {
              disabled: true,
              options: SELECT_VALUES_A_B,
            },
            valueFormat: (v) => {
              if (!v) {
                return null;
              }

              const activeID = SELECT_VALUES_A_B.find((id) => id.value === v);

              return {
                value: v,
                label: activeID ? activeID.label : v,
              };
            },
            onUpdateFormat: ({ value }) => value,
          },
          {
            key: 'timeformat',
            label: 'Time Format',
            type: 'checkboxGroup',
            validate: 'onChange',
            isVisible: isInternal,
            valueFormat: (v) => (v ? [v] : null),
            onUpdateFormat: (value) => value[1],
            checkboxes: [
              {
                label: '12 Hour',
                value: '12-hour',
              },
              {
                label: '24 Hour',
                value: '24-hour',
              },
            ],
          },
        ],
        style: { alignItems: 'center' },
      },
      {
        key: 'loginRow',
        label: 'Login',
        type: 'row',
        fields: [
          {
            key: 'instance_id',
            label: 'Instance',
            type: 'select',
            validate: 'onChange',
            fieldProps: {
              options: INSTANCES_SELECT_VALUES,
              disabled: !!instanceId,
            },
            valueFormat: (v) =>
              v ? INSTANCES_SELECT_VALUES.find((i) => i.value === v) : null,
            onUpdateFormat: ({ value }) => value,
          },
          {
            key: 'sso_configured',
            label: 'Open in a new window',
            type: 'checkbox',
            isVisible: isInternal,
            valueFormat: (v) => v === 1,
            onUpdateFormat: (v) => (v ? 1 : 0),
          },
        ],
        style: { alignItems: 'center' },
      },
      {
        key: 'licenses',
        type: 'row',
        fields: [
          {
            key: 'available_licenses',
            label: 'Available Licenses',
            type: 'input',
            validate: 'onChange',
            fieldProps: {
              disabled: !isInternal,
              type: 'number',
              input: {
                min: 0,
              },
            },
          },
          {
            key: 'used_licenses',
            label: 'Used Licenses',
            type: 'input',
            isVisible: isEditing,
            fieldProps: {
              disabled: true,
            },
          },
        ],
      },
    ],
    [isInternal, isEditing, INSTANCES_SELECT_VALUES, instanceId]
  );

  return (
    <Form
      {...props}
      enableReinitialize
      fields={FORM_FIELDS}
      initialData={formData}
      schema={deployment}
    />
  );
};
