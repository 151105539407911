import React from 'react';
import styled from 'styled-components';
import { colors } from '@rollioforce/rollio-ui';

export interface StepInfoProps {
  title: string;
  message: string;
}

const Container = styled.div`
  padding: 0 100px;
  text-align: center;
  margin-bottom: 40px;
`;

const Title = styled.h1`
  color: ${colors.primary};
  margin-top: 0;
  margin-bottom: 10px;
`;

const Message = styled.h4`
  color: ${colors.greyLight};
  margin: 0;
`;

export const StepInfo: React.FC<StepInfoProps> = ({ title, message }) => (
  <Container>
    <Title>{title}</Title>
    <Message>{message}</Message>
  </Container>
);
