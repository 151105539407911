import { omit, pick } from 'ramda';

import { SLOT_OPTION } from './constants';
import { getSlotsDataFromTemplate, makeInternalId } from './services/utils';

export const mapConfiguration = (data: ConfigurationType) =>
  ({
    config: JSON.parse(data.config),
  } as ConfigurationType);

export const mapDeployment = (data: DeploymentType) =>
  ({
    ...pick(
      [
        'name',
        'is_active',
        'language_customization_id_a',
        'language_customization_id_b',
        'timeformat',
        'record_sync_stt',
        'active_language_customization',
        'available_licenses',
        'used_licenses',
        'sso_configured',
      ],
      data
    ),
    instance_id: String(data.instance_id),
  } as DeploymentType);

export const mapInstance = (data: OrganizationInstanceType) =>
  pick(
    ['name', 'is_active', 'instance_url', 'login_url'],
    data
  ) as OrganizationInstanceType;

export const mapOrganization = (data: OrganizationType) =>
  ({
    ...pick(['name', 'is_active', 'used_licenses'], data),
    available_licenses: data.available_licenses || '0',
  } as OrganizationType);

export const mapOrganizationUser = (data: OrganizationUserType) =>
  ({
    ...pick(
      ['first_name', 'last_name', 'email', 'profile_image_url', 'is_active'],
      data
    ),
    deployment_ids: (data.deployment_rollio_users || []).map(({ deployment }) =>
      String(deployment.id)
    ),
  } as OrganizationUserType);

export const mapFlow = (data: FlowType) =>
  ({
    ...pick(['id', 'name', 'intent_id', 'object_id'], data),
    deployment_id: String(data.deployment_id),
  } as FlowType);

export const mapTemplate = (template: TemplateType) => {
  return {
    ...pick(['id', 'order', 'text'], template),
  };
};

export const mapSlot = (slot: SlotType) => {
  const type =
    slot.type === 'Decision Point'
      ? SLOT_OPTION.DECISION_POINT
      : SLOT_OPTION.PROMPT;

  return {
    ...pick(['id', 'node_path', 'name', 'detail'], slot),
    type,
    field_id: slot.slot_field?.field_id,
    ...(type === SLOT_OPTION.DECISION_POINT && {
      condition_true: slot.condition_true?.map(mapSlot),
      condition_false: slot.condition_false?.map(mapSlot),
    }),
    internal_id: makeInternalId(),
  };
};

export const formatDeployment = (data: DeploymentType) =>
  omit(['used_licenses'], data);

export const formatOrganization = (data: OrganizationType) =>
  omit(['available_licenses', 'used_licenses'], data);

export const formatFlow = (data: FlowType, fields: any[]) => {
  const slotsData = getSlotsDataFromTemplate(data.template);

  return {
    ...pick(['name', 'deployment_id', 'intent_id', 'object_id'], data),
    templates: [{ order: 0, text: data.template }],
    slots: slotsData.map(({ id, name }, i) => {
      const field = fields.find((f) => f.api_name === id);

      return {
        type: SLOT_OPTION.PROMPT,
        node_path: String(i + 1),
        name,
        detail: {
          configuration: {
            type: field?.type,
            name,
            api: id,
            promptOrder: i,
            defaultValue: '',
            prompts: [`What is the ${name}?`],
          },
          entity: {},
        } as Rollio.Field,
        field_id: field?.id,
      };
    }),
  };
};

export const formatExistFlow = (data: FlowType) => {
  return {
    ...pick(
      [
        'id',
        'name',
        'deployment_id',
        'intent_id',
        'object_id',
        'templates',
        'slots',
      ],
      data
    ),
  };
};

export const formatSttReport = (data: STTReportType) => {
  return {
    score: data.score || null,
  };
};
