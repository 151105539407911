import React from 'react';
import styled from 'styled-components';

import { DeleteButton } from 'src/components/Buttons';
import { InstanceForm, InstanceFormProps } from 'src/components/Forms';

import { Modal, ModalProps } from './Modal';

interface Props extends Omit<ModalProps, 'children' | 'small'> {
  form: InstanceFormProps;
  onDelete?: () => void;
}

const StyledDeleteButton = styled(DeleteButton)`
  position: absolute;
  bottom: 0;
`;

export const InstanceModal: React.FC<Props> = ({
  form,
  onDelete,
  ...props
}) => (
  <Modal {...props}>
    <InstanceForm {...form} />
    {form.initialData && onDelete && <StyledDeleteButton onClick={onDelete} />}
  </Modal>
);
