import React from 'react';
import styled, { css } from 'styled-components';
import { colors, fonts } from '@rollioforce/rollio-ui';

import { formatDate } from 'src/services/utils';

import { Footer } from './Footer';

interface TextMessageProps extends Pick<Messages.TextMessage, 'text'> {
  createdAt: string;
  isBot: boolean;
}

const BubbleOuter = styled.div`
  align-items: flex-end;
  max-width: 86%;
`;

const BubbleWrap = styled.div`
  padding: 9px 20px 9px 18px;

  ${({ isBot }) => {
    if (!isBot) {
      return css`
        background: ${colors.primary};
        border-bottom-left-radius: 24px;
        border-bottom-right-radius: 4px;
        border-top-left-radius: 24px;
        border-top-right-radius: 20px;
      `;
    }

    return css`
      background: ${colors.greyDark};
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 24px;
      border-top-left-radius: 4px;
      border-top-right-radius: 24px;
    `;
  }}
`;

const BubbleText = styled.div.attrs({ selectable: true })`
  color: ${({ color }) => color};
  font-family: ${fonts.default};
  font-size: 15px;
  line-height: 20px;
  text-align: left;
`;

export const TextMessage: React.FC<TextMessageProps> = ({
  createdAt,
  isBot,
  text,
}) => (
  <BubbleOuter>
    <BubbleWrap isBot={isBot}>
      <BubbleText color="white">{text}</BubbleText>
    </BubbleWrap>
    <Footer isBot={isBot} read showStatus time={formatDate(createdAt)} />
  </BubbleOuter>
);
