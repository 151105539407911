import React, { useState, useCallback } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { Button } from '@rollioforce/rollio-ui';
import { LoadingIndicator } from '@rollioforce/rollio-admin-ui';

import { GenerateSTTReportModal, STTReportModal } from 'src/components/Modals';
import { Permission } from 'src/components/Permission';
import { STTReportingTable } from 'src/components/Tables';
import { useSTTReportsData } from 'src/hooks';
import { ACTION, RESOURCE } from 'src/constants';
import { formatSttReport } from 'src/parser';

const TopTable = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  margin-bottom: 10px;
`;

const GenerateReportButton = styled(Button)`
  margin-left: 10px;
`;

export const STTReporting = ({ deploymentId }) => {
  const [isGenerateReportModalOpen, setIsGenerateReportModalOpen] = useState(
    false
  );
  const [isSTTReportModalOpen, setIsSTTReportModalOpen] = useState(false);
  const [sTTReport, setSTTReport] = useState<STTReportType>();
  const [formErrors, setFormErrors] = useState<Record<string, string>>({});
  const [searchParams] = useState<SearchParams>();

  const {
    isMutating,
    isLoading,
    sttData,
    generateReport,
    editReport,
    isLoadingFetcher: isGeneratingReport,
    refresh,
  } = useSTTReportsData(deploymentId, searchParams);

  const onGenerateSTTReportFormSubmit = useCallback(
    (values) => {
      const { date_from, date_to } = values;
      generateReport(date_from, date_to);
    },
    [generateReport]
  );

  const onEditSTTReport = useCallback(async (data) => {
    setIsSTTReportModalOpen(true);
    setSTTReport(data);
  }, []);

  const onFormSubmit = useCallback(
    async (data: STTReportType) => {
      try {
        const res = await editReport(data.id, formatSttReport(data));

        if (res && res.status === 'error') {
          if (res.errors) {
            return setFormErrors(res.errors);
          }
          return toast(res.message, { type: 'error' });
        }

        setIsSTTReportModalOpen(false);
        refresh();
      } catch (e) {
        toast(e.message, { type: 'error' });
      }
    },
    [editReport, refresh]
  );

  return (
    <>
      <TopTable>
        <Permission resource={RESOURCE.SF_USER} action={ACTION.READ}>
          <GenerateReportButton
            background="greyDark"
            onClick={() => setIsGenerateReportModalOpen(true)}
          >
            Generate report
          </GenerateReportButton>

          <GenerateSTTReportModal
            form={{
              onSubmit: onGenerateSTTReportFormSubmit,
            }}
            isOpen={isGenerateReportModalOpen}
            onCloseClick={() => setIsGenerateReportModalOpen(false)}
            title="Generate Report"
          />
        </Permission>
      </TopTable>
      <Permission resource={RESOURCE.SF_USER} action={ACTION.READ}>
        <STTReportingTable data={sttData} onEditSTTReport={onEditSTTReport} />
      </Permission>

      <LoadingIndicator
        isLoading={isLoading || isMutating || isGeneratingReport}
      />

      {sTTReport && (
        <STTReportModal
          isOpen={isSTTReportModalOpen}
          onCloseClick={() => setIsSTTReportModalOpen(false)}
          title={`Edit STT Report ID ${sTTReport.id}`}
          form={{
            errors: formErrors,
            initialData: sTTReport,
            isSubmitting: isMutating,
            onSubmit: onFormSubmit,
          }}
        />
      )}
    </>
  );
};
