import { useMemo } from 'react';

import { INSTANCES_URL } from 'src/constants';

import { useData } from './utils';

export const useInstanceObjectData = (instanceId: string) => {
  const key = useMemo(
    () => (instanceId ? `${INSTANCES_URL}/${instanceId}/objects` : null),
    [instanceId]
  );
  const { data: { data: objects = null } = {}, ...data } = useData<
    { data: any[] },
    any
  >(key);

  return {
    ...data,
    objects,
  };
};
