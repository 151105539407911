import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { colors, Icon } from '@rollioforce/rollio-ui';

import { SLOT_OPTION } from 'src/constants';
import { Slot } from 'src/components/Slot';
import { getSlotsDataFromTemplate } from 'src/services/utils';

interface SlotsFieldProps extends React.HTMLAttributes<HTMLDivElement> {
  label: string;
  template: string;
  fields?: any[];
}

const Field = styled.div``;

const LabelSlots = styled.div`
  color: ${colors.greyLight};
  font-size: 12px;
  margin-bottom: 10px;
`;

const SlotsContainer = styled.div`
  max-height: 200px;
  overflow: auto;
`;

const EmptySlot = styled.div`
  display: flex;
  flex-direction: row;
  background: ${colors.offWhite2};
  padding: 10px;
  border-radius: 4px;
  min-width: 200px;
`;

const EmptyLabel = styled.span`
  flex: 1;
  color: ${colors.greyLight};
  font-size: 14px;
  font-weight: 500;
  padding-left: 10px;
`;

const StyledSlot = styled(Slot)`
  margin-bottom: 10px;
`;

type SlotTypeWithApiName = SlotType & { api_name: string };

export const SlotsField: React.FC<SlotsFieldProps> = ({
  label,
  template,
  fields,
  ...props
}) => {
  const [slots, setSlots] = useState<SlotTypeWithApiName[]>([]);

  useEffect(() => {
    if (template) {
      const slotsData = getSlotsDataFromTemplate(template);
      const newSlots = slotsData.map(({ id, name }, i) => ({
        type: SLOT_OPTION.PROMPT,
        node_path: String(i + 1),
        name,
        api_name: id,
      }));
      setSlots(newSlots);
    }
  }, [template]);

  const requiredFields = useMemo(
    () => fields?.filter((x) => x.required) || [],
    [fields]
  );

  const pendingSlots = useMemo(() => {
    const slotsIds = slots.map((slot) => slot.api_name);

    return requiredFields
      .filter((x) => !slotsIds.includes(x.api_name))
      .map((x) => ({
        type: SLOT_OPTION.PROMPT,
        name: x.label,
        api_name: x.api_name,
      }));
  }, [requiredFields, slots]);

  const allSlots = useMemo(() => [...pendingSlots, ...slots], [
    pendingSlots,
    slots,
  ]);

  return (
    <Field {...props}>
      <LabelSlots>{label}</LabelSlots>
      <SlotsContainer>
        {allSlots.length ? (
          <>
            {pendingSlots.map((pendingSlot) => (
              <StyledSlot
                key={pendingSlot.api_name}
                slot={pendingSlot}
                isRequired
              />
            ))}
            {slots.map((slot) => {
              const isRequired = requiredFields.some(
                (x) => x.api_name === slot.api_name
              );

              return (
                <StyledSlot
                  key={slot.api_name}
                  slot={slot}
                  isActive
                  isRequired={isRequired}
                />
              );
            })}
          </>
        ) : (
          <EmptySlot>
            <Icon name="alert" color="greyLight" size="small" />
            <EmptyLabel>
              Slots will appear here while entering a Conversation Template.
            </EmptyLabel>
          </EmptySlot>
        )}
      </SlotsContainer>
    </Field>
  );
};
