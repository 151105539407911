import { useCallback, useState, useMemo, useContext } from 'react';
import { toast } from 'react-toastify';
import { navigate } from '@reach/router';
import * as Sentry from '@sentry/react';

import { useDispatch } from 'src/hooks/useDispatch';
import { setAuthToken } from 'src/store/actions';
import { auth, utils } from 'src/services';
import { getRouteUrlById, ROUTES } from 'src/routes';
import { FirebaseContext } from 'src/FirebaseProvider';

import { useStore } from './useStore';
import { useSfAuth } from './useSfAuth';

export const useAuth = () => {
  const dispatch = useDispatch();
  const { authToken } = useStore();
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [loginError, setLoginError] = useState('');
  const { auth: firebaseAuth } = useContext(FirebaseContext);
  const { sfLogout } = useSfAuth();

  const isLoggedIn = useMemo(() => !!authToken, [authToken]);

  const updateSentryUser = useCallback((authToken: string) => {
    const profile = utils.getProfileFromToken(authToken);
    Sentry.configureScope((scope) => {
      scope.setUser(
        profile
          ? {
              userId: profile.id,
              organizationId: profile.organization_id,
              deploymentId: profile.deployment_id,
            }
          : null
      );
    });
  }, []);

  const updateToken = useCallback(
    (newAuthToken) => {
      dispatch(setAuthToken(newAuthToken));
      updateSentryUser(newAuthToken);
    },
    [dispatch, updateSentryUser]
  );

  const logout = useCallback(async () => {
    try {
      await firebaseAuth.signOut();
      updateToken(null);
      sfLogout();
      navigate(getRouteUrlById(ROUTES.login));
    } catch (e) {
      toast(e.message, { type: 'error' });
    }
  }, [updateToken, sfLogout, firebaseAuth]);

  const login = useCallback(
    async (username, password) => {
      setLoginError('');

      if (!username?.trim()) {
        setLoginError('Invalid username');
        return;
      }

      setIsLoggingIn(true);
      try {
        const { user } = await firebaseAuth.signInWithEmailAndPassword(
          username,
          password
        );
        const token = await user.getIdToken(true);
        const authResponse = await auth.login(token, user.refreshToken);
        if (authResponse.success) {
          updateToken(authResponse.token);
        }
        setLoginError(authResponse.message);
      } catch (e) {
        setLoginError(e.message);
      } finally {
        setIsLoggingIn(false);
      }
    },
    [firebaseAuth, updateToken]
  );

  return {
    isLoggedIn,
    isLoggingIn,
    login,
    logout,
    updateToken,
    authToken,
    loginError,
  };
};
