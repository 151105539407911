import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';
import { LoadingIndicator } from '@rollioforce/rollio-admin-ui';

import { useConfigurationData, useStore } from 'src/hooks';
import { Container } from 'src/components/Container';
import {
  ConfigurationForm,
  DEFAULT_CONFIGURATION_FORM,
} from 'src/components/Forms';
import { CONFIGURATION_TYPE } from 'src/constants';
import { mapConfiguration } from 'src/parser';

import { ActiveDeployment } from '../ActiveDeployment';

interface Props {
  type?: CONFIGURATION_TYPE;
}

export const ConfigurationDetail: React.FC<Props> = ({ type }) => {
  const [formErrors, setFormErrors] = useState<Record<string, string>>({});
  const [isFormDirty, setIsFormDirty] = useState<boolean>(false);
  const { activeDeploymentId } = useStore();

  const {
    configuration,
    isLoading,
    isMutating,
    addRecord,
  } = useConfigurationData(activeDeploymentId, type);

  const initialFormState = useMemo(
    () =>
      configuration
        ? mapConfiguration(configuration)
        : DEFAULT_CONFIGURATION_FORM,

    [configuration]
  );

  useEffect(() => {
    setIsFormDirty(false);
  }, [activeDeploymentId, configuration]);

  const onFormSubmit = useCallback(
    async (data: ConfigurationType) => {
      try {
        const res = await addRecord(data, { doNotMutate: true });

        if (res && res.status === 'error') {
          if (res.message) {
            toast(res.message, { type: 'error' });
          }
          return setFormErrors(res.errors);
        }
        toast('Configuration updated successfully', { type: 'success' });
        setIsFormDirty(false);
      } catch (error) {
        toast(error.message, { type: 'error' });
      }
    },
    [addRecord]
  );

  const onFormChange = useCallback(
    (data: any) => {
      const isDirty = !!data.config && data.config !== initialFormState.config;
      setIsFormDirty(isDirty);
    },
    [initialFormState]
  );

  return (
    <>
      <ActiveDeployment askForConfirmation={isFormDirty} />

      <Container>
        <ConfigurationForm
          errors={formErrors}
          initialData={initialFormState}
          isSubmitting={isMutating}
          onSubmit={onFormSubmit}
          onChange={onFormChange}
        />
      </Container>

      <LoadingIndicator isLoading={isLoading || isMutating} />
    </>
  );
};
