import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { navigate } from '@reach/router';
import { Select } from '@rollioforce/rollio-ui';

import { getRouteUrlById, ROUTES } from 'src/routes';
import {
  useDeploymentsData,
  useDispatch,
  usePermission,
  useStore,
  useUserProfile,
} from 'src/hooks';
import { setActiveDeploymentId } from 'src/store/actions';
import { ConfirmModal } from 'src/components/Modals';

interface Props {
  askForConfirmation?: boolean;
  disabled?: boolean;
}

const StyledSelect = styled(Select)`
  margin-bottom: 20px;
`;

export const ActiveDeployment: React.FC<Props> = ({
  askForConfirmation,
  disabled,
}) => {
  const dispatch = useDispatch();
  const { activeDeploymentId, activeInstance } = useStore();

  const { deployments } = useDeploymentsData();
  const { isInternal } = usePermission();
  const userProfile = useUserProfile();
  const [activeDeployment, setActiveDeployment] = useState<{
    label: string;
    value: string;
  }>();
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState<boolean>(
    false
  );
  const [tmpOption, setTmpOption] = useState<any>();

  const changeActiveDeployment = useCallback(
    (option) => {
      if (option.value !== '0') {
        setActiveDeployment(option);
        dispatch(setActiveDeploymentId(option.value));
      } else {
        navigate(
          userProfile.organization_id
            ? getRouteUrlById(ROUTES.organizationDetail, {
                organizationId: userProfile.organization_id,
              })
            : getRouteUrlById(ROUTES.organizations)
        );
      }
    },
    [dispatch, userProfile]
  );

  const onActiveDeploymentChange = useCallback(
    (option) => {
      if (askForConfirmation) {
        setTmpOption(option);
        setIsConfirmModalVisible(true);
      } else {
        changeActiveDeployment(option);
      }
    },
    [askForConfirmation, changeActiveDeployment]
  );

  const onSwitchDeploymentConfirm = useCallback(() => {
    setIsConfirmModalVisible(false);
    changeActiveDeployment(tmpOption);
  }, [changeActiveDeployment, tmpOption]);

  const mapOption = useCallback(
    (d) => ({
      label: isInternal ? `${d.name} [${d.id}]` : d.name,
      value: String(d.id),
    }),
    [isInternal]
  );

  const options = useMemo(
    () =>
      deployments
        .filter(
          (dep) => Number(dep.instance_id) === Number(activeInstance?.value)
        )
        .map(mapOption),
    [deployments, mapOption, activeInstance]
  );

  useEffect(() => {
    const deploymentId = options?.find(
      (d) => d.value === activeDeployment?.value
    );

    if ((!activeDeployment || !deploymentId) && options.length) {
      const initialDeployment =
        options.find((d) => d.value === activeDeploymentId) || options[0];

      setActiveDeployment(initialDeployment);
      dispatch(setActiveDeploymentId(initialDeployment.value));
    }
  }, [activeDeployment, activeDeploymentId, options, dispatch, activeInstance]);

  return (
    <>
      <StyledSelect
        disabled={disabled}
        label="Active Deployment"
        onChange={onActiveDeploymentChange}
        options={options}
        value={activeDeployment}
      />
      <ConfirmModal
        isOpen={isConfirmModalVisible}
        message="You have unsaved changes, are you sure you wish to switch deployments?"
        onCloseClick={() => setIsConfirmModalVisible(false)}
        onConfirm={onSwitchDeploymentConfirm}
        small
        title="Unsaved Changes"
      />
    </>
  );
};
