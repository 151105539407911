import React from 'react';
import styled from 'styled-components';
import { colors, fonts, Icon } from '@rollioforce/rollio-ui';

interface FooterProps {
  isBot: boolean;
  read?: boolean;
  showStatus?: boolean;
  time?: string;
}

const FooterWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${({ isBot }) => (!isBot ? 'flex-end' : 'flex-start')};
  align-items: center;
  margin-top: 2px;
`;

const FooterTime = styled.div`
  color: ${colors.greyLight};
  font-family: ${fonts.default};
  font-size: 10px;
`;

const ReadStatus = styled.div`
  margin-left: 5px;
`;

export const Footer: React.FC<FooterProps> = ({
  isBot,
  read,
  showStatus,
  time,
}) => {
  if (!time && !showStatus) {
    return null;
  }
  return (
    <FooterWrap isBot={isBot}>
      {time && <FooterTime>{time}</FooterTime>}

      {showStatus && (
        <ReadStatus read={read}>
          <Icon
            color={read ? 'greyDark' : 'greyLight'}
            name="check"
            size="xsmall"
          />
        </ReadStatus>
      )}
    </FooterWrap>
  );
};
