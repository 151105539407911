import React from 'react';
import styled from 'styled-components';

import { DeleteButton } from 'src/components/Buttons';
import { UserForm, UserFormProps } from 'src/components/Forms';
import { Permission } from 'src/components/Permission';
import { ACTION, RESOURCE } from 'src/constants';

import { Modal, ModalProps } from './Modal';

interface Props extends Omit<ModalProps, 'children' | 'small'> {
  form: UserFormProps;
  onDelete?: () => void;
}

const StyledDeleteButton = styled(DeleteButton)`
  position: absolute;
  bottom: 0;
`;

export const UserModal: React.FC<Props> = ({ form, onDelete, ...props }) => (
  <Modal {...props}>
    <UserForm {...form} />
    {form.initialData && onDelete && (
      <Permission resource={RESOURCE.ROLLIO_USER} action={ACTION.DELETE}>
        <StyledDeleteButton onClick={onDelete} />
      </Permission>
    )}
  </Modal>
);
