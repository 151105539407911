import { useMemo } from 'react';

import { DEPLOYMENTS_URL } from 'src/constants';

import { useData } from './utils';

export const useConversationsData = (
  deploymentId: string,
  sfUserId: string,
  pagination: PaginationType
) => {
  const { limit, offset } = pagination;
  const key = useMemo(
    () =>
      `${DEPLOYMENTS_URL}/${deploymentId}/conversations/${sfUserId}?per_page=${limit}&offset=${offset}`,
    [deploymentId, sfUserId, limit, offset]
  );

  const {
    data: { data: conversations = [], total = 0 } = {},
    ...data
  } = useData<{ data: ConversationType[]; total: number }, ConversationType>(
    key
  );

  return {
    ...data,
    totalItems: total,
    conversations,
  };
};
