import React from 'react';
import { Icon, TableLabel } from '@rollioforce/rollio-ui';

interface Props {
  value?: boolean;
}

export const TableBitCell: React.FC<Props> = ({ value }) => (
  <TableLabel>
    <Icon
      name={value ? 'check' : 'close'}
      color={value ? 'primary' : 'red'}
      size="small"
    />
  </TableLabel>
);
