import React, { useMemo } from 'react';
import styled from 'styled-components';
import { debounce } from 'lodash';
import { Form, FormFields, FormProps } from '@rollioforce/rollio-admin-ui';

export interface SearchFormProps extends Partial<FormProps> {
  initialData?: SearchParams;
  inputLabel?: string;
  onSubmit: (formDetails?: SearchParams) => void;
}

const StyledForm = styled(Form)`
  flex: 1;
`;

export const SearchForm: React.FC<SearchFormProps> = ({
  inputLabel,
  fields,
  onSubmit,
  ...props
}) => {
  const onFieldsChange = useMemo(
    () =>
      debounce((data) => {
        const doSubmit = Object.keys(data).length;
        if (doSubmit) onSubmit(data);
      }, 500),
    [onSubmit]
  );

  const rowFields: FormFields = useMemo(
    () => [
      {
        key: 'text',
        type: 'input',
        label: inputLabel,
        fieldProps: {
          icon: 'search',
        },
      },
      ...(fields || []),
    ],
    [inputLabel, fields]
  );

  return (
    <StyledForm
      {...props}
      fields={[
        {
          key: 'searchRow',
          type: 'row',
          fields: rowFields,
        },
      ]}
      onChange={onFieldsChange}
      onSubmit={onSubmit}
      showSave={false}
    />
  );
};
