import React, { useMemo } from 'react';
import { Form, FormFields, FormProps } from '@rollioforce/rollio-admin-ui';
import { organization } from '@rollioforce/rollio-schemas';

import { usePermission } from 'src/hooks';
import { ROLE } from 'src/constants';

export interface OrganizationFormProps extends Partial<FormProps> {
  initialData?: OrganizationType;
  onSubmit: (formDetails: OrganizationType) => void;
}

export const DEFAULT_ORGANIZATION_FORM: OrganizationType = {
  name: '',
  is_active: 1,
  available_licenses: null,
  used_licenses: null,
};

export const OrganizationForm: React.FC<OrganizationFormProps> = ({
  initialData,
  ...props
}) => {
  const { checkRole } = usePermission();

  const formData = useMemo(() => {
    return {
      ...DEFAULT_ORGANIZATION_FORM,
      ...initialData,
    };
  }, [initialData]);

  const isReadOnly = useMemo(() => checkRole(ROLE.DEPLOYMENT), [checkRole]);
  const isFieldsVisible = useMemo(() => checkRole(ROLE.INTERNAL), [checkRole]);

  const isEditing = useMemo(() => !!initialData, [initialData]);

  const FORM_FIELDS: FormFields = useMemo(
    () => [
      {
        key: 'topRow',
        type: 'row',
        fields: [
          {
            key: 'name',
            label: 'Name',
            type: 'input',
            validate: 'onChange',
          },
          {
            key: 'is_active',
            label: 'Active',
            type: 'checkbox',
            validate: 'onChange',
            isVisible: isFieldsVisible,
            valueFormat: (v) => v === 1,
            onUpdateFormat: (v) => (v ? 1 : 0),
          },
        ],
        style: { alignItems: 'center' },
      },
      {
        key: 'licenses',
        type: 'row',
        isVisible: isEditing,
        fields: [
          {
            key: 'available_licenses',
            label: 'Available Licenses',
            type: 'input',
            fieldProps: {
              disabled: true,
            },
          },
          {
            key: 'used_licenses',
            label: 'Used Licenses',
            type: 'input',
            fieldProps: {
              disabled: true,
            },
          },
        ],
      },
    ],
    [isFieldsVisible, isEditing]
  );

  return (
    <Form
      {...props}
      fields={FORM_FIELDS}
      enableReinitialize
      initialData={formData}
      schema={organization}
      isReadOnly={isReadOnly}
    />
  );
};
