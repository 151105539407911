import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { Button } from '@rollioforce/rollio-ui';
import { LoadingIndicator } from '@rollioforce/rollio-admin-ui';

import { SearchForm } from 'src/components/Forms';
import { DeploymentModal } from 'src/components/Modals';
import { Permission } from 'src/components/Permission';
import { DeploymentsTable } from 'src/components/Tables';
import {
  useDeleteModal,
  useDeploymentData,
  useInstanceDeploymentsData,
} from 'src/hooks';
import { ACTION, RESOURCE } from 'src/constants';
import { formatDeployment, mapDeployment } from 'src/parser';

const TopTable = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  margin-bottom: 10px;
`;

const AddNewButton = styled(Button)`
  margin-left: 10px;
`;

export const Deployments = ({ instanceId, organizationId = null }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formErrors, setFormErrors] = useState<Record<string, string>>({});
  const [currentId, setCurrentId] = useState(null);
  const [currentDeployment, setCurrentDeployment] = useState(null);
  const [searchParams, setSearchParams] = useState<SearchParams>();

  const {
    deployments,
    isLoading,
    isMutating,
    addRecord,
    refresh: refreshDeployments,
  } = useInstanceDeploymentsData(instanceId, searchParams);
  const {
    deployment,
    isLoading: isDeploymentLoading,
    isMutating: isDeploymentMutating,
    editRecord,
    deleteRecord,
  } = useDeploymentData(currentId);
  const { isLoading: isDeleteLoading, openDeleteModal } = useDeleteModal();

  useEffect(() => {
    if (deployment) {
      setIsModalOpen(true);
      setCurrentDeployment(mapDeployment(deployment));
    }
  }, [deployment]);

  useEffect(() => {
    if (!isModalOpen) {
      setCurrentId(null);
      setCurrentDeployment(null);
      setFormErrors({});
    }
  }, [isModalOpen]);

  const onFormSubmit = useCallback(
    async (data: DeploymentType) => {
      const action = currentId ? editRecord : addRecord;
      try {
        const res = await action(
          formatDeployment({ ...data, organization_id: organizationId })
        );

        if (res && res.status === 'error') {
          if (res.errors) {
            return setFormErrors(res.errors);
          }
          return toast(res.message, { type: 'error' });
        }

        setIsModalOpen(false);
        refreshDeployments();
      } catch (e) {
        toast(e.message, { type: 'error' });
      }
    },
    [currentId, organizationId, editRecord, addRecord, refreshDeployments]
  );

  const onDeleteClick = useCallback(async () => {
    openDeleteModal({
      data: currentDeployment,
      type: 'deployment',
      action: deleteRecord,
      callback: () => {
        setIsModalOpen(false);
        refreshDeployments();
      },
    });
  }, [currentDeployment, deleteRecord, openDeleteModal, refreshDeployments]);

  const isSubmitting = useMemo(
    () => (isMutating || isDeploymentMutating) && !isDeleteLoading,
    [isMutating, isDeploymentMutating, isDeleteLoading]
  );

  return (
    <>
      <TopTable>
        <Permission resource={RESOURCE.DEPLOYMENT} action={ACTION.READ}>
          <SearchForm
            initialData={searchParams}
            inputLabel="Search deployments by name"
            onSubmit={setSearchParams}
          />
        </Permission>
        <Permission resource={RESOURCE.DEPLOYMENT} action={ACTION.CREATE}>
          <AddNewButton
            background="greyDark"
            onClick={() => setIsModalOpen(true)}
          >
            Add new
          </AddNewButton>
          <DeploymentModal
            form={{
              errors: formErrors,
              initialData: currentDeployment,
              isSubmitting,
              onSubmit: onFormSubmit,
              organizationId,
              instanceId,
            }}
            isOpen={isModalOpen}
            onCloseClick={() => setIsModalOpen(false)}
            onDelete={onDeleteClick}
            title={
              !currentDeployment ? 'Add New Deployment' : 'Edit Deployment'
            }
          />
        </Permission>
      </TopTable>
      <Permission resource={RESOURCE.DEPLOYMENT} action={ACTION.READ}>
        <DeploymentsTable
          data={deployments}
          organizationId={organizationId}
          hiddenColumns={['instance.name']}
        />
      </Permission>

      <LoadingIndicator isLoading={isLoading || isDeploymentLoading} />
    </>
  );
};
