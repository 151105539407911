import React from 'react';
import { RouteComponentProps } from '@reach/router';

import { DeploymentDetail } from 'src/layouts/Deployment';
import { PageWrap } from 'src/components/PageWrap';

interface Props extends RouteComponentProps {
  deploymentId?: string;
}

export const Deployment: React.FC<Props> = ({ deploymentId }) => (
  <PageWrap>
    <DeploymentDetail deploymentId={deploymentId} />
  </PageWrap>
);
