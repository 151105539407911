import { LoadingIndicator } from '@rollioforce/rollio-admin-ui';
import React from 'react';

import { useMessagesData } from 'src/hooks';

import { MessageList } from '../MessageList';
import { Modal, ModalProps } from './Modal';

interface Props extends Omit<ModalProps, 'children' | 'small'> {
  deploymentId: string;
  conversationId: string;
  sfUserId: string;
}

export const ConversationModal: React.FC<Props> = ({
  deploymentId,
  conversationId,
  sfUserId,
  ...props
}) => {
  const { messages, isLoading } = useMessagesData(deploymentId,
    conversationId, sfUserId);

  return (
    <Modal small {...props}>
      <LoadingIndicator isLoading={isLoading} />
      <MessageList messages={messages} />
    </Modal>)
};
