import { pathToRegexp } from 'path-to-regexp';
import { IconName } from '@rollioforce/rollio-ui/dist/types/components/Icon';
import { RouteComponentProps } from '@reach/router';

import { ACTION, RESOURCE, ROLE } from 'src/constants';
import {
  Configuration,
  Configurations,
  Dashboard,
  Data,
  Deployment,
  Flow,
  Flows,
  Instance,
  Login,
  MyOrganization,
  Organization,
  Organizations,
} from 'src/pages';

import { OrganizationName, DeploymentName } from './Breadcrumbs';

interface MatchParam {
  match: boolean;
  url: string;
  params: Record<string, any>;
}

interface MatchParamAll extends MatchParam {
  exactMatch: boolean;
}

export interface Route {
  path: string;
  id: string;
  label: string;
  LabelComponent?: React.FC<any>;
  Component?: React.FC<RouteComponentProps>;
  icon?: IconName;
  roles?: ROLE[];
  permission?: { resource: RESOURCE; action: ACTION };
}

export const ROUTES = {
  home: 'home',
  login: 'login',
  organizations: 'organizations',
  organizationDetail: 'organizationDetail',
  data: 'data',
  deploymentDetail: 'deploymentDetail',
  organization: 'organization',
  deployment: 'deployment',
  configurations: 'configurations',
  configurationDetail: 'configurationDetail',
  instance: 'instance',
  instanceDetail: 'instanceDetail',
  flows: 'flows',
  flowDetail: 'flowDetail',
};

export const routes: Route[] = [
  {
    path: '/',
    id: ROUTES.home,
    label: 'Dashboard',
    Component: Dashboard,
    icon: 'dashboard',
  },
  { path: '/login', id: ROUTES.login, label: 'Login', Component: Login },
  {
    path: '/organizations',
    id: ROUTES.organizations,
    label: 'Organizations',
    Component: Organizations,
    icon: 'users',
    roles: [ROLE.INTERNAL],
    permission: { resource: RESOURCE.ORGANIZATION, action: ACTION.READ },
  },
  {
    path: '/organizations/:organizationId',
    id: ROUTES.organizationDetail,
    label: 'Organization',
    LabelComponent: OrganizationName,
    Component: Organization,
    roles: [ROLE.INTERNAL],
    permission: { resource: RESOURCE.ORGANIZATION, action: ACTION.READ },
  },
  {
    path: '/organizations/:organizationId/deployment/:deploymentId',
    id: ROUTES.deploymentDetail,
    label: 'Deployment',
    LabelComponent: DeploymentName,
    Component: Deployment,
    roles: [ROLE.INTERNAL],
    permission: { resource: RESOURCE.DEPLOYMENT, action: ACTION.READ },
  },
  {
    path: '/my-organization',
    id: ROUTES.organization,
    label: 'Organization',
    Component: MyOrganization,
    icon: 'users',
    roles: [ROLE.ORGANIZATION, ROLE.DEPLOYMENT],
    permission: { resource: RESOURCE.ORGANIZATION, action: ACTION.READ },
  },
  {
    path: '/my-organization/deployment/:deploymentId',
    id: ROUTES.deployment,
    label: 'Deployment',
    LabelComponent: DeploymentName,
    Component: Deployment,
    roles: [ROLE.ORGANIZATION, ROLE.DEPLOYMENT],
    permission: { resource: RESOURCE.DEPLOYMENT, action: ACTION.READ },
  },
  {
    path: '/configurations',
    id: ROUTES.configurations,
    label: 'Configurations',
    Component: Configurations,
    icon: 'memory',
    roles: [ROLE.INTERNAL],
    permission: { resource: RESOURCE.DEPLOYMENT, action: ACTION.READ },
  },
  {
    path: '/configurations/:type',
    id: ROUTES.configurationDetail,
    label: 'Configuration',
    LabelComponent: ({ type }) => type.toUpperCase(),
    Component: Configuration,
    icon: 'memory',
    permission: { resource: RESOURCE.DEPLOYMENT, action: ACTION.READ },
  },
  {
    path: '/data',
    id: ROUTES.data,
    label: 'Data',
    Component: Data,
    icon: 'plug',
    permission: { resource: RESOURCE.DEPLOYMENT, action: ACTION.READ },
  },
  {
    path: '/my-organization/instance/:instanceId',
    id: ROUTES.instance,
    label: 'Instance',
    Component: Instance,
    roles: [ROLE.ORGANIZATION, ROLE.DEPLOYMENT],
  },
  {
    path: '/organizations/:organizationId/instance/:instanceId',
    id: ROUTES.instanceDetail,
    label: 'Instance',
    Component: Instance,
    roles: [ROLE.INTERNAL],
  },
  {
    path: '/flows',
    id: ROUTES.flows,
    label: 'Flows',
    Component: Flows,
    icon: 'flash',
  },
  {
    path: '/flows/:flowId',
    id: ROUTES.flowDetail,
    label: 'Flow',
    Component: Flow,
  },
];

export const getRouteById = (id: string): Route =>
  routes.find((route) => route.id === id);

export const getRouteUrlById = (
  id: string,
  params: Record<string, any> = {}
): string => {
  const { path } = getRouteById(id);
  return Object.keys(params).reduce(
    (acc, key) => acc.replace(`:${key}`, params[key]),
    path
  );
};

const matchUrlWithPathAll = (url: string, path: string): MatchParamAll => {
  const parts = url.split('/');
  const urlsToMatch = parts.map(
    (_part, index) => parts.slice(0, parts.length - index).join('/') || '/'
  );
  return urlsToMatch.reduce(
    (acc, urlToCheck) => {
      if (!acc.match) {
        const { match, params } = matchUrlWithPath(urlToCheck, path);
        acc = {
          match,
          url: urlToCheck,
          params,
          exactMatch: url === urlToCheck,
        };
      }
      return acc;
    },
    { match: false, url, params: {} } as MatchParamAll
  );
};

const matchUrlWithPath = (url: string, path: string): MatchParam => {
  const keys = []; //keys are populated by pathToRegexp
  const regexp = pathToRegexp(path, keys);
  const match = regexp.exec(url);

  if (!match) {
    return { match: false, url, params: {} };
  }

  const [matchUrl, ...values] = match;
  const params = keys.reduce(
    (acc, key, index) => ({ ...acc, [key.name]: values[index] }),
    {}
  );

  return { match: true, url: matchUrl, params };
};

interface RouteWithMatchParam
  extends Route,
    Pick<MatchParam, 'match' | 'params'> {}

export const getBreadcrumbs = (url: string): RouteWithMatchParam[] => {
  return routes.reduce((acc, route) => {
    const { path } = route;
    const { match, params, exactMatch } = matchUrlWithPathAll(url, path);
    return match ? [...acc, { ...route, match: exactMatch, params }] : acc;
  }, []);
};

export const getSidebarItems = (
  url: string,
  routeIds: string[]
): RouteWithMatchParam[] => {
  return routeIds.map((id) => {
    const route = getRouteById(id);
    const { match, params, exactMatch } = matchUrlWithPathAll(url, route.path);
    return {
      ...route,
      match: id === ROUTES.home ? exactMatch : match,
      params,
    };
  });
};

export const getRouteByUrl = (url: string) => {
  return routes.find((route) => {
    const { match } = matchUrlWithPath(url, route.path);
    return match;
  });
};
