import { useMemo } from 'react';

import { ORGANIZATIONS_URL } from 'src/constants';
import { useData } from './utils';

export const useUsersData = (organizationId: string, search?: SearchParams) => {
  const key = useMemo(() => `${ORGANIZATIONS_URL}/${organizationId}/users`, [
    organizationId,
  ]);

  const { data: { data: users = [] } = {}, ...data } = useData<
    { data: OrganizationUserType[] },
    OrganizationUserType
  >(key, search);

  return {
    ...data,
    users,
  };
};
