import { useMemo } from 'react';

import { ORGANIZATIONS_URL } from 'src/constants';

import { useData } from './utils';

export const useOrganizationData = (organizationId: string) => {
  const key = useMemo(() => `${ORGANIZATIONS_URL}/${organizationId}`, [
    organizationId,
  ]);

  const { data: { data: organization = {} } = {}, ...data } = useData<
    { data: OrganizationType },
    OrganizationType
  >(key);

  return {
    ...data,
    organization,
  };
};
