import React, { useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { Button, colors } from '@rollioforce/rollio-ui';
import { Form } from '@rollioforce/rollio-admin-ui';
import { FormRef } from '@rollioforce/rollio-admin-ui/dist/types/components/Form';
import { conversation } from '@rollioforce/rollio-schemas';

import { HighlightedText } from './HighlightedText';

export interface TemplateProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, 'slot'> {
  data: TemplateType;
  showActons?: boolean;
  fields?: any[];
  onDeleteTemplate: () => void;
  onChangeTemplate: (data: TemplateType) => void;
}

const TemplateContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px 0;
  border-bottom: 1px solid ${colors.offWhite};
`;

const TextContainer = styled.div`
  flex: 1;
  font-size: 14px;
  color: ${colors.greyDark};
  display: flex;
  align-items: center;
  padding-right: 10px;
`;

const ActionContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
`;

const StyledButton = styled(Button)`
  margin-left: 5px;
`;

export const Template: React.FC<TemplateProps> = ({
  data,
  showActons,
  fields,
  onDeleteTemplate,
  onChangeTemplate,
}) => {
  const formRef = useRef<FormRef>(null);
  const [isEditing, setIsEditing] = useState(!data.text);

  const AVAILABLE_FIELDS_DATA = useMemo(
    () =>
      fields?.map((i) => ({
        id: i.api_name,
        display: i.label,
      })),
    [fields]
  );

  const onStartTemplate = () => {};

  const onFormValidate = (isValid: boolean, values: any) => {
    if (isValid) {
      onChangeTemplate(values);
      setIsEditing(false);
    }
  };

  const onSaveTemplate = () => {
    formRef.current.validate([]); //TODO: Validate field ['text']
  };

  const onCloseTemplate = () => {
    if (!data.text) {
      onDeleteTemplate();
    }
    setIsEditing(false);
  };

  const regex = /@\[(.*?)\]/g;
  const removeTextRegex = /\((.*?)\)/g;

  return (
    <TemplateContainer>
      <TextContainer>
        {isEditing ? (
          <Form
            ref={formRef}
            fields={[
              {
                key: 'text',
                label: 'Conversation Template',
                type: 'textarea',
                fieldProps: {
                  style: { minWidth: 600 },
                  mentions: AVAILABLE_FIELDS_DATA,
                },
              },
            ]}
            initialData={data}
            showSave={false}
            onValidate={onFormValidate}
            schema={conversation}
          />
        ) : (
          <HighlightedText
            text={data.text}
            regex={regex}
            removeTextRegex={removeTextRegex}
          />
        )}
      </TextContainer>

      {showActons && (
        <ActionContainer>
          {!isEditing ? (
            <>
              <StyledButton
                icon="play"
                dark
                small
                background="offWhite2"
                onClick={onStartTemplate}
              />
              <StyledButton
                key="edit"
                icon="edit"
                dark
                small
                background="offWhite2"
                onClick={() => setIsEditing(true)}
              />
              <StyledButton
                icon="delete"
                dark
                small
                background="offWhite2"
                onClick={onDeleteTemplate}
              />
            </>
          ) : (
            <>
              <StyledButton
                key="edit"
                icon="check"
                small
                background="primary"
                onClick={onSaveTemplate}
              />
              <StyledButton
                icon="close"
                dark
                small
                background="offWhite2"
                onClick={onCloseTemplate}
              />
            </>
          )}
        </ActionContainer>
      )}
    </TemplateContainer>
  );
};
