import { FLOWS_URL } from 'src/constants';

import { useData } from './utils';

export const useFlowsData = (search?: SearchParams) => {
  const { data: { data: flows = [] } = {}, ...data } = useData<
    { data: FlowType[] },
    FlowType
  >(FLOWS_URL, search);

  return {
    ...data,
    flows,
  };
};
