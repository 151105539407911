import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { Button } from '@rollioforce/rollio-ui';
import { LoadingIndicator } from '@rollioforce/rollio-admin-ui';

import { SearchForm } from 'src/components/Forms';
import { DeploymentModal, GenerateReportModal } from 'src/components/Modals';
import { Permission } from 'src/components/Permission';
import { DeploymentsTable } from 'src/components/Tables';
import {
  useDeleteModal,
  useDeploymentData,
  useOrganizationDeploymentsData,
} from 'src/hooks';
import { ACTION, RESOURCE } from 'src/constants';
import { formatDeployment, mapDeployment } from 'src/parser';

const TopTable = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  margin-bottom: 10px;
`;

const AddNewButton = styled(Button)`
  margin-left: 10px;
`;

export const Deployments = ({ organizationId }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isGenerateReportModalOpen, setIsGenerateReportModalOpen] = useState(
    false
  );
  const [formErrors, setFormErrors] = useState<Record<string, string>>({});
  const [currentId, setCurrentId] = useState(null);
  const [currentDeployment, setCurrentDeployment] = useState(null);
  const [searchParams, setSearchParams] = useState<SearchParams>();
  const [selectedTableRows, setSelectedTableRows] = useState([]);

  const {
    deployments,
    isLoading,
    isMutating,
    addRecord,
    generateReport,
    refresh: refreshDeployments,
    isLoadingFetcher: isGeneratingReport,
  } = useOrganizationDeploymentsData(organizationId, searchParams);
  const {
    deployment,
    isLoading: isDeploymentLoading,
    isMutating: isDeploymentMutating,
    editRecord,
    deleteRecord,
  } = useDeploymentData(currentId);
  const { isLoading: isDeleteLoading, openDeleteModal } = useDeleteModal();

  useEffect(() => {
    if (deployment) {
      setIsModalOpen(true);
      setCurrentDeployment(mapDeployment(deployment));
    }
  }, [deployment]);

  useEffect(() => {
    if (!isModalOpen) {
      setCurrentId(null);
      setCurrentDeployment(null);
      setFormErrors({});
    }
  }, [isModalOpen]);

  const onFormSubmit = useCallback(
    async (data: DeploymentType) => {
      const action = currentId ? editRecord : addRecord;
      try {
        const res = await action(formatDeployment(data));

        if (res && res.status === 'error') {
          if (res.errors) {
            return setFormErrors(res.errors);
          }
          return toast(res.message, { type: 'error' });
        }

        setIsModalOpen(false);
        refreshDeployments();
      } catch (e) {
        toast(e.message, { type: 'error' });
      }
    },
    [currentId, editRecord, addRecord, refreshDeployments]
  );

  const onDeleteClick = useCallback(async () => {
    openDeleteModal({
      data: currentDeployment,
      type: 'deployment',
      action: deleteRecord,
      callback: () => {
        setIsModalOpen(false);
        refreshDeployments();
      },
    });
  }, [currentDeployment, deleteRecord, openDeleteModal, refreshDeployments]);

  const onTableRowToggle = useCallback((orgId, isSelected) => {
    setSelectedTableRows((prevSelected) =>
      isSelected
        ? [...prevSelected, orgId]
        : prevSelected.filter((id) => id !== orgId)
    );
  }, []);

  const onTableRowToggleAll = useCallback(
    (isSelected) => {
      setSelectedTableRows(
        isSelected ? deployments.map((deployment) => deployment.id) : []
      );
    },
    [deployments]
  );

  const onGenerateReportFormSubmit = useCallback(
    (values) => {
      const { date_from, date_to, include_successful_failed } = values;
      generateReport(
        selectedTableRows,
        date_from,
        date_to,
        include_successful_failed
      );
    },
    [generateReport, selectedTableRows]
  );

  const isSubmitting = useMemo(
    () => (isMutating || isDeploymentMutating) && !isDeleteLoading,
    [isMutating, isDeploymentMutating, isDeleteLoading]
  );

  return (
    <>
      <TopTable>
        <Permission resource={RESOURCE.DEPLOYMENT} action={ACTION.READ}>
          <SearchForm
            initialData={searchParams}
            inputLabel="Search deployments by name"
            onSubmit={setSearchParams}
          />
        </Permission>
        <Permission resource={RESOURCE.DEPLOYMENT} action={ACTION.READ}>
          <Button
            background="greyLight"
            disabled={selectedTableRows.length === 0}
            onClick={() => setIsGenerateReportModalOpen(true)}
          >
            Generate report
          </Button>
          <GenerateReportModal
            form={{
              onSubmit: onGenerateReportFormSubmit,
            }}
            isOpen={isGenerateReportModalOpen}
            onCloseClick={() => setIsGenerateReportModalOpen(false)}
            title="Generate Report"
          />
        </Permission>
        <Permission resource={RESOURCE.DEPLOYMENT} action={ACTION.CREATE}>
          <AddNewButton
            background="greyDark"
            onClick={() => setIsModalOpen(true)}
          >
            Add new
          </AddNewButton>
          <DeploymentModal
            form={{
              errors: formErrors,
              initialData: currentDeployment,
              isSubmitting,
              onSubmit: onFormSubmit,
              organizationId,
            }}
            isOpen={isModalOpen}
            onCloseClick={() => setIsModalOpen(false)}
            onDelete={onDeleteClick}
            title={
              !currentDeployment ? 'Add New Deployment' : 'Edit Deployment'
            }
          />
        </Permission>
      </TopTable>
      <Permission resource={RESOURCE.DEPLOYMENT} action={ACTION.READ}>
        <DeploymentsTable
          data={deployments}
          organizationId={organizationId}
          keyField="id"
          allowRowSelect
          selectedRows={selectedTableRows}
          onRowToggle={onTableRowToggle}
          onToggleAll={onTableRowToggleAll}
        />
      </Permission>

      <LoadingIndicator
        isLoading={isLoading || isDeploymentLoading || isGeneratingReport}
      />
    </>
  );
};
