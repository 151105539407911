import { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import useSWR from 'swr';

import { utils } from 'src/services';
import { setAuthToken } from 'src/store/actions';

import { useAuth } from '../useAuth';
import { useDispatch } from '../useDispatch';

type DataOptions = {
  headers?: Record<string, string>;
  doNotMutate?: boolean;
  downloadFile?: boolean;
};

type UseData<Data, NewData> = {
  data: Data;
  error?: Error;
  isLoading: boolean;
  isMutating: boolean;
  addRecord: (newData: NewData, options?: DataOptions) => Promise<any>;
  editRecord: (newData: NewData, options?: DataOptions) => Promise<any>;
  deleteRecord: (newData?: NewData, options?: DataOptions) => Promise<any>;
  refresh: () => void;
  mutate: (mutatedData: any) => void;
};

const buildHeaders = (authToken, headers = {}) => ({
  ...utils.jsonHeaders,
  Authorization: `Bearer ${authToken}`,
  ...headers,
});

const processResponse = async (res, dispatch) => {
  const parsedResponse = await res.json();
  const { authToken, ...finalResponse } = parsedResponse;

  if (typeof authToken === 'string' && authToken.length) {
    dispatch(setAuthToken(authToken));
  } else if (res.headers['Authorization']) {
    dispatch(setAuthToken(res.headers['Authorization']));
  }

  return finalResponse;
};

const processError = async (res, logout) => {
  if (res.status === 401) {
    return logout();
  }

  if (res.status === 403) {
    return toast("You don't have permission to access this resource", {
      type: 'error',
    });
  }

  try {
    const parsedErrorResponse = await res.json();
    return parsedErrorResponse;
  } catch (error) {
    return {
      status: 'error',
      message: res.statusText || error.message,
    };
  }
};

const fetcher = async (key, search, authToken, dispatch) => {
  let input = key;
  if (search) {
    const queryParams = Object.keys(search)
      .map((k) => {
        if (Array.isArray(search[k])) {
          return search[k].map((value) => `${k}=${value}`).join('&');
        }
        return `${k}=${search[k]}`;
      })
      .join('&');

    input = `${key}/search?` + queryParams;
  }

  const res = await fetch(input, {
    method: 'GET',
    headers: buildHeaders(authToken),
  });

  if (!res.ok) {
    return Promise.reject({
      status: res.status,
      message: res.statusText,
    });
  }

  return processResponse(res, dispatch);
};

export const useData = <D = any, ND extends BaseDataType = any>(
  key: string,
  search?: SearchParams
): UseData<D, ND> => {
  const { isLoggedIn, authToken, logout } = useAuth();
  const [isMutating, setIsMutating] = useState(false);
  const dispatch = useDispatch();
  const { data, error, mutate: swrMutate } = useSWR(
    key && isLoggedIn ? [key, search, authToken, dispatch] : null,
    fetcher
  );

  if (error && error.status === 401) {
    logout();
  }

  const mutate = useCallback(
    (mutatedData) => {
      swrMutate(mutatedData);
    },
    [swrMutate]
  );

  const addRecord = useCallback(
    async (newData: ND, options?: DataOptions): Promise<void> => {
      setIsMutating(true);

      const res = await fetch(key, {
        method: 'POST',
        body: JSON.stringify(newData),
        headers: buildHeaders(authToken, options?.headers),
      });

      setIsMutating(false);

      if (!res.ok) {
        return processError(res, logout);
      }

      const response = await processResponse(res, dispatch);

      if (!options?.doNotMutate) {
        mutate({
          data: [...data.data, response.data],
        });
      }

      return response;
    },
    [key, data, mutate, authToken, dispatch, logout]
  );

  const editRecord = useCallback(
    async (newData: ND, options?: DataOptions): Promise<void> => {
      setIsMutating(true);

      const res = await fetch(key, {
        method: 'PUT',
        body: JSON.stringify(newData),
        headers: buildHeaders(authToken, options?.headers),
      });

      setIsMutating(false);

      if (!res.ok) {
        return processError(res, logout);
      }

      const response = await processResponse(res, dispatch);

      if (!options?.doNotMutate) {
        mutate({
          data: { ...data.data, ...response.data },
        });
      }

      return response;
    },
    [key, data, mutate, authToken, dispatch, logout]
  );

  const deleteRecord = useCallback(
    async (newData?: ND, options?: DataOptions): Promise<void> => {
      setIsMutating(true);
      const res = await fetch(key, {
        method: 'DELETE',
        body: newData && JSON.stringify(newData),
        headers: buildHeaders(authToken, options?.headers),
      });
      setIsMutating(false);

      if (!res.ok) {
        return processError(res, logout);
      }

      return processResponse(res, dispatch);
    },
    [key, authToken, dispatch, logout]
  );

  const refresh = useCallback(() => {
    mutate(data);
  }, [mutate, data]);

  return {
    data,
    error,
    isLoading: key && !data && !error,
    isMutating,
    addRecord,
    editRecord,
    deleteRecord,
    refresh,
    mutate,
  };
};

export const useFetcher = () => {
  const { authToken, logout } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const send = useCallback(
    async (
      key: string,
      method: string = 'POST',
      body?: any,
      options?: DataOptions
    ) => {
      setIsLoading(true);
      const res = await fetch(key, {
        method,
        body: JSON.stringify(body),
        headers: buildHeaders(authToken, options?.headers),
      });
      setIsLoading(false);

      if (!res.ok) {
        return processError(res, logout);
      }

      if (options?.downloadFile) {
        const blob = await res.blob();
        var file = window.URL.createObjectURL(blob);
        return window.location.assign(file);
      }

      return processResponse(res, dispatch);
    },
    [authToken, dispatch, logout]
  );

  return {
    send,
    isLoading,
  };
};
