import { useCallback, useMemo } from 'react';

import { INSTANCES_URL } from 'src/constants';

import { useData, useFetcher } from './utils';

export const useInstanceSfLinkedUsersData = (instanceId: number) => {
  const { send, isLoading } = useFetcher();

  const key = useMemo(
    () =>
      instanceId ? `${INSTANCES_URL}/${instanceId}/linked-sf-users` : null,
    [instanceId]
  );

  const { data: { data: linkedUsers = [] } = {}, ...data } = useData<
    { data: any[] },
    any
  >(key);

  const linkUser = useCallback(
    async (username) => {
      return send(key, 'POST', { username });
    },
    [send, key]
  );

  const unlinkUser = useCallback(
    async (id) => {
      return send(key, 'DELETE', { id });
    },
    [send, key]
  );

  const deleteSfUserByEmail = useCallback(
    async (email) => {
      return send(`${INSTANCES_URL}/${instanceId}/sf-user/${email}`, 'DELETE');
    },
    [send, instanceId]
  );

  return {
    ...data,
    deleteSfUserByEmail,
    isLoadingFetcher: isLoading,
    linkedUsers,
    linkUser,
    unlinkUser,
  };
};
