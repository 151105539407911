import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { Button } from '@rollioforce/rollio-ui';
import { LoadingIndicator } from '@rollioforce/rollio-admin-ui';

import { SearchForm } from 'src/components/Forms';
import { InstanceModal } from 'src/components/Modals';
import { InstancesTable } from 'src/components/Tables';
import {
  useDeleteModal,
  useInstanceData,
  useOrganizationInstancesData,
} from 'src/hooks';
import { mapInstance } from 'src/parser';

const TopTable = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  margin-bottom: 10px;
`;

const AddNewButton = styled(Button)`
  margin-left: 10px;
`;

export const Instances = ({ organizationId }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formErrors, setFormErrors] = useState<Record<string, string>>({});
  const [currentId, setCurrentId] = useState(null);
  const [currentInstance, setCurrentInstance] = useState(null);
  const [searchParams, setSearchParams] = useState<SearchParams>();

  const {
    instances,
    isLoading,
    isMutating,
    addRecord,
    refresh: refreshInstances,
  } = useOrganizationInstancesData(organizationId, searchParams);
  const {
    instance,
    isLoading: isInstanceLoading,
    isMutating: isInstanceMutating,
    editRecord,
    deleteRecord,
  } = useInstanceData(currentId);
  const { isLoading: isDeleteLoading, openDeleteModal } = useDeleteModal();

  useEffect(() => {
    if (instance) {
      setIsModalOpen(true);
      setCurrentInstance(mapInstance(instance));
    }
  }, [instance]);

  useEffect(() => {
    if (!isModalOpen) {
      setCurrentId(null);
      setCurrentInstance(null);
      setFormErrors({});
    }
  }, [isModalOpen]);

  const onFormSubmit = useCallback(
    async (data: OrganizationInstanceType) => {
      const action = currentId ? editRecord : addRecord;
      try {
        const res = await action(data);

        if (res && res.status === 'error') {
          if (res.errors) {
            return setFormErrors(res.errors);
          }
          return toast(res.message, { type: 'error' });
        }

        setIsModalOpen(false);
        refreshInstances();
      } catch (e) {
        toast(e.message, { type: 'error' });
      }
    },
    [currentId, editRecord, addRecord, refreshInstances]
  );

  const onDeleteClick = useCallback(async () => {
    openDeleteModal({
      data: currentInstance,
      type: 'instance',
      action: deleteRecord,
      callback: () => {
        setIsModalOpen(false);
        refreshInstances();
      },
    });
  }, [currentInstance, deleteRecord, openDeleteModal, refreshInstances]);

  const isSubmitting = useMemo(
    () => (isMutating || isInstanceMutating) && !isDeleteLoading,
    [isMutating, isInstanceMutating, isDeleteLoading]
  );

  return (
    <>
      <TopTable>
        <SearchForm
          initialData={searchParams}
          inputLabel="Search instances by name"
          onSubmit={setSearchParams}
        />
        <AddNewButton
          background="greyDark"
          onClick={() => setIsModalOpen(true)}
        >
          Add new
        </AddNewButton>
        <InstanceModal
          form={{
            errors: formErrors,
            initialData: currentInstance,
            isSubmitting,
            onSubmit: onFormSubmit,
            organizationId,
          }}
          isOpen={isModalOpen}
          onCloseClick={() => setIsModalOpen(false)}
          onDelete={onDeleteClick}
          title={!currentInstance ? 'Add New Instance' : 'Edit Instance'}
        />
      </TopTable>
      <InstancesTable data={instances} organizationId={organizationId} />

      <LoadingIndicator isLoading={isLoading || isInstanceLoading} />
    </>
  );
};
