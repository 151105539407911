import React from 'react';
import { navigate } from '@reach/router';
import { Card } from '@rollioforce/rollio-ui';

import { Role } from 'src/components/Role';
import { ROLE } from 'src/constants';
import { getRouteUrlById, ROUTES } from 'src/routes';

export const Dashboard: React.FC = () => (
  <>
    <Role roles={[ROLE.INTERNAL]}>
      <Card
        title="Organizations"
        description="Manage your Organizations, deployments and users."
        buttonLabel="View More"
        onButtonClick={() => navigate(getRouteUrlById(ROUTES.organizations))}
      />
    </Role>

    <Role roles={[ROLE.ORGANIZATION, ROLE.DEPLOYMENT]}>
      <Card
        title="Organization"
        description="Manage your Organization, deployments and users."
        buttonLabel="View More"
        onButtonClick={() => navigate(getRouteUrlById(ROUTES.organization))}
      />
    </Role>
  </>
);
