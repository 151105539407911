import { useMemo } from 'react';

import { useData } from './utils';
import { DEPLOYMENTS_URL } from 'src/constants';

export const useMessagesData = (
  deploymentId: string,
  conversationId: string,
  sfUserId: string
) => {
  const key = useMemo(
    () =>
      `${DEPLOYMENTS_URL}/${deploymentId}/messages/${conversationId}?user_id=${sfUserId}`,
    [conversationId, deploymentId, sfUserId]
  );

  const { data: { data: messages = [] } = {}, ...data } = useData<
    { data: ConversationMessageType[]; total: number },
    ConversationMessageType
  >(key);

  return {
    ...data,
    messages,
  };
};
